export interface IStructureKTMDetails {
    ktmSymbol: string ,
    description: string,
    descriptionShort: string ,
}


export const structureKTMInitValues: IStructureKTMDetails = {
    ktmSymbol: "",
    description:  "",
    descriptionShort: ""
}

