/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {IAccessGroupDetails} from "./AccessGroupAddModel";
import SubMenu from "../../component/subMenu/SubMenu";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {AccessGroupForm} from "./AccessGroupCreateModalForm";
import {useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {fetchAllAdminGroups} from "./AccessGroupFunctions";
import {style, theme} from "../adminList/Admin";

type Props = {
    className: string
}

const AccessGroupsTable: React.FC<Props> = ({className}) => {
    const [chosenListItem, setChosenListItem] = useState<IAccessGroupDetails | null>(null)
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [searchValue, setSearchValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            const search = setTimeout(() => {
                setTriggerSearch(prevState => !prevState)
            }, 1200)

            return () => {
                clearTimeout(search)
            }
        } else {
            setTriggerSearch(prevState => !prevState)
        }
    }, [searchValue])

    const {data: adminGroups, isLoading, isFetching} = useQuery(["groups", triggerSearch], () => fetchAllAdminGroups(accessToken ,searchValue.trim().length > 0 ? "?simplesearch=" + searchValue : ""))

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 d-flex flex-nowrap gap-5 justify-content-end'>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Kliknij, aby dodać użytkownika'
                >
                    <button
                        className='btn btn-sm btn-light-primary'
                        onClick={() => setOpen(!open)}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        <span className="d-none d-sm-inline">Dodaj</span>
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                {adminGroups?.length === 0 && <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {adminGroups?.length > 0 && !isLoading && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>Nazwa grupy</th>
                            <th>Ilość członków</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {adminGroups?.length > 0 && adminGroups?.map(adminGroup => (
                            <tr>
                                <td>{adminGroup.title}</td>
                                <td>1</td>
                                <td>
                                    <SubMenu
                                        item={adminGroup}
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        setOpen={setOpen}
                                        url={"groups"}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>}
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpen(!open)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} grupę dostępu</h1>
                                    </div>
                                    <AccessGroupForm setChosenListItem={setChosenListItem}
                                                     chosenListItem={chosenListItem}
                                                     modalOpen={open} setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    )
}

export {AccessGroupsTable}
