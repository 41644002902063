export interface IClients {
    clientNumber: string | null,
    internalNumber: string | null,
    name: string | null,
    companyShort: string | null,
    surname: string | null,
    company: string | null,
    vatNumber: string | null,
    street: string | null,
    zipCode: string | null,
    city: string | null,
    phone: string | null,
    email: string | null,
    isActive: boolean,
    isVerified: boolean,
    isDistributor: boolean,
    discounts:  IDiscount[]
}

export interface IDiscount {
    gate_type: {
        name: string | null
    },
    percentage: number | null
}

export const clientsInitValues: IClients = {
    clientNumber: "",
    internalNumber: "",
    companyShort: "",
    name: "",
    surname: "",
    company: '',
    vatNumber: "",
    street: "",
    zipCode: "",
    city: "",
    phone: "",
    isDistributor: false,
    isActive: true,
    isVerified: false,
    email: "",
    distributor: undefined,
    discounts: [0,0,0,0]
}




