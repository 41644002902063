export const getAllSizes = async (accessToken, path) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${path}?pagination=false`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Błąd")
    }

    return response.json()
}

export const createSize = async ({accessToken, data, path}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${path}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(errorMessage.detail)
    }
}

export const updateSizeById = async ({accessToken, data, path, id}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${path}/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const theadTitleChanger = (params) => {
    let tHeadTitle;
    switch (params?.size) {
        case "heights": {
            tHeadTitle = "Wysokość";
            break;
        }
        case "widths": {
            tHeadTitle = "Szerokość";
            break;
        }
        case "lintels": {
            tHeadTitle = "Nadproże";
            break;
        }
    }
    return tHeadTitle
}