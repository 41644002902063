/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import { AccessGroupsTable } from './AccessGroupsTable';
import {ClientTable} from "../clientList/ClientTable";


const AccessGroupsWrapper: FC = () => {

    return (
        <>
            <PageTitle>Grupy dostępów</PageTitle>
            <AccessGroupsTable className='card-xxl-stretch mb-5 mb-xl-8'/>
        </>
    )
}

export {AccessGroupsWrapper}
