/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ChartsWidget2, ChartsWidget3,} from '../../../_metronic/partials/widgets'
import {useQuery} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {getAllGateTypes} from "../components/gateCategory/GateCategoryFunctions";

const getStats = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/stats`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    return await response.json();
}

const Statistics: FC = () => {
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const {data: stats, isLoading} = useQuery("stats", () => getStats(accessToken), {
        placeholderData: {}
    });
    const {data: gateTypes} = useQuery("gate_types", () => getAllGateTypes(accessToken));
    const statisticValues = Object?.entries(stats)

    const getCsv = async (gate, title) => {
        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}api/stats/export?gateType=${gate.id}`;
            httpClient.open('get', pdfLink, true);
            httpClient.setRequestHeader("Authorization", `Bearer ${accessToken}`)
            httpClient.responseType = "blob";
            httpClient.onload = function () {
                const file = new Blob([httpClient.response], {type: 'application/csv'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `${title}.csv`;
                link.click();
                // document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
            };
            httpClient.send();
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <>
            {/* begin::Row */}
            {statisticValues.map(stats => {
                const gate = gateTypes?.find(gate => gate.id === stats[0])
                if (!Array.isArray(stats[1])) {
                    return (
                        <div className='row gy-5 g-xl-8'>
                            <div className='col-xxl-6'>
                                <ChartsWidget2 className='card-xl-stretch mb-5 mb-xl-8'
                                               title={`${gate?.name} - wartość sprzedanych bram`} stats={stats[1]}
                                               gate={gate} getCsv={getCsv}/>
                            </div>
                            <div className='col-xxl-6'>
                                <ChartsWidget3 className='card-xl-stretch mb-5 mb-xl-8'
                                               title={`${gate?.name} - ilość sprzedanych bram`} stats={stats[1]}
                                               gate={gate} getCsv={getCsv}/>
                            </div>
                        </div>
                    )
                }
            })}
            {/* end::Row */}
        </>)
}


const StatisticsWrapper: FC = () => {

    return (
        <>
            <PageTitle breadcrumbs={[]}>Statystyki</PageTitle>
            <Statistics/>
        </>
    )
}

export {StatisticsWrapper}
