import React, {useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers';
import {IClients, clientsInitValues as initialValues} from './ClientAddModel'
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {buttonBaseClasses} from "@mui/material";
import {changeStatusClient, createNewClient, fetchAllClients, updateClientById, verifyClient} from "./ClientsFunctions";
import {getAllGateTypes} from "../components/gateCategory/GateCategoryFunctions";
import CustomSelect from "../../component/customSelect/CustomSelect";

const profileDetailsSchema = Yup.object().shape({
    name: Yup.string().required('Pole "Imię" jest wymagane').nullable(),
    surname: Yup.string().required('Pole "Nazwisko" jest wymagane').nullable(),
    email: Yup.string().required('Pole "Email" jest wymagane').nullable(),
    internalNumber: Yup.string().required('Pole "Numer wewnętrzny IFS" jest wymagane').nullable(),
    // vatNumber: Yup.string().required('Pole "NIP firmy" jest wymagane').test('len', 'Pole NIP firmy musi składać się z dokładnie 10 znaków' , val=> val?.length === 10)
})

const ClientCrateForm: React.FC<{ chosenListItem: IClients | null, isClient: boolean }> = (props) => {
    const client = useQueryClient();
    const {chosenListItem, isClient, modalOpen, setOpen, setChosenListItem} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [initValues, setInitValues] = useState<IClients>(initialValues);
    const {
        data: allDistributors,
    } = useQuery<IClients[]>(['allDistributors'], () => fetchAllClients(accessToken, `?pagination=false&isDistributor=true`), {
        keepPreviousData: true,
        placeholderData: []
    });
    const optionsDistributors = allDistributors?.map((d) => {
        return {value: d?.id, label: d?.surname +", "+ d.company}
    })

    const {data: gateTypes, isLoading} = useQuery("gate_types", () => getAllGateTypes(accessToken), {
        placeholderData: []
    });

    useEffect(() => {
        if (chosenListItem) {
            setInitValues({
                ...chosenListItem,
                discounts:  chosenListItem?.discounts?  chosenListItem?.discounts?.map(d => d?.discount) : [] ,
                phone: +chosenListItem.phone,
                distributor: chosenListItem?.distributor ? chosenListItem.distributor.id : null
            })
        } else {
            setInitValues({
                ...initialValues,
                discounts: gateTypes?.map(gT => 0) || []
            })
        }
    }, [chosenListItem, gateTypes])

    const clientCreateMutation = useMutation(createNewClient, {
        onError: (error => {
            console.log(error)
        }),
        onSuccess: () => {
            client.invalidateQueries("clients")
            client.invalidateQueries("allClients")
        }
    })

    const clientEditMutation = useMutation(updateClientById, {
        onError: (error => {
            console.log(error)
        }),
        onSuccess: () => {
            client.invalidateQueries("clients")
            client.invalidateQueries("allClients")
        },
    })

    const verifyClientMutation = useMutation(verifyClient, {
        onSuccess: () => {
            client.invalidateQueries(isClient ? "clients" : "distributors")
            client.invalidateQueries("allClients")
            setTimeout(() => {
                setOpen(prev => !prev)
            }, 1000)
        },
        onError: (e) => {
            console.log(e)
        }
    })

    const changeStatusClientMutation = useMutation(changeStatusClient, {
        onSuccess: () => {
            client.invalidateQueries(isClient ? "clients" : "distributors")
            client.invalidateQueries("allClients")
            setTimeout(() => {
                setOpen(prev => !prev)
            }, 1000)
        },
        onError: (e) => {
            console.log(e)
        }
    })

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IClients>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            let discounts = gateTypes.map((gate, index) => {
                return {
                    gateType: gate.id,
                    discount: values?.discounts[index] === null || values?.discounts[index] === undefined ? 0 : +values?.discounts[index]
                }
            });
            setLoading(true)
            const options = {
                onSuccess: () => {
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                }
            }
            if (chosenListItem && chosenListItem.id) {
                clientEditMutation.mutate({
                    isClient,
                    data: {
                        ...values,
                        discounts,
                        phone: values.phone.toString()
                    },
                    accessToken,
                    clientId: chosenListItem.id,
                }, options)
            } else {
                clientCreateMutation.mutate({
                    isClient,
                    data: {
                        ...values,
                        discounts: gateTypes?.map((gate) => {
                            return {
                                gateType: gate.id,
                                discount: 0
                            }
                        }),
                        isDistributor: !isClient,
                        phone: values.phone.toString()
                    },
                    accessToken
                }, options)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div id='kt_account_profile_details' className='collapse show'>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>

                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Numer IFS</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź numer IFS'
                                            {...formik.getFieldProps('internalNumber')}
                                        />
                                        {formik.errors.internalNumber && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.internalNumber}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Konto
                                        Stalprodukt</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='checkbox'
                                            checked={formik.values.isInsideClient}
                                            {...formik.getFieldProps('isInsideClient')}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>Nazwa firmy</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź nazwę fimry'
                                            {...formik.getFieldProps('company')}
                                        />
                                        {formik.touched.company && formik.errors.company && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.company}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>NIP firmy</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź NIP'
                                            {...formik.getFieldProps('vatNumber')}
                                        />
                                        {formik.touched.vatNumber && formik.errors.vatNumber && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.vatNumber}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Imię</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź imię klienta'
                                            {...formik.getFieldProps('name')}
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwisko</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź nazwisko'
                                            {...formik.getFieldProps('surname')}
                                        />
                                        {formik.touched.surname && formik.errors.surname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.surname}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Ulica</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź ulicę'
                                            {...formik.getFieldProps('street')}
                                        />
                                        {formik.touched.street && formik.errors.street && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.street}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Kod pocztowy</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź kod pocztowy'
                                            {...formik.getFieldProps('zipCode')}
                                        />
                                        {formik.touched.zipCode && formik.errors.zipCode && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.zipCode}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>Miasto</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź miasto'
                                            {...formik.getFieldProps('city')}
                                        />
                                        {formik.touched.city && formik.errors.city && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.city}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'
                                           title='Change avatar'>Dystrybutor</label>
                                    <Field
                                        className="custom-select"
                                        name="distributor"
                                        options={optionsDistributors}
                                        component={CustomSelect}
                                        placeholder="Wybierz dystrybutora"
                                    />
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Numer telefonu</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź numer telefonu'
                                            {...formik.getFieldProps('phone')}
                                        />
                                        {formik.touched.phone && formik.errors.phone && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.phone}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Email</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wprowadź email'
                                            {...formik.getFieldProps('email')}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <h3 className="mt-5">Przydzielone rabaty</h3>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>Rodzaj bramy</label>
                                    {gateTypes?.map((gate, index) => {
                                        return (<div className='col-lg-12 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-5'
                                                placeholder={gate.name}
                                                disabled
                                            />
                                            {formik.touched.city && formik.errors.city && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.city}</div>
                                                </div>
                                            )}
                                        </div>)
                                    })}

                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label fw-bold fs-6'>Wartość rabatu w %</label>
                                    {gateTypes?.map((gate, index) => {
                                        return (<div className='col-lg-12 fv-row'>
                                            <input
                                                style={{
                                                    color: chosenListItem?.distributor?.id ? '#b1b3c5' : null
                                                }}
                                                disabled={chosenListItem?.distributor?.id}
                                                defaultValue={0}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid mb-5'
                                                placeholder='Wartośc rabatu'
                                                {...formik.getFieldProps(`discounts[${index}]`)}
                                            />
                                            {formik.touched.city && formik.errors.city && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.city}</div>
                                                </div>
                                            )}
                                        </div>)
                                    })}
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label  fw-bold fs-6'>Akcesoria - wartość rabatu
                                        w %</label>
                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            style={{
                                                color: chosenListItem?.distributor?.id ? '#b1b3c5' : null
                                            }}
                                            disabled={chosenListItem?.distributor?.id}
                                            defaultValue={0}
                                            type='number'
                                            className='form-control form-control-lg form-control-solid mb-5'
                                            placeholder='Wartośc rabatu'
                                            {...formik.getFieldProps(`accessoriesDiscount`)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-between py-6 px-9'>
                            {chosenListItem !== null && !chosenListItem.isVerified && <button type='button'
                                                                                              disabled={formik.getFieldProps('internalNumber')?.value?.trim().length === 0 || formik.getFieldProps('internalNumber')?.value?.trim().length === undefined}
                                                                                              onClick={() => verifyClientMutation.mutate({
                                                                                                  isClient,
                                                                                                  data: {
                                                                                                      isVerified: true,
                                                                                                      internalNumber: formik.getFieldProps('internalNumber').value
                                                                                                  },
                                                                                                  accessToken,
                                                                                                  clientId: chosenListItem?.id
                                                                                              })}
                                                                                              className={`btn btn-primary btn-success`}>
                                {!verifyClientMutation.isLoading && `Aktywuj ${(formik.getFieldProps('internalNumber')?.value?.trim().length === 0 || formik.getFieldProps('internalNumber')?.value?.trim().length === undefined) ? " (Aby aktywować klienta, wpisz węwnetrzny numer IFS)" : ""}`}
                                {verifyClientMutation.isLoading && (
                                    <span className='indicator-progress' style={{display: 'block'}}>
                                    Aktywowanie...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </button>}
                            {(chosenListItem === null || chosenListItem?.isVerified) &&
                                <>
                                    {chosenListItem !== null && chosenListItem?.isActive && <button type='button'
                                                                                                    onClick={() => changeStatusClientMutation.mutate({
                                                                                                        isClient,
                                                                                                        data: {
                                                                                                            isActive: false,
                                                                        },
                                                                        accessToken,
                                                                        clientId: chosenListItem?.id
                                                                    })}
                                                                    className={`btn btn-primary btn-danger`}>
                                    {!changeStatusClientMutation.isLoading && "Dezaktywuj"}
                                    {changeStatusClientMutation.isLoading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                    Dezaktywowanie...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </button>}

                                {chosenListItem !== null && !chosenListItem?.isActive && <button type='button'
                                                                                                onClick={() => changeStatusClientMutation.mutate({
                                                                                                    isClient,
                                                                                                    data: {
                                                                                                        isActive: true,
                                                                                                    },
                                                                                                    accessToken,
                                                                                                    clientId: chosenListItem?.id
                                                                                                })}
                                                                                                className={`btn btn-primary btn-danger`}>
                                    {!changeStatusClientMutation.isLoading && "Aktywuj"}
                                    {changeStatusClientMutation.isLoading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                    Aktywowanie...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </button>}

                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && (chosenListItem?.id ? "Zapisz" : chosenListItem === null ? "Dodaj i aktywuj" : "Dodaj")}
                                    {(clientCreateMutation.isLoading || clientEditMutation.isLoading || loading) && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                    Zapisywanie...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </button>
                            </>}
                    </div>
                </form>
                </FormikProvider>
                {clientEditMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {clientCreateMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Stworzono!</p>}
                {verifyClientMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Konto aktywowane!</p>}
                {changeStatusClientMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Konto dezaktywowane!</p>}
                {verifyClientMutation.isError &&
                    <p className="text-center fw-bold" style={{
                        color: "red",
                        fontSize: 16
                    }}>{verifyClientMutation?.error.toString().replace("Error:", "")}!</p>}
            </div>
        </div>
    )
}

export {ClientCrateForm}
