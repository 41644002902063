import {Loader} from '@mantine/core';
import React, {useEffect, useState} from 'react';
import {useIsFetching, useMutation, useQueryClient} from "react-query";

export const changePosition = async ({url, id, data, accessToken}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${id}/change_order`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()
}

export const changePosition2 = async ({url, items, accessToken}) => {
    await Promise.all(items.map(item => {
        fetch(`${process.env.REACT_APP_API_URL}api/${url}/${item.id}/change_order`, {
            method: "PATCH",
            body: JSON.stringify(item.data),
            headers: {
                "Content-Type": "application/merge-patch+json",
                "Authorization": `Bearer ${accessToken}`
            }
        }).then(r => r.json());
    }))
}
export const increasePosition = async ({url, id, data, accessToken}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${id}/change_order`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()
}
export const decreasePosition = async ({url, id, data, accessToken}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${id}/change_order`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak")
    }

    return await response.json()
}


const CustomChangePosition = ({item, url, accessToken, allItems, orderKey, queryKey, queryKey2, isDuplicated}) => {
    const queryClient = useQueryClient();
    // const increasePositionMutation = useMutation(increasePosition, {
    //     onSuccess: () => {
    //         queryClient.invalidateQueries(queryKey)
    //     }
    // })
    const changePositionMutation = useMutation(changePosition)
    const isFetching = useIsFetching(queryKey)
    return (
        <div className={"change-position"}>
            <div className={"change-position--left"}>
                {changePositionMutation.isLoading || isFetching ? <div><Loader size={12}/></div> : <div style={isDuplicated ? {
                    color: 'red',
                    fontWeight: "bold"
                } : undefined}>{item[orderKey]}{isDuplicated ? '!' : ''}</div>}
            </div>
            <div className={"change-position--right"}>
                <div className={"arrow-up"}
                     onClick={() => {
                         if (!changePositionMutation.isLoading) {
                             const item2 = allItems[item[orderKey] - 2]
                             if (item2) {
                                 changePositionMutation.mutate({
                                     url,
                                     id: item.id,
                                     data: {
                                         [orderKey]: item[orderKey] - 1
                                     },
                                     accessToken
                                 }, {
                                     onSuccess: () => {
                                         changePositionMutation.mutate({
                                             url,
                                             id: item2.id,
                                             data: {
                                                 [orderKey]: item2[orderKey] + 1
                                             },
                                             accessToken
                                         }, {
                                             onSuccess: () => {
                                                 queryClient.invalidateQueries(queryKey)
                                                 if (queryKey2) {
                                                     queryClient.invalidateQueries(queryKey2)
                                                 }
                                             }
                                         })
                                     }
                                 })
                             }
                         }
                     }}>
                    <div></div>
                </div>
                <div className={"arrow-down"}
                     onClick={() => {
                         if (!changePositionMutation.isLoading) {
                             const item2 = allItems[item[orderKey]]
                             if (item2) {
                                 changePositionMutation.mutate({
                                     url,
                                     id: item.id,
                                     data: {
                                         [orderKey]: item[orderKey] + 1
                                     },
                                     accessToken
                                 }, {
                                     onSuccess: () => {
                                         changePositionMutation.mutate({
                                             url,
                                             id: item2.id,
                                             data: {
                                                 [orderKey]: item2[orderKey] - 1
                                             },
                                             accessToken
                                         }, {
                                             onSuccess: () => {
                                                 queryClient.invalidateQueries(queryKey)
                                                 if (queryKey2) {
                                                     queryClient.invalidateQueries(queryKey2)
                                                 }
                                             }
                                         })
                                     }
                                 })
                             }
                         }
                     }}
                >
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default CustomChangePosition;
