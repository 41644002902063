/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from './../../../_metronic/helpers'
import {StatusChangeForm} from "./StatusChangeForm";
import {IOffer} from "./OfferTable";
import QRCode from 'react-qr-code';
import {useQueries, useQuery} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {getDetailGate} from "./OfferListFunctions";
import {CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";

const statuses = ['', 'Nowa', 'Potwierdzona przez dystrybutora', 'Wysłana', 'Potwierdzona', 'Anulowana'];

const PdfPreview: FC<{ chosenOffer: IOffer[] | null | undefined }> = (props) => {
    const [clickedItem, setClickedItem] = useState(false)
    const {chosenOffer} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const choseOfferData = chosenOffer?.offerData ? chosenOffer?.offerData : chosenOffer?.orderData;
    const params = useParams();
    const userQueries = useQueries(
        choseOfferData?.items?.map(gate => {
            return {
                queryKey: ['gate', gate.gate],
                queryFn: () => getDetailGate(gate.gate, accessToken),
                keepPreviousData: true,
            }
        })
    )

    return (
        <>
            {<>
                <div className="pdf-preview-middle p-10">
                    <div className="d-flex flex-column flex-xl-row">
                        <div className="flex-lg-row-fluid mb-10 mb-xl-0">
                            <div className="mt-n1 d-flex gap-10">
                                {/* ZDJECIE JESLI BĘDZIE */}
                                {/*<div className="d-flex flex-stack pb-10">*/}
                                {/*    <a href="#">*/}
                                {/*        <img alt="Logo" width="100" height="50"*/}
                                {/*             src="dist/assets/media/apigee.svg"/>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                                <div className="w-50">

                                    {<div className="m-0">
                                        <div className="bg-blue fw-bolder fs-3 w-75 p-3">Zamówienie</div>
                                        <div
                                            className="bg-blue-border mb-5 p-3">{choseOfferData?.client?.name} M-66(#{chosenOffer?.internalNumber})
                                        </div>

                                        <div className="blue-item d-flex mb-2">
                                            <div className="p-3 ">Data zamówienia</div>
                                            <p>{new Date(chosenOffer.createdAt)?.toLocaleDateString() || "-"}</p>
                                        </div>
                                        <div className="blue-item d-flex mb-2">
                                            <div className="p-3">Status</div>
                                            <p>{statuses[chosenOffer?.status]}</p>
                                        </div>
                                        <div className="blue-item d-flex mb-2">
                                            <div className="p-3">Ilość pozycji</div>
                                            <p>{choseOfferData?.items?.length + choseOfferData?.accessories?.length}</p>
                                        </div>
                                        <div className="blue-item d-flex mb-2">
                                            <div className="p-3">Wartość netto</div>
                                            <p>{choseOfferData?.priceData?.finalPrice / 100} zł</p>
                                        </div>
                                    </div>}
                                </div>

                                <div className="w-50 pdf-right">
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="bg-blue fw-bolder fs-3 mb-0 p-3 w-75 ">Zamawiający</div>
                                        <div className="bg-blue-border mb-5 p-3 d-flex w-100 justify-content-between">
                                            <div className="d-flex flex-column align-items-center">
                                                <p>858584</p>
                                                <div><QRCode value={"asdasdassdas"} size={100}/></div>
                                            </div>
                                            <div className="text-end ">
                                                <p>{chosenOffer?.client?.name + " " + chosenOffer?.client?.surname}</p>
                                                <p>{"ul. " + chosenOffer?.client?.street + ", " + chosenOffer?.client?.zipCode + ", " + chosenOffer?.client?.city}</p>
                                                <p>{chosenOffer?.receiverName || "-"}</p>
                                                <p>{chosenOffer?.client?.email || "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-end">
                                        <div className="bg-blue fw-bolder fs-3 p-3 w-75">Odbiorca</div>
                                        <div className="bg-blue-border mb-5 p-3 w-100">
                                            <div className="text-end ">
                                                <p>{chosenOffer?.client?.name + " " + chosenOffer?.client?.surname}</p>
                                                <p>{"ul. " + chosenOffer?.client?.street + ", " + chosenOffer?.client?.zipCode + ", " + chosenOffer?.client?.city}</p>
                                                <p>{chosenOffer?.receiverName || "-"}</p>
                                                <p>{chosenOffer?.client?.email || "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pdf-preview-middle p-10">
                    <p className="mb-1">info Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est, minima!</p>
                    <div className="bg-blue w-100 h-5px p-3"></div>
                    <div className="bg-blue fw-bolder fs-3 mb-0 p-3 w-100 mb-10" style={{maxWidth: 466}}>Specyfikacja
                    </div>
                    {userQueries?.map((offer, index) => {
                        return (
                            <>
                                <div className="d-flex flex-column">
                                    <div>
                                        <div className="d-flex mb-10">
                                            <div className="bg-blue p-3">{index + 1}</div>
                                            <div className="flex-grow-1 ">
                                                <div className="d-flex bg-blue-border ">
                                                    <div className="bg-blue-border-right p-3">
                                                        {offer?.data?.ktmCode &&
                                                            <QRCode value={offer?.data?.ktmCode} size={100}/>}
                                                    </div>
                                                    <div
                                                        className="bg-blue-border-right w-100 p-3 d-flex flex-column justify-content-around">
                                                        <p className="text-gray-800 fw-normal mb-0">
                                                            {offer?.data?.ktmCode}
                                                        </p>
                                                        <p className="text-gray-800 fw-normal mb-0">{offer?.data?.ktmCodeDescription}</p>
                                                    </div>
                                                    <div className="p-3">
                                                        {offer?.data?.ktmCodeDescription &&
                                                            <QRCode value={offer?.data?.ktmCodeDescription}
                                                                    size={100}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-10">
                                            <div className="w-100">
                                                <div className="bg-grey-border d-flex mb-3">
                                                    <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Rodzaj</p>
                                                    <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.gateType?.name}</p>
                                                </div>
                                                <div className="bg-grey-border d-flex mb-3">
                                                    <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Prowadzenie</p>
                                                    <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.leading?.name}</p>
                                                </div>
                                                <div className="bg-grey-border d-flex mb-3">
                                                    <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Nadproże</p>
                                                    <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.lintel?.value} mm</p>
                                                </div>
                                                {offer?.data?.componentItems?.map(comp => {
                                                    return (
                                                        <div className="bg-grey-border d-flex mb-3">
                                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">{comp?.component?.name}</p>
                                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{comp?.name}</p>
                                                        </div>
                                                    )
                                                })}
                                                <div
                                                    className="d-flex my-10 justify-content-between w-100">
                                                    <div className="w-25">
                                                        <div>
                                                            <div className="blue-item d-flex mb-3">
                                                                <div className="p-3 w-25 min-w-unset">Sc</div>
                                                                <p className="flex-grow-1">2000 mm</p>
                                                            </div>
                                                            <div className="blue-item d-flex ">
                                                                <div className="p-3 w-25 min-w-unset">Hc</div>
                                                                <p className="flex-grow-1">{offer?.data?.width?.value} mm</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {offer?.data?.price &&
                                                            <>
                                                                <QRCode
                                                                    value={(offer.data.price / 100).toString() + " PLN"}
                                                                    size={100}
                                                                    className="mb-5"/>
                                                                <p className="text-center mb-0"
                                                                   style={{fontWeight: 500}}> {offer?.data?.gateType?.name.includes("garażowa") ? "BG" : "BP"}{(offer.data.price / 100).toString()}</p>
                                                            </>
                                                        }
                                                    </div>
                                                    <div className="w-25"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    {choseOfferData.accessories?.map((offer, index) => {
                        return (
                            <>
                                <div className="d-flex flex-column">
                                    <div>
                                        <div className="d-flex mb-10">
                                            <div className="bg-blue p-3">{index + 1}</div>
                                            <div className="flex-grow-1 ">
                                                <div className="d-flex bg-blue-border ">
                                                    <div className="bg-blue-border-right p-3">
                                                        {offer?.ktm && <QRCode
                                                            value={offer?.ktm}
                                                            size={100}/>}
                                                    </div>
                                                    <div
                                                        className="bg-blue-border-right w-100 p-3 d-flex flex-column justify-content-around">
                                                        <p className="text-gray-800 fw-normal mb-0">
                                                            {offer?.ktm}
                                                        </p>
                                                        <p className="text-gray-800 fw-normal mb-0">{offer?.ktmDescription}</p>
                                                    </div>
                                                    <div className="p-3">
                                                        {offer?.ktmDescription && <QRCode
                                                            value={offer?.ktmDescription}
                                                            size={100}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-10">
                                            <div className="w-100">
                                                <div className="bg-grey-border d-flex mb-3">
                                                    <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Nazwa</p>
                                                    <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.name}</p>
                                                </div>
                                                <div className="bg-grey-border d-flex mb-3">
                                                    <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Ilość</p>
                                                    <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.quantity}</p>
                                                </div>
                                                <div
                                                    className="d-flex justify-content-center p-10 flex-column align-items-center">
                                                    <>
                                                        {offer?.priceData &&
                                                            <>
                                                                <QRCode
                                                                    className="mb-5"
                                                                    value={(offer?.priceData?.finalPrice).toString()}
                                                                    size={100}/>
                                                                <p className="text-center mb-0" style={{fontWeight: 500}}>AK{offer?.priceData?.finalPrice}</p>
                                                            </>}
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                    {userQueries?.map((offer, index) => {
                        return (
                            <>
                                <div className="w-100">
                                    <img src={toAbsoluteUrl('/media/pdf-preview.png')} alt='img' className="w-100"/>
                                </div>
                                <div className="d-flex gap-10 mb-10">
                                    <div className="w-100">
                                        {offer?.data?.width && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Szerokość
                                                otworu</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.width ? offer?.data?.width?.value + " mm" : ""} </p>
                                        </div>}
                                        {offer?.data?.height && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Wysokość
                                                otworu</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.height ? offer?.data?.height?.value + " mm" : ""} </p>
                                        </div>}
                                        {offer?.data?.depth && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Głębokość</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.depth ? offer?.data?.depth + " mm" : ""} </p>
                                        </div>}
                                        {offer?.data?.rightArea && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Przestrzeń
                                                boczna z prawej</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.rightArea ? offer?.data?.rightArea + " mm" : ""} </p>
                                        </div>}
                                        {offer?.data?.leftArea && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Przestrzeń
                                                boczna z lewej</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.leftArea ? offer?.data?.leftArea + " mm" : ""} </p>
                                        </div>}
                                        {offer?.data?.lintel && <div className="bg-grey-border d-flex mb-3">
                                            <p className="bg-grey-border-right p-3 mb-0 text-gray-800 fw-normal w-50">Nadproże</p>
                                            <p className="p-3 mb-0 text-end w-50 fw-normal">{offer?.data?.lintel ? offer?.data?.lintel?.value + " mm" : ""} </p>
                                        </div>}
                                    </div>
                                </div>
                                <div className="w-100 mb-20 pdf-sizes">
                                    <img src={toAbsoluteUrl('/media/car.png')} alt='img' className="w-100"/>
                                    <p className="width">- {offer?.data?.width ? offer?.data?.width?.value + " mm" : ""}</p>
                                    <p className="height">- {offer?.data?.height ? offer?.data?.height?.value + " mm" : "2000 mm"}</p>
                                    <p className="deep">- {offer?.data?.depth ? offer?.data?.depth + " mm" : ""}</p>
                                    <p className="leftArea">- {offer?.data?.leftArea ? offer?.data?.leftArea + " mm" : ""}</p>
                                    <p className="rightArea">- {offer?.data?.rightArea ? offer?.data?.rightArea + " mm" : ""}</p>
                                    <p className="lintel">- {offer?.data?.lintel ? offer?.data?.lintel?.value + " mm" : ""}</p>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="pdf-preview-bottom d-flex flex-column gap-10 mb-5">
                    <div className="d-flex gap-20">
                        <div className="bg-blue-border text-center w-100">
                            <div className="bg-blue p-3">Ilość</div>
                            <p className="p-3 mb-0">1</p>
                        </div>
                        <div className="bg-blue-border text-center w-100">
                            <div className="bg-blue p-3">Cena netto</div>
                            <p className="mb-0 p-3">{choseOfferData?.priceData?.finalPrice / 100} zł</p>
                        </div>
                        <div className="bg-blue-border text-center w-100">
                            <div className="bg-blue p-3">Wartość netto</div>
                            <p className="mb-0 p-3">{choseOfferData?.priceData?.netPrice / 100} zł</p>
                        </div>
                    </div>
                </div>
            </>}
        </>


    )
}

export {PdfPreview}
