/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from './../../../_metronic/helpers'
import QRCode from 'react-qr-code';
import {useQueries, useQuery} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";

import {CircularProgress} from "@mui/material";
import {getDetailGate} from "../offerList/OfferListFunctions";


const BasketPreview = (props) => {
    const [clickedItem, setClickedItem] = useState(false)
    const {chosenOffer, isLoading} = props;
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const userQueries = useQueries(
        chosenOffer?.items?.map(gate => {
            return {
                queryKey: ['gate', gate.gate],
                queryFn: () => getDetailGate(gate.gate, accessToken),
                keepPreviousData: true,
            }
        })
    )

    return (
        <div>
            <div>
                <div className="d-flex flex-column flex-xl-row" style={{padding: 30}}>
                    <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                        <div className="mt-n1">
                            {/* ZDJECIE JESLI BĘDZIE */}
                            {/*<div className="d-flex flex-stack pb-10">*/}
                            {/*    <a href="#">*/}
                            {/*        <img alt="Logo" width="100" height="50"*/}
                            {/*             src="dist/assets/media/apigee.svg"/>*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            {!!userQueries.find(e => e.isLoading) &&
                                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                                    <CircularProgress
                                        size={30}/>
                                </div>}
                            {!userQueries.find(e => e.isLoading) && <div className="m-0">
                                {/*<div*/}
                                {/*    className="fw-bolder fs-3 text-gray-800 mb-8">#{params.type === "offers" ? chosenOffer?.orderNumber : chosenOffer?.offerNumber}</div>*/}
                                <div className="flex-grow-1">
                                    <div className="table-responsive mb-9">
                                        <table className="table mb-3" style={{borderCollapse: "unset"}}>
                                            <thead>
                                            <tr className="border-bottom fs-6 fw-bolder text-muted">
                                                <th className="min-w-175px pb-2">Projekt</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {userQueries?.length > 0 && userQueries?.map(d => d.data).map((item, index) => {
                                                return (
                                                    <tr key={item} className="fw-bolder text-gray-700 fs-5 text-end"
                                                        style={{borderBottom: "1px solid #EFF2F5"}}>
                                                        <td className="d-flex flex-column pt-6">
                                                            <div className="d-flex flex-column">
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between">
                                                                    <div>
                                                                        <i className="fa fa-genderless text-danger fs-2 me-2"></i>{item?.ktmCode}
                                                                    </div>
                                                                    <div className="fv-row me-0">
                                                                        {item?.ktmCode &&
                                                                            <QRCode value={item?.ktmCode} size={50}/>}
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className="d-flex align-items-center justify-content-between py-5">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="fv-row me-3">
                                                                            {item?.ktmCodeDescription &&
                                                                                <QRCode value={item?.ktmCodeDescription}
                                                                                        size={50}/>}
                                                                        </div>
                                                                        <div style={{fontSize: 11, textAlign: 'left'}}>
                                                                            {item?.ktmCodeDescription}
                                                                        </div>
                                                                    </div>
                                                                    <button
                                                                        onClick={() => setClickedItem(prev => {
                                                                            if (prev === item.id) {
                                                                                return false;
                                                                            } else {
                                                                                return item.id
                                                                            }
                                                                        })}
                                                                        className="btn btn-primary custom-order-btn">Szczegóły
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {clickedItem === item?.id &&
                                                                <div className=" custom-order-container">
                                                                    <div className="d-flex flex-column mt-5 gap-2">
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Rodzaj:</p>
                                                                            <p className="text-nowrap mb-0">{item?.gateType?.name}</p>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Szerokość:</p>
                                                                            <p className="text-nowrap mb-0">{item?.width?.value} mm</p>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Wysokość:</p>
                                                                            <p className="text-nowrap mb-0">{item?.height?.value} mm</p>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Nadproże:</p>
                                                                            <p className="text-nowrap mb-0">{item?.lintel?.value} mm</p>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Kolor:</p>
                                                                            <p className="text-nowrap mb-0">{item?.componentItems.filter(i => i?.component?.name === "Kolor")[0]?.name}</p>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Tłoczenie:</p>
                                                                            <p className="text-nowrap mb-0">{item?.componentItems.filter(i => i?.component?.name === "Tłoczenie")[0]?.name}</p>
                                                                        </div>
                                                                        {item?.leading && <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Prowadzenie:</p>
                                                                            <p className="text-nowrap mb-0">{item?.leading.name}</p>
                                                                        </div>}
                                                                        {item?.drive && <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Napęd:</p>
                                                                            <p className="text-nowrap mb-0">{item?.drive.name}</p>
                                                                        </div>}
                                                                        <div
                                                                            className="d-flex justify-content-between gap-5">
                                                                            <p className="fw-normal mb-0">Akcesoria:</p>
                                                                            <p className="text-nowrap mb-0">Brak</p>
                                                                        </div>

                                                                    </div>
                                                                    {item?.render && <div
                                                                        className="d-flex justify-content-center py-5 w-100">
                                                                        <img
                                                                            src={`${process.env.REACT_APP_API_URL}` + item?.render?.contentUrl}
                                                                            alt="visualization imgage" className="w-50"/>
                                                                    </div>}
                                                                </div>}
                                                            <div className={'gate-price-table'}>
                                                                <table>
                                                                    <thead>
                                                                    <th>Ilość</th>
                                                                    <th>Cena katalogowa(netto):</th>
                                                                    <th>Wartość rabatu(netto):</th>
                                                                    <th>Cena(netto) po rabacie</th>
                                                                    <th>Cena(brutto) po rabacie</th>
                                                                    </thead>
                                                                    <tbody>
                                                                    <tr>
                                                                        <td className="pt-6">{chosenOffer?.items[index]?.quantity}</td>
                                                                        <td className="pt-6">{(chosenOffer?.items[index]?.priceData.netRegularPrice / 100).toFixed(2)} zł</td>
                                                                        <td className="pt-6 ">{((chosenOffer?.items[index]?.priceData.netRegularPrice - chosenOffer?.items[index]?.priceData.netPrice) / 100).toFixed(2)} zł</td>
                                                                        <td className="pt-6 ">{(chosenOffer?.items[index]?.priceData.netPrice / 100).toFixed(2)} zł</td>
                                                                        <td className="pt-6 text-dark fw-boldest">{(chosenOffer?.items[index]?.priceData.finalPrice / 100).toFixed(2)} zł</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                        {/*<td className="pt-6">{choseOfferData?.items[index]?.quantity}</td>*/}
                                                        {/*<td className="pt-6">{((item?.price + (23 / 100 * item?.price)) / 100).toFixed(2)} zł</td>*/}
                                                        {/*<td className="pt-6 text-dark fw-boldest">{choseOfferData?.items[index]?.quantity * ((item?.price + (23 / 100 * item?.price)) / 100).toFixed(2)} zł</td>*/}
                                                    </tr>
                                                )
                                            })}
                                            {chosenOffer?.accessories?.length > 0 &&
                                                <tr className="border-bottom fs-6 fw-bolder text-muted">
                                                    <div className="min-w-175px pb-2 mt-5 p-0">Akcesoria:</div>
                                                </tr>}
                                            {chosenOffer?.accessories?.length > 0 && chosenOffer?.accessories.map((acc, index) => (
                                                <tr key={acc} className="fw-bolder text-gray-700 fs-5 text-end"
                                                    style={{borderBottom: "1px solid #EFF2F5"}}>
                                                    <td className="d-flex flex-column pt-6">
                                                        <div className="d-flex flex-column">
                                                            <div
                                                                className="d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <i className="fa fa-genderless text-danger fs-2 me-2"></i>{acc?.ktm}
                                                                </div>
                                                                <div className="fv-row me-0">
                                                                    {acc?.ktm &&
                                                                        <QRCode value={acc?.ktm} size={50}/>}
                                                                </div>
                                                            </div>

                                                            <div className="d-flex align-items-center justify-content-between mt-5 py-5">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="fv-row me-3">
                                                                        {acc?.ktmDescription &&
                                                                            <QRCode value={acc?.ktmDescription}
                                                                                    size={50}/>}
                                                                    </div>
                                                                    <div style={{fontSize: 11}}>
                                                                        {acc?.ktmDescription}
                                                                    </div>
                                                                </div>
                                                                <button
                                                                    onClick={() => setClickedItem(prev => {
                                                                        if (prev === acc.id) {
                                                                            return false;
                                                                        } else {
                                                                            return acc.id
                                                                        }
                                                                    })}
                                                                    className="btn btn-primary custom-order-btn">Szczegóły
                                                                </button>
                                                            </div>

                                                        </div>
                                                        {clickedItem === acc?.id &&
                                                            <div className=" custom-order-container">
                                                                <div className="d-flex flex-column mt-5 gap-5 w-100">
                                                                    <div className="d-flex justify-content-between gap-5">
                                                                        <p className="fw-normal mb-0">Nazwa:</p>
                                                                        <p className="text-nowrap mb-0">{acc?.name}</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between gap-5">
                                                                        <p className="fw-normal mb-0">Ilość:</p>
                                                                        <p className="text-nowrap mb-0">{acc?.quantity}</p>
                                                                    </div>
                                                                </div>
                                                                {acc.image && <div
                                                                    className="d-flex justify-content-center py-5 w-100">
                                                                    <img
                                                                        src={`${process.env.REACT_APP_API_URL}` + acc?.image?.contentUrl}
                                                                        alt=""/>
                                                                </div>}
                                                            </div>}
                                                    </td>
                                                    <div className={'gate-price-table'}>
                                                        <table>
                                                            <thead>
                                                            <th>Ilość</th>
                                                            <th>Cena katalogowa(netto):</th>
                                                            <th>Wartość rabatu(netto):</th>
                                                            <th>Cena(netto) po rabacie</th>
                                                            <th>Cena(brutto) po rabacie</th>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td className="pt-6">{chosenOffer?.accessories[index]?.quantity}</td>
                                                                <td className="pt-6">{(chosenOffer?.accessories[index]?.priceData.netRegularPrice / 100).toFixed(2)} zł</td>
                                                                <td className="pt-6 ">{((chosenOffer?.accessories[index]?.priceData.netRegularPrice - chosenOffer?.accessories[index]?.priceData.netPrice) / 100).toFixed(2)} zł</td>
                                                                <td className="pt-6 ">{(chosenOffer?.accessories[index]?.priceData.netPrice / 100).toFixed(2)} zł</td>
                                                                <td className="pt-6 text-dark fw-boldest">{(chosenOffer?.accessories[index]?.priceData.finalPrice / 100).toFixed(2)} zł</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/*<td className="pt-6">{acc?.quantity}</td>*/}
                                                    {/*<td className="pt-6">{((acc?.priceData.finalPrice / 100)).toFixed(2)} zł</td>*/}
                                                    {/*<td className="pt-6 text-dark fw-boldest">{acc?.quantity * (acc?.priceData.finalPrice / 100).toFixed(2)} zł</td>*/}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <div className="mw-300px">
                                            <div className="d-flex flex-stack">
                                                <div className="fw-bold pe-10 text-gray-600 fs-7">Do
                                                    zapłaty(netto)
                                                </div>
                                                <div className="text-end fw-bolder fs-6 text-gray-800">
                                                    {(chosenOffer?.priceData?.netRegularPrice / 100).toFixed(2)} zł
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="m-0">
                        <div
                            className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                            <div className="mb-6 text-end">
                                    <span
                                        className="badge badge-light-success me-2">{chosenOffer?.status === "Nowa" ? "Nowa oferta" : null}</span>
                            </div>
                            <h6 className="mb-5 fw-boldest text-gray-700 text-hover-primary text-uppercase">Dane
                                firmy(zamawiającej)</h6>
                            <div className="mb-6 d-flex justify-content-between align-items-center">
                                <div id="code-qr3"></div>
                                <div>
                                    <div className="fw-bold text-gray-600 fs-7">Numer klienta</div>
                                    <div
                                        className="fw-bolder text-gray-800 fs-6 text-end">{chosenOffer?.client?.distributor ? chosenOffer?.client.distributor.internalNumber : chosenOffer?.client?.internalNumber}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="mb-6 w-50">
                                    <div className="fw-bold text-gray-600 fs-7">Nazwa firmy</div>
                                    <div
                                        className="fw-bolder text-gray-800 fs-6">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.company : chosenOffer?.client.company || "-"}</div>
                                </div>
                                <div className="mb-6 w-50 text-end">
                                    <div className="fw-bold text-gray-600 fs-7">NIP</div>
                                    <div
                                        className="fw-bolder fs-6 text-gray-800 d-flex align-items-center justify-content-end">
                                            <span
                                                className="fs-7 text-danger d-flex align-items-center">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.vatNumber : chosenOffer?.client?.vatNumber || "-"}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="mb-6">
                                    <div className="fw-bold text-gray-600 fs-7">Adres:</div>
                                    <div
                                        className="fw-bolder text-gray-800 fs-6">{chosenOffer?.client.distributor ? "ul. " + chosenOffer?.client.distributor.street : chosenOffer?.client?.street ? "ul." + chosenOffer?.client?.street : "-"}</div>
                                    {chosenOffer?.client?.zipCode && <div
                                        className="fw-bolder text-gray-800 fs-6">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.zipCode : chosenOffer?.client?.zipCode} {chosenOffer?.client.distributor ? chosenOffer?.client.distributor.city : chosenOffer?.client?.city}</div>}
                                </div>
                            </div>
                            <h6 className="mb-5 mt-5 fw-boldest text-gray-600 text-hover-primary text-uppercase">Osoba
                                zamawiająca</h6>
                            <div className="d-flex justify-content-between">
                                <div className="mb-6">
                                    <div className="fw-bold text-gray-600 fs-7">Imię i nazwisko:</div>
                                    <div
                                        className="fw-bolder text-gray-800 fs-6">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.name + " " + chosenOffer?.client.distributor.surname : chosenOffer?.client?.name + " " + chosenOffer?.client?.surname}</div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div className="mb-5">
                                    <div className="fw-bold text-gray-600 fs-7">Adres email:</div>
                                    <div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center">
                                            <span
                                                className="fs-7 text-danger d-flex align-items-center">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.email : chosenOffer?.client?.email || "-"}</span>
                                    </div>
                                </div>
                                <div>
                                    <div className="fw-bold text-gray-600 fs-7">Numer telefonu:</div>
                                    <div
                                        className="fw-bolder fs-6 text-gray-800 d-flex align-items-center justify-content-end">
                                            <span
                                                className="fs-7 text-danger d-flex align-items-center">{chosenOffer?.client.distributor ? chosenOffer?.client.distributor.phone : chosenOffer?.client?.phone || "-"}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {BasketPreview}
