/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {IAccessGroupDetails} from "../accessGroups/AccessGroupAddModel";
import Modal from "@mui/material/Modal";
import {style, theme} from "../adminList/Admin";
import Box from "@mui/material/Box";
import {BasketPreview} from "./BasketPreview";
import {Pagination} from "@mantine/core";
import {deleteNewOffer, deleteOffer, getPdf, printPdf} from "../offerList/OfferListFunctions";
import {fetchAllAdminGroups} from "../accessGroups/AccessGroupFunctions";
import {UserModel} from "../../modules/auth/models/UserModel";

type Props = {
    className: string;
    searchValue: string;
}

const BasketTable: React.FC<Props> = () => {
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [chosenListItem, setChosenListItem] = useState<IAccessGroupDetails | null>(null)
    const [openPreview, setOpenPreview] = React.useState(false);
    const queryClient = useQueryClient();
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const {data: adminGroups} = useQuery(["groups"], () => fetchAllAdminGroups(accessToken, ""));
    const userGroup = adminGroups?.find(e => e.id === user.userGroupId);
    const [openDelete, setOpenDelete] = useState(false)
    const handleOpenPreview = () => setOpenPreview(true);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClosePreview = () => {
        setChosenListItem(null)
        setOpenPreview(false)
    };

    const deleteNewOfferMutation = useMutation(deleteNewOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries("rejectedOfferCarts")
            setOpenDelete(false)
            setAnchorEl(null);
        },
    })

    const fetchRejectedBaskets = async (url) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
        const data = await response.json();
        return data;
    }
    const [page, setPage] = useState(1)
    const {
        data: rejectedCartListAll
    } = useQuery("rejectedOfferCarts", () => fetchRejectedBaskets("carts/abandoned?pagination=false"))

    const {
        data: rejectedCartList,
        isLoading,
        isError
    } = useQuery("rejectedOfferCarts", () => fetchRejectedBaskets(`carts/abandoned?page=${page}&itemsPerPage=30`))

    const totalPages = Math.ceil(rejectedCartListAll?.length / 30)

    return (
        <>
            {/* begin::Header */}
            {/* end::Header */}
            {/* begin::Body */}
            <div>
                {(isError || rejectedCartList?.lenght === 0) &&
                    <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {/* begin::Table container */}
                {!isLoading && rejectedCartList?.length > 0 && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>Firma</th>
                            <th>Data otrzymania</th>
                            <th>Status</th>
                            <th>Nr. IFS</th>
                            <th>Wartość netto</th>
                            <th>Imię i nazwisko</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {rejectedCartList?.length > 0 && rejectedCartList?.map(item => {
                            return <tr>
                                <td>{item?.client?.company}</td>
                                <td>{new Date(item.updatedAt).toLocaleDateString()}</td>
                                <td>Nowa</td>
                                <td>{item?.client?.internalNumber}</td>
                                <td>{(item?.priceData?.netPrice / 100) + " zł" || "-"}</td>
                                <td>{item?.client?.name + " " + item?.client?.surname}</td>
                                <td>{item?.client?.email}</td>
                                <td>
                                    <div>
                                        {/* begin::Menu */}
                                        <Button
                                            id={`basic-button${item.id}`}
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={(e) => {
                                                setChosenListItem(item)
                                                handleClick(e)
                                            }}
                                        >
                                            <KTSVG path='/media/icons/duotune/general/gen024.svg'
                                                   className='svg-icon-2'/>
                                        </Button>
                                        <Menu
                                            id={`basic-menu${item.id}`}
                                            anchorEl={anchorEl}
                                            open={open}
                                            onClose={handleClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                            sx={{
                                                boxShadow: 0
                                            }}
                                        >
                                            <MenuItem onClick={() => {
                                                handleClose()
                                                handleOpenPreview()
                                            }} className="w-100">
                                                <span className="w-100 text-center">Podgląd</span>
                                            </MenuItem>

                                            <MenuItem onClick={() => {
                                                handleClose()
                                                printPdf(chosenListItem.id, accessToken, '', 'carts')
                                            }}><span
                                                className="w-100 text-center">Drukuj</span></MenuItem>
                                            {userGroup?.isOrderPreviewPDFAccess &&
                                                <MenuItem onClick={() => {
                                                    handleClose()
                                                    getPdf(chosenListItem.id, accessToken, '', 'carts')
                                                }}><span
                                                    className="w-100 text-center">Pobierz PDF</span></MenuItem>}
                                            <MenuItem onClick={() => {
                                                setOpenDelete(true)
                                            }
                                            }>
                                                <button className='btn btn-danger btn-sm px-4 w-100'>
                                                    Usuń
                                                </button>
                                            </MenuItem>
                                        </Menu>
                                        {/* end::Menu */}
                                    </div>
                                </td>
                            </tr>
                        })}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                    {totalPages > 1 && <Pagination total={totalPages} page={page} onChange={setPage}
                                                   style={{justifyContent: "flex-end"}}/>}
                </div>}
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={openPreview}
                onClose={handleClosePreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => handleClosePreview()}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>Podgląd zamówienia</h1>
                                    </div>
                                    <BasketPreview chosenOffer={chosenListItem} isLoading={isLoading}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>


            <Modal
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpenDelete(false)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>Usuń zamówienie</h1>
                                    </div>
                                    <>
                                        <p style={{fontSize: 20}}>Czy na pewno chcesz
                                            usunąć wybrane zamówienie?</p>
                                        <div className="d-flex justify-content-between w-100 mt-10">
                                            <button
                                                onClick={() => {
                                                    deleteNewOfferMutation.mutate({
                                                        accessToken,
                                                        cartId: chosenListItem.id
                                                    })
                                                }}
                                                className='btn btn-danger'>Tak, usuń
                                            </button>
                                            <button
                                                onClick={() => setOpenDelete(false)}
                                                className='btn btn-primary'>Nie, zamknij
                                            </button>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </>
    )
}

export {BasketTable}
