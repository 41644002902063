import React, {useEffect, useState} from 'react'
import {IStatusChangeDetails, statusInitValues as initialValues} from './StatusChangeModel';
import {useFormik} from 'formik'
import {ISizesDetails} from "../components/sizes/SizesAddModel";
import {useMutation, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {changeStatus} from "./OfferListFunctions";
import {useParams} from "react-router-dom";

const statusOptions: { name: string, value: number }[] = [
    {
        name: "Nowa",
        value: 1
    },
    {
        name: "Zatwierdzone przez dystrybutora",
        value: 2
    },
    {
        name: "Wysłane",
        value: 3
    },
    {
        name: "Zatwierdzone",
        value: 4
    },
    {
        name: "Anulowana",
        value: 5
    },
    {
        name: "Wysłane do dystrybutora",
        value: 6
    },
]
const StatusChangeForm: React.FC<{ chosenListItem: {} | null | undefined }> = (props: { chosenListItem: {} }) => {
    const {chosenListItem, setOpenStatusChange} = props;
    const [initValues, setInitValues] = useState<ISizesDetails>(initialValues);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const params = useParams();
    const queryClient = useQueryClient();
    const [cancelNote, setCancelNote] = useState(undefined);
    const [status, setStatus] = useState("");
    useEffect(() => {
        if (chosenListItem) {
            setInitValues({status: chosenListItem.status})
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const changeStatusMutation = useMutation(changeStatus, {
        onSuccess: () => {
            setOpenStatusChange(false)
            queryClient.invalidateQueries("clientsRequests")
        },
        onError: () => {
            setTimeout(() => {
                setOpenStatusChange(false)
            }, 1500)
        }
    })

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IStatusChangeDetails>({
        initialValues: initValues,
        enableReinitialize: true,
        onSubmit: (values) => {
            changeStatusMutation.mutate({
                url: params.type,
                accessToken,
                data: {
                    status: +values.status,
                    cancelNote: cancelNote ? cancelNote : ""
                },
                statusId: chosenListItem.id
            })
        },
    })
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className="d-flex flex-column me-n7 pe-7">
                            <div className="fv-row mb-7">

                                <label className="required fw-bold fs-6 mb-2">Status oferty</label>
                                <select

                                    className="form-select form-select-solid"
                                    {...formik.getFieldProps("status")}>
                                    <option>Wybierz status</option>
                                    {statusOptions?.map((option) => (
                                        <option key={option.name} selected={option.value === chosenListItem.status}
                                                value={option.value}>{option.name}</option>
                                    ))}
                                </select>
                                {(chosenListItem.status === 5 || formik.values.status === "5") && <>
                                    <p style={{fontSize: 20, marginBottom: 0}}>Podaj powód
                                        anulowania {params.type === "offers" ? "oferty" : "zamówienia"} !</p>
                                    <textarea
                                        onChange={(e) => {
                                            setCancelNote(e.target.value)
                                        }
                                        }
                                        defaultValue={chosenListItem?.cancelNote}
                                        rows={5}
                                        style={{width: "100%", resize: "none", padding: 10}}/>
                                </>}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        {changeStatusMutation.isIdle &&
                            <button type='submit' className='btn btn-primary'>
                                Zapisz
                            </button>}
                    </div>
                </form>
                {changeStatusMutation.isLoading && (
                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </div>

                )}
                {changeStatusMutation.isSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {changeStatusMutation.isError &&
                    <p className="text-center fw-bold" style={{color: "red", fontSize: 16}}>Zmiana statusu się nie
                        powdiodła! Sprubój ponownie!</p>}
            </div>
        </div>
    )
}

export {StatusChangeForm}
