export const deleteComponentById = async ({accessToken, id} : {accessToken: string, id: string})  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/component_items/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się usunąć komponentu!")
    }
};
export const deleteAccessoryById = async ({accessToken, id} : {accessToken: string, id: string})  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/accessories/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się usunąć komponentu!")
    }
};

