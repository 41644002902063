/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {ContentTable} from "./ContentTable";


const ContentListPage: FC = () => (
    <>
        <ContentTable className='card-xxl-stretch mb-5 mb-xl-8'/>
    </>
)

const ContentListWrapper: FC = () => {

    return (
        <>
            <PageTitle>Lista treści stałych</PageTitle>
            <ContentListPage/>
        </>
    )
}

export {ContentListWrapper}
