import React, {FC, useEffect, useState} from 'react';
import {KTSVG} from "./../../../_metronic/helpers";
import {IAdminDetails} from "../adminList/AdminAddModel";
import {useQuery} from "react-query";
import {getAllKTM} from "./StructureKtmFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import SubMenu from "../../component/subMenu/SubMenu";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {style, theme} from "../adminList/Admin";
import {StructureKTMCreateForm} from "./StructureKTMCreateForm";
import _ from "lodash";


interface ChosenComponent {
    id: number,
    description: string,
    ktmSymbol: string,
    descriptionShort: string
}

const typesOptions = [
    {value: 1, label: "Rodzaj bram"},
    {value: 2, label: "Szerokość"},
    {value: 3, label: "Wysokość"},
    {value: 4, label: "Nadproże"},
    {value: 5, label: "Rodzaje napędów"},
    {value: 6, label: "Prowadzenie"}
];


const StructureKTM: FC = () => {
    const [chosenListItem, setChosenListItem] = useState<IAdminDetails | null>(null)
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })
    const [open, setOpen] = React.useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const [chosenComponent, setChosenComponent] = useState<ChosenComponent[]>([])
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const {data: ktmStructure, isLoading} = useQuery("ktm", () => getAllKTM(accessToken));
    const [duplicatedElements, setDuplicatedElements] = useState([])
    const [duplicatedDescriptionElements, setDuplicatedDescriptionElements] = useState([])
    const ktmStructureSorted = ktmStructure?.sort((a, b) => a.position - b.position)

    useEffect(() => {
        const ktmStructureGrouped = _.groupBy(ktmStructureSorted, "position")
        const ktmPositionStructureGrouped = _.groupBy(ktmStructureSorted, "descriptionPosition")
        const ktmStructureGroupedFiltered = Object.entries(ktmStructureGrouped)?.filter(e => e[1].length > 1)
        const ktmStructureDescriptionGroupedFiltered = Object.entries(ktmPositionStructureGrouped)?.filter(e => e[1].length > 1)
        if (ktmStructureGroupedFiltered.length === 0) {
            setDuplicatedElements([])
        } else {
            setDuplicatedElements(ktmStructureGroupedFiltered[0][1])
        }
        if (ktmStructureDescriptionGroupedFiltered.length === 0) {
            setDuplicatedDescriptionElements([])
        } else {
            setDuplicatedDescriptionElements(ktmStructureDescriptionGroupedFiltered[0][1])
        }
    }, [ktmStructure])


    return (
        <>
            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                {isLoading &&
                    <div className="d-flex w-100 justify-content-center m-5">
                        <CircularProgress/>
                    </div>}
                {duplicatedElements?.length > 0 &&
                    <h3 className="text-center p-10" style={{color: "red"}}>KTM zawiera zduplikowane
                        pozycje! <br/> Musisz je zmienić!</h3>}
                {!isLoading && duplicatedElements?.length === 0 && <div className='card-header border-0 pt-5 w-100'>
                    <h3 className="text-center w-100 mb-7">Przykładowy KTM</h3>

                    {!isLoading && <div className="d-flex justify-content-around w-100 m-auto">
                        <div style={{fontSize: 20}}>
                            <div style={{fontWeight: 500}} className="d-flex mt-5 mb-5 justify-content-center">
                                <div className="text-center">R</div>
                            </div>
                            <div style={{color: "#A1A5B7"}}
                                 className="text-center">Brama
                            </div>
                        </div>
                        {ktmStructureSorted?.map(item => {
                            return (
                                <div style={{fontSize: 20}}>
                                    <div style={{fontWeight: 500}}
                                         className="d-flex mt-5 mb-5 justify-content-center">{new Array(item.length).fill(characters[Math.floor(Math.random() * characters.length)]).map((item, i) => {

                                        return (
                                            <div className="text-center">{item}</div>
                                        )
                                    })}</div>
                                    <div style={{color: "#A1A5B7"}}
                                         className="text-center">{item?.component?.name ? item?.component?.name : item.type ? typesOptions[typesOptions.findIndex(e => e.value === item.type)].label : "-"}</div>
                                </div>

                            )
                        })}
                    </div>}
                </div>}
            </div>
            {false && <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                <div className='card-header border-0 pt-5 w-100'>
                    <h3 className="text-center w-100 mb-7">Przykładowy opis skrócony</h3>
                    {isLoading &&
                        <div className="d-flex w-100 justify-content-center m-5">
                            <CircularProgress/>
                        </div>}
                    {!isLoading && <div className="d-flex justify-content-around w-100 m-auto">
                        <div style={{fontSize: 20}}>
                            <div style={{fontWeight: 500}} className="d-flex mt-5 mb-5 justify-content-center">
                                <div className="text-center">R</div>
                            </div>
                            <div style={{color: "#A1A5B7"}}
                                 className="text-center">Brama
                            </div>
                        </div>
                        {ktmStructureSorted?.map(item => {
                            return (
                                <div style={{fontSize: 20}}>
                                    <div style={{fontWeight: 500}}
                                         className="d-flex mt-5 mb-5 justify-content-center">{new Array(item.descriptionLength).fill(characters[Math.floor(Math.random() * characters.length)]).map((item, i) => {

                                        return (
                                            <div className="text-center">{item}</div>
                                        )
                                    })}</div>
                                    <div style={{color: "#A1A5B7"}}
                                         className="text-center">{item?.component?.name ? item?.component?.name : item.type ? typesOptions[typesOptions.findIndex(e => e.value === item.type)].label : "-"}</div>
                                </div>

                            )
                        })}
                    </div>}
                </div>
            </div>}
            {!isLoading && <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 w-100 d-flex justify-content-end'>
                    <div
                        className='card-toolbar'
                        title='Kliknij, aby dodać element'
                    >
                        <button
                            className='btn btn-sm btn-light-primary'
                            onClick={() => setOpen(!open)}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                            <span className="d-none d-sm-inline">Dodaj</span>
                        </button>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bolder text-muted'>
                                <th>Komponent</th>
                                <th>Pozycja KTM</th>
                                <th>Długość w KTM</th>
                                <th>Pozycja Opisu</th>
                                <th>Długość Opisu</th>
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {chosenComponent && ktmStructureSorted?.map(component => {
                                return (
                                    <tr>
                                        <td>
                                            {component?.component?.name ? component?.component?.name : component.type ? typesOptions[typesOptions.findIndex(e => e.value === component.type)].label : "-"}
                                        </td>
                                        <td style={duplicatedElements?.includes(component) ? {
                                            color: "red",
                                            fontWeight: 500
                                        } : null}>{component.position}{duplicatedElements?.includes(component) ? "!" : ""}</td>
                                        <td>{component.length}</td>
                                        <td style={duplicatedDescriptionElements?.includes(component) ? {
                                            color: "red",
                                            fontWeight: 500
                                        } : null}>{component.descriptionPosition}{duplicatedDescriptionElements?.includes(component) ? "!" : ""}</td>
                                        <td>{component.descriptionLength}</td>
                                        <td>
                                            <SubMenu item={component} setChosenListItem={setChosenListItem}
                                                     chosenListItem={chosenListItem}
                                                     modalOpen={open} setOpen={setOpen} url="ktm_structures"/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={theme}>
                        <Box sx={style}>
                            <div>
                                <div>
                                    <div className='modal-header pb-0 border-0 justify-content-end'>
                                        <div
                                            onClick={() => setOpen(!open)}
                                            className='btn btn-sm btn-icon btn-active-color-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className='svg-icon-1'/>
                                        </div>
                                    </div>

                                    <div className='modal-body scroll-y'>
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} KTM</h1>
                                        </div>
                                        <StructureKTMCreateForm
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open}
                                            setOpen={setOpen}
                                            ktmStructureSorted={ktmStructureSorted}/>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </ThemeProvider>
                </Modal>
            </div>}
        </>
    );
};

export default StructureKTM;
