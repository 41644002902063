export interface IAccessGroupDetails {
    title: string | null,
    isGroupsAccess: boolean,
    isUsersAccess: boolean,
    isListClients: boolean,
    isComponents: boolean,
    isListKtm: boolean,
    isListPricing: boolean,
    isListAccess: boolean,
    isListContent: boolean,
    isAdminAccess: boolean,
    isListStatistic: boolean,
}



export const accessGroupsInitValues: IAccessGroupDetails = {
    title: "",
    isUsersAccess: false,
    isListClients: false,
    isComponents: false,
    isListKtm: false,
    isListPricing: false,
    isListAccess: false,
    isListContent: false,
    isListStatistic: false,
}
