/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useState} from 'react'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {FilterDropdown} from '../../component/dropdown/FilterDropdown'
import {OfferPreview} from "./OfferPreview";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {changeStatus, deleteOffer, getOrdersAndOffers, updateOffer} from "./OfferListFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {style, theme} from "../adminList/Admin";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {IAccessGroupDetails} from "../accessGroups/AccessGroupAddModel";
import {useParams} from "react-router-dom";
import Fade from "@mui/material/Fade";
import {StatusChangeForm} from "./StatusChangeForm";
import {PdfPreview} from "./PDFPreview";
import CustomIfs from "../components/drive/CustomIFS";
import {deleteModalStyle} from "../../component/subMenu/SubMenu";
import CustomDateField from "./CustomDateField";
import SubMenu from "./SubMenu";
import OpenOfferPreview from "./OpenOfferPreview";
import {BasketListWrapper} from "../basketList/BasketListWrapper";
import {Pagination, Select} from "@mantine/core";
import {DateRangePicker, DateRangePickerValue} from "@mantine/dates";
import 'dayjs/locale/pl';
import moment from "moment";

type Props = {
    className: string
}

export interface IOffer {
    id: string,
    items: {
        gate: {
            id: string,
            gate_type: {
                id: string,
                name: string
            },
            width: number,
            height: number,
            depth: number,
            left_area: number,
            right_area: number,
            stamping: number,
            components: [
                {
                    id: string,
                    name: string,
                    price: number,
                    values: [
                        {
                            component: string,
                            value: [
                                {}
                            ]
                        }
                    ],
                    component: {
                        id: string,
                        title: string,
                        related_components: [
                            {}
                        ],
                        compontent_items: [
                            {}
                        ]
                    }
                }
            ],
            leading: {
                id: string,
                name: string,
                price: number,
                values: [
                    {
                        component: string,
                        value: [
                            {}
                        ]
                    }
                ],
                component: {
                    id: string,
                    title: string,
                    related_components: [
                        {}
                    ],
                    compontent_items: [
                        {}
                    ]
                }
            },
            KTM: string,
            discount_percentage: number,
            price: number,
            tax_amount: number,
            montage_cost: number,
            expiration_date: string
        }, quantity: number
    }[],
    price_data: {
        price_final: number,
        tax_amount: number,
        discount_amount: number
    },
    offer_status: string,
    client: {
        id: string,
        email: string,
        password: string,
        name: string,
        surname: string,
        company: string,
        company_short: string,
        phone: string,
        country: string,
        language: {
            id: string,
            name: string,
            language_code: string
        },
        vat_number: string,
        distributor: {
            id: string,
            client_number: string,
            internal_number: string,
            name: string,
            surname: string,
            company: string,
            vat_number: string,
            street: string,
            zip_code: string,
            city: string,
            phone: string,
            email: string,
            discounts: [
                {
                    gate_type: {
                        id: string,
                        name: string
                    },
                    percentage: number
                }
            ]
        }
    },
}

const customInputStyle = {
    maxWidth: 125,
    border: "1px solid #EFF2F5",
    padding: "5px 0"
}
const orderStatuses = ["-", "Nowa", "Potwierdzone przez dystrybutora", "Wysłana", "Potwierdzona", "Anulowana" , "Wysłane do dystrybutora"];


const OfferTable: React.FC<Props> = ({className}) => {
    const [filteredTableHeaderItems, setFilteredTableHeaderItems] = useState<{
        name: string,
        isChecked: boolean
    }[]>([]);
    const [chosenOffer, setChosenOffer] = useState<IOffer | null>(null);
    const [searchValue, setSearchValue] = useState("");
    const debounceSearch = useDebounce(searchValue, 1000)
    const [triggerSearch, setTriggerSearch] = useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [chosenListItem, setChosenListItem] = useState<IAccessGroupDetails | null>(null)
    const [offerId, setOfferId] = React.useState(undefined);
    const [openPreview, setOpenPreview] = React.useState(false);
    const [openOfferPreview, setOpenOfferPreview] = React.useState(false);
    const [openExportCsv, setOpenExportCsv] = React.useState(false);
    const [openPdfPreview, setOpenPdfPreview] = React.useState(false);
    const [openStatusChange, setOpenStatusChange] = React.useState(false);
    const [openCancel, setOpenCancel] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [chosenStatus, setChosenStatus] = useState(undefined);
    const [cancelNote, setCancelNote] = useState("");
    const [exportDate, setExportDate] = useState({
        from: '',
        to: ''
    })

    const getCsv = async () => {
        try {
            let httpClient = new XMLHttpRequest();
            let pdfLink = `${process.env.REACT_APP_API_URL}api/orders/export?from=${exportDate.from}&to=${exportDate.to}`;
            httpClient.open('get', pdfLink, true);
            httpClient.setRequestHeader("Authorization", `Bearer ${accessToken}`)
            httpClient.responseType = "blob";
            httpClient.onload = function () {
                const file = new Blob([httpClient.response], {type: 'application/csv'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement("a");
                link.href = fileURL;
                link.download = `raport.csv`;
                link.click();
                // document.body.removeChild(link);
                URL.revokeObjectURL(fileURL);
            };
            httpClient.send();
        } catch (e) {
            console.log(e);
        }
    }
    const handleOpenPreview = () => setOpenPreview(true);
    const params = useParams();
    const queryClient = useQueryClient();
    const [activeBtn, setActiveBtn] = useState("Wysłane");
    const [dateFilter, setDateFilter] = useState<DateRangePickerValue>([null, null]);
    const [extraDateFilter, setExtraDateFilter] = useState<DateRangePickerValue>([null, null]);
    const handleClosePreview = () => {
        setChosenListItem(null)
        setOpenPreview(false)
    };
    const handleCloseOfferPreview = () => {
        setChosenListItem(null)
        setOpenOfferPreview(false)
    };
    const handleClosePdfPreview = () => {
        setChosenListItem(null)
        setOpenPdfPreview(false)
    };

    const getFilteredTableHeaderItems = (filteredHeader: []) => {
        setFilteredTableHeaderItems(filteredHeader)
    }
    const handleChosenOffer = (offer: any) => {
        setChosenOffer(offer)
    }

    const updateOfferMutation = useMutation(updateOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries("allOffersPaginated")

        },
        onError: () => {
            setTimeout(() => {
                setOpenStatusChange(false)
            }, 1500)
        }
    })

    const deleteOfferMutation = useMutation(deleteOffer, {
        onSuccess: () => {
            queryClient.invalidateQueries("allOffersPaginated")
            setOpenDelete(false)
        },
    })

    const changeStatusMutation = useMutation(changeStatus, {
        onSuccess: () => {
            queryClient.invalidateQueries("allOffersPaginated")
            setTimeout(() => {
                setOpenCancel(false)
                changeStatusMutation.reset()
            }, 2000)
        }
    })

    const [page, setPage] = useState(1)

    useEffect(() => {
        const nextPage = page + 1;

        queryClient.prefetchQuery(
            ["allOffersPaginated", nextPage],
            () => getOrdersAndOffers(accessToken, params.type + `?page=${nextPage}&itemsPerPage=30`))
    }, [page, queryClient])


    const [chosenStatusFilter, setChosenStatusFilter] = useState(undefined)

    const searchByFilters = useCallback((searchValue, statusFilter, date, extraDate, page) => {
            const validDate = !date?.includes(null)
            const validExtraDate = !extraDate?.includes(null)
            const extraDateUrlAfter = params.type === 'offers' ? "&validUntil[strictly_after]=" : "&realizationDate[strictly_after]="
            const extraDateUrlBefore = params.type === 'offers' ? "&validUntil[strictly_before]=" : "&realizationDate[strictly_before]="


            const searchValueUrl = searchValue.trim().length > 0 ? "&simplesearch=" + searchValue : ""
            const filterValueUrl = statusFilter ? "&status=" + statusFilter : ""
            const dateFilter = validDate ? "&createdAt[strictly_after]=" + moment(new Date(date[0])).format('DD.MM.YYYY HH:mm:ss')
                + "&createdAt[strictly_before]=" + moment(new Date(date[1])).add(1, 'day').format('DD.MM.YYYY HH:mm:ss') : ''
            const extraDateFilter = validExtraDate ? extraDateUrlAfter + moment(new Date(extraDate[0])).format('DD.MM.YYYY HH:mm:ss')
                + extraDateUrlBefore + moment(new Date(extraDate[1])).add(1, 'day').format('DD.MM.YYYY HH:mm:ss') : ""

            return params.type + "?page=" + page + "&itemsPerPage=30" + searchValueUrl + filterValueUrl + dateFilter + extraDateFilter

        }, [dateFilter, extraDateFilter, debounceSearch, chosenStatusFilter, params.type]
    )

    const {
        data: allOffersPaginated,
        isFetching
    } = useQuery(["allOffersPaginated", params.type, page, dateFilter, extraDateFilter, debounceSearch, chosenStatusFilter], () => getOrdersAndOffers(accessToken, searchByFilters(debounceSearch, chosenStatusFilter, dateFilter, extraDateFilter, page)), {
        placeholderData: [],
        keepPreviousData: false,
        refetchOnWindowFocus: false
    })

    const totalPages = Math.ceil(allOffersPaginated?.totalItems / 30);

    useEffect(() => {
        setPage(1)
        setSearchValue("")
        setDateFilter([null, null])
        setExtraDateFilter([null, null])
        setChosenStatusFilter(undefined)
        setActiveBtn('Wysłane')
    }, [params.type]);

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 d-flex flex-nowrap gap-5'>
                <div className="d-flex align-items-center position-relative my-1 justify-content-start pt-5">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                  height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                  fill="black"/>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"/>
                        </svg>
                    </span>
                    <input type="text"
                           value={searchValue}
                           onChange={(e) => setSearchValue(e.target.value)}
                           className="form-control form-control-solid ps-14 me-5"
                           placeholder="Szukaj..."/>
                    <div className="w-100 d-flex">
                        {params.type === "orders" && activeBtn === 'Wysłane' &&
                            <Select id="ktm-select"
                                    searchable
                                    styles={(theme) => ({
                                        input: {
                                            padding: 20,
                                            borderRadius: "0,457rem",
                                            width: "100%",
                                            minWidth: 220
                                        }
                                    })}
                                    placeholder={"Filtruj po statusie"}
                                    name="drive-type"
                                    onChange={(e) => {
                                        if (e === 0) {
                                            setChosenStatusFilter(undefined)
                                        } else {
                                            setChosenStatusFilter(e)
                                        }
                                    }}
                                    data={[
                                        {value: 0, label: "Wszystkie"},
                                        {value: 2, label: "Potwierdzone przez dystrybutora"},
                                        {value: 3, label: "Wysłana"},
                                        {value: 4, label: "Potwierdzona"},
                                        {value: 5, label: "Anulowana"},
                                        {value: 6, label: "Wysłane do dystrybutora"},
                                    ]}
                                    className=" me-5">
                            </Select>

                        }
                        <DateRangePicker
                            className={'me-5'}
                            size={'md'}
                            locale={'pl'}
                            value={dateFilter}
                            inputFormat="DD/MM/YYYY"
                            placeholder={'Filtruj po dacie otrzymania'}
                            allowSingleDateInRange
                            style={{minWidth: 250}}
                            onChange={setDateFilter}
                        />
                        <DateRangePicker
                            size={'md'}
                            locale={'pl'}
                            value={extraDateFilter}
                            inputFormat="DD/MM/YYYY"
                            placeholder={`Filtruj po terminie ${params.type === 'orders' ? 'realizacji' : 'ważności'}`}
                            allowSingleDateInRange
                            style={{minWidth: 250}}
                            onChange={setExtraDateFilter}
                        />
                    </div>
                </div>
                {allOffersPaginated?.data?.length > 0 && !isFetching && <div className='card-toolbar pt-5'>
                    {params.type === "orders" && <div className='card-toolbar' style={{marginLeft: 'auto'}}>
                        <button
                            className='btn btn-sm btn-light-primary d-flex'
                            onClick={() => {
                                setOpenExportCsv(true)
                            }
                            }
                        >
                            <span className="d-none d-sm-block">Eksport zamówień do CSV</span>
                        </button>
                    </div>}
                    {/* begin::Menu */}
                    {activeBtn === 'Wysłane' && <button
                        onClick={() => {
                            setOpenFilter(prevState => !prevState)
                        }
                        }
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                        <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2'/>
                    </button>}
                    {activeBtn === 'Wysłane' && <FilterDropdown getFilteredTableHeaderItems={getFilteredTableHeaderItems} openFilter={openFilter}/>}
                    {/* end::Menu */}
                </div>}
            </div>
            <div className='d-flex justify-content-center pt-5'>
                {params.type === "orders" && <div className="d-none d-sm-flex align-items-center">
                    <a href="#"
                       style={{minWidth: 90}}
                       onClick={() => {
                           setActiveBtn("Wysłane")
                       }}
                       className={`btn btn-sm ${activeBtn === "Wysłane" ? "btn-primary" : "btn-secondary"} mx-5`}>Wysłane</a>
                    <a
                        href="#"
                        style={{minWidth: 90}}
                        onClick={() => {
                            setActiveBtn("Nowe")
                        }}
                        className={`btn btn-sm ${activeBtn === "Nowe" ? "btn-primary" : "btn-secondary"} mx-5`}>Nowe</a>
                </div>}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {isFetching && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {allOffersPaginated?.data?.length === 0 && !isFetching && <Fade in={!isFetching}>
                    <div className="d-flex justify-content-center p-10"><p>Brak elementów do wyświetlenia...</p></div>
                </Fade>}
                {/* begin::Table container */}
                {activeBtn === 'Wysłane' && <Fade in={!isFetching && activeBtn === 'Wysłane'}>
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        {allOffersPaginated?.data?.length> 0 && !isFetching &&
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                <tr className='fw-bolder text-muted'>
                                    {filteredTableHeaderItems.filter(item => item.isChecked).map(item => <th
                                        key={item.name}>{item.name}</th>)}
                                </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                {allOffersPaginated?.data?.filter(chosenStatus?.trim()?.length > 0 ? r => r.status === +chosenStatus : r => r).sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)).map((order, index) => {
                                    return (
                                        <tr key={order.id}>
                                            {filteredTableHeaderItems[0]?.isChecked &&
                                                <td>{order?.client?.company || "-"}</td>}
                                            {filteredTableHeaderItems[1]?.isChecked &&
                                                <td>{params.type === "offers" ? order.offerNumber : order.orderNumber || "-"}</td>}
                                            {filteredTableHeaderItems[2]?.isChecked &&
                                                <td>{order?.clientNumber || "-"}</td>}
                                            {filteredTableHeaderItems[3]?.isChecked &&
                                                <td>{order?.createdAt ? (new Date(order?.createdAt)?.toLocaleDateString()) : "-"}</td>}
                                            {filteredTableHeaderItems[4]?.isChecked &&
                                                <td>{orderStatuses[order.status]}</td>}
                                            {filteredTableHeaderItems[5]?.isChecked && <td>
                                                <CustomIfs order={order} customInputStyle={customInputStyle}/>
                                            </td>}
                                            {filteredTableHeaderItems[6]?.isChecked &&
                                                <td>{order.cancelNote || "-"}</td>}
                                            {filteredTableHeaderItems[7]?.isChecked && <td>
                                                {params.type === "orders" ? "-" :
                                                    <CustomDateField order={order}/>
                                                }
                                            </td>}
                                            {filteredTableHeaderItems[8]?.isChecked && <td>
                                                {params.type === "offers" ? "-" :
                                                    <CustomDateField order={order}/>
                                                }
                                            </td>}
                                            {filteredTableHeaderItems[9]?.isChecked && order?.orderData &&
                                                <td>{order?.orderData?.basePriceData ? (order?.orderData?.priceData?.netPrice / 100).toFixed(2) + " zł" : "-"}</td>
                                            }
                                            {filteredTableHeaderItems[9]?.isChecked && order?.offerData &&
                                                <td>{order?.offerData?.priceData ? (order?.offerData?.priceData?.netPrice / 100).toFixed(2) + " zł" : "-"}</td>
                                            }
                                            {filteredTableHeaderItems[10]?.isChecked &&
                                                <td>{order?.client?.name ? order?.client?.name + " " + order.client.surname : "-"}</td>}
                                            {filteredTableHeaderItems[11]?.isChecked &&
                                                <td>{order?.client?.email ? order?.client?.email : "-"}</td>}
                                            {filteredTableHeaderItems[12]?.isChecked &&
                                                <td>{order?.client?.phone ? order.client.phone : "-"}</td>}
                                            {filteredTableHeaderItems[13]?.isChecked && <td><a
                                                href="#">{order?.client?.distributor?.company ? order?.client?.distributor?.company : "-"}</a>
                                            </td>}
                                            {filteredTableHeaderItems[14]?.isChecked &&
                                                <td><a onClick={() => {
                                                    setOfferId(params.type === 'orders' ? order?.offer?.id : order?.order?.id)
                                                    setOpenOfferPreview(true)
                                                }}
                                                       href="#">{params.type === 'orders' ? order?.offer?.offerNumber || "-" : order?.order?.orderNumber || "-"}</a>
                                                </td>}
                                            {filteredTableHeaderItems[15]?.isChecked && <td className={'text-center'}>
                                                <SubMenu
                                                    order={order}
                                                    setChosenListItem={setChosenListItem}
                                                    chosenListItem={chosenListItem}
                                                    handleOpenPreview={handleOpenPreview}
                                                    setOpenStatusChange={setOpenStatusChange}
                                                    setOpenCancel={setOpenCancel}
                                                    setOpenDelete={setOpenDelete}
                                                />
                                            </td>}
                                        </tr>
                                    )
                                })
                                }
                                </tbody>
                                {/* end::Table body */}
                            </table>}
                        {/* end::Table */}
                    </div>
                </Fade>}
                {activeBtn === 'Nowe' && params.type === "orders" &&
                    <BasketListWrapper searchValue={searchValue}/>
                }
                {/* end::Table container */}
                {activeBtn === 'Wysłane' && totalPages > 1 &&
                    <Pagination total={totalPages} page={page} onChange={setPage}
                                style={{justifyContent: "flex-end"}}/>}
            </div>
            {/* begin::Body */}

            {openCancel && <div style={deleteModalStyle}>
                {!changeStatusMutation.isSuccess && <>
                    <p style={{fontSize: 20, marginBottom: 0}}>Podaj powód
                        anulowania {params.type === "offers" ? "oferty" : "zamówienia"} !</p>
                    <textarea
                        onChange={(e) => {
                            setCancelNote(e.target.value)
                        }
                        }
                        rows={5}
                        style={{width: "100%", resize: "none", padding: 10}}/>
                    <div className="d-flex justify-content-between w-100">
                        <button
                            onClick={() => {
                                changeStatusMutation.mutate({
                                    url: params.type,
                                    accessToken,
                                    data: {
                                        cancelNote: cancelNote,
                                        status: 5
                                    },
                                    statusId: chosenListItem.id
                                })
                            }}
                            className='btn btn-danger'>Anuluj {params.type === "offers" ? "ofertę" : "zamówienie"}</button>
                        <button
                            onClick={() => {
                                setCancelNote("")
                                setOpenCancel(false)
                            }}
                            className='btn btn-primary'>Zamknij
                        </button>
                    </div>
                </>}

                {changeStatusMutation.isSuccess &&
                    <p style={{
                        fontSize: 20,
                        color: "green"
                    }}>{params.type === "offers" ? "Oferta została anulowana" : "Zamówienie zostało anulowane"} !</p>}
            </div>}

            <Modal
                open={openPreview}
                onClose={handleClosePreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => handleClosePreview()}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{params.type === "offers" ? 'Podgląd oferty' : 'Podgląd zamówienia'}</h1>
                                    </div>
                                    <OfferPreview chosenOffer={chosenListItem} isFetching={isFetching}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
            <Modal
                open={openOfferPreview}
                onClose={handleCloseOfferPreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <OpenOfferPreview offerId={offerId} closePreview={handleCloseOfferPreview}
                                          chosenListItem={chosenListItem}/>
                    </Box>
                </ThemeProvider>
            </Modal>
            <Modal
                open={openStatusChange}
                onClose={() => setOpenStatusChange(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpenStatusChange(false)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>Zmień status zamówienia</h1>
                                    </div>
                                    <StatusChangeForm chosenListItem={chosenListItem}
                                                      setOpenStatusChange={setOpenStatusChange}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>

            <Modal
                open={openDelete}
                onClose={() => setOpenDelete(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpenDelete(false)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>Usuń {params.type === 'offers' ? 'ofertę' : 'zamówienie'}</h1>
                                    </div>
                                    <>
                                        <p style={{fontSize: 20}}>Czy na pewno chcesz
                                            usunąć {params.type === 'offers' ? 'wybraną ofertę' : 'wybrane zamówienie'}?</p>
                                        <div className="d-flex justify-content-between w-100 mt-10">
                                            <button
                                                onClick={() => {
                                                    deleteOfferMutation.mutate({
                                                        accessToken,
                                                        url: params.type === 'offers' ? 'offers' : 'orders',
                                                        orderId: chosenListItem.id
                                                    })
                                                }}
                                                className='btn btn-danger'>Tak, usuń
                                            </button>
                                            <button
                                                onClick={() => setOpenDelete(false)}
                                                className='btn btn-primary'>Nie, zamknij
                                            </button>
                                        </div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>

            <Modal
                open={openPdfPreview}
                onClose={handleClosePdfPreview}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        maxWidth: {
                            xs: "80%",
                            md: "70%"
                        },
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => handleClosePdfPreview()}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='pdf-preview modal-body scroll-y'>
                                    <div className='pdf-preview-top text-center mb-10 d-flex justify-content-between'>
                                        <div className="pdf-preview-top-item">
                                            <img alt="Logo" className="logo" src="/media/logos/logo.png"/>
                                        </div>
                                        <div className="pdf-preview-top-item">
                                            <p>StalProdukt-Zamość Sp. z o.o.</p>
                                            <p>ul. Kilińskiego 86, 22-400 Zamość</p>
                                        </div>
                                        <div className="pdf-preview-top-item">
                                            <p>tel. 84 639 97 85</p>
                                            <p>e-mail: zamowienia@futryna.com.pl</p>
                                        </div>
                                    </div>
                                    <PdfPreview chosenOffer={chosenListItem}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
            <Modal
                open={openExportCsv}
                onClose={() => setOpenExportCsv(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={{
                        ...style,
                        width: "fit-content",
                    }}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpenExportCsv(false)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className="d-flex flex-column align-items-center gap-5">
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>Eksport zamówień do CSV</h1>
                                        </div>
                                        <div className="d-flex gap-5">
                                            <div className="w-50">
                                                <label className="w-100">Od
                                                    <input type="date" className="form-control" onChange={e => {
                                                        setExportDate(prevState => {
                                                            return {
                                                                ...prevState,
                                                                from: e.target.value,
                                                            }
                                                        })
                                                    }
                                                    }/>
                                                </label>
                                            </div>
                                            <div className="w-50">
                                                <label className="w-100">Do
                                                    <input type="date" className="form-control" onChange={e => {
                                                        setExportDate(prevState => {
                                                            return {
                                                                ...prevState,
                                                                to: e.target.value,
                                                            }
                                                        })
                                                    }
                                                    }/>
                                                </label>
                                            </div>
                                        </div>
                                        <button onClick={getCsv} type="submit"
                                                className="btn btn-primary mt-5">Eksportuj
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    )
}

export {OfferTable}
