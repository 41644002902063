/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";


export function FilterDropdown(props: any) {
    const {openFilter} = props;
    const params = useParams();
    const tableHeaderFilterItemsInitial = [
        {
            name: "Firma",
            isChecked: true
        },
        {
            name: params.type === "orders" ? "Nr. zamówienia" : "Nr. oferty",
            isChecked: true
        },
        {
            name: params.type === "orders" ? "Nr. wew. zamówienia" : "Nr. wew. oferty",
            isChecked: true
        },
        {
            name: "Data otrzymania",
            isChecked: true
        },
        {
            name: "Status",
            isChecked: params.type !== "offers"
        },
        {
            name: "Nr. IFS",
            isChecked: params.type !== "offers"
        },
        {
            name: "Powód anulowania",
            isChecked: false
        },
        {
            name: "Termin ważności",
            isChecked: params.type === "offers"
        },
        {
            name: "Termin realizacji",
            isChecked: params.type === "orders"
        },
        {
            name: "Wartosć netto",
            isChecked: true
        },
        {
            name: "Imię nazwisko",
            isChecked: true
        },
        {
            name: "Email",
            isChecked: true
        },
        {
            name: "Telefon",
            isChecked: true
        },
        {
            name: "Dystrybutor",
            isChecked: true
        },
        {
            name: params.type === "orders" ? "Oferta" : "Zamówienie",
            isChecked: true
        },
        {
            name: "Akcje",
            isChecked: true
        },

    ]
    useEffect(() => {
        setTableHeaderFilterItems(tableHeaderFilterItemsInitial)
    }, [params.type])
    const [tableHeaderFilterItems, setTableHeaderFilterItems] = useState(tableHeaderFilterItemsInitial)

    const onChange = (item: {}) => {
        const existingItemIndex = tableHeaderFilterItems.findIndex(tableItem => tableItem === item)
        const updatedArray = [...tableHeaderFilterItems]
        const updatedItem = updatedArray[existingItemIndex]
        updatedItem.isChecked = !updatedItem.isChecked
        updatedArray[existingItemIndex] = updatedItem
        setTableHeaderFilterItems(updatedArray)
    }

    useEffect(() => {
        props.getFilteredTableHeaderItems(tableHeaderFilterItems)
    }, [tableHeaderFilterItems])

    // useEffect(() => {
    //     const isOfferTable = tableHeaderFilterItemsInitial.map(item => {
    //         if (item.name ===  "Termin ważności" && params.type === "orders") {
    //             item.isChecked = false
    //         }
    //         if (item.name ===  "Termin ważności" && params.type === "offers") {
    //             item.isChecked = true
    //         }
    //         return item
    //     })
    //     setTableHeaderFilterItems(isOfferTable)
    //     props.getFilteredTableHeaderItems(isOfferTable)
    // }, [tableHeaderFilterItems, params])


    return (
        <>
            {openFilter && <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                                style={{
                                    display: "flex",
                                    zIndex: 105,
                                    position: "fixed",
                                    inset: "0px 0px auto auto",
                                    margin: 0,
                                    transform: "translate(-59px, 210px)"
                                }}>
                <div className='px-7 py-5'>
                    <div className='fs-5 text-dark fw-bolder'>Wyświetlaj kolumny</div>
                </div>

                <div className='separator border-gray-200'></div>

                <div className='px-7 py-5'>
                    <div className='mb-10'>
                        <div className='d-flex flex-column'>
                            {tableHeaderFilterItems.map((item: { name: string, isChecked: boolean }) => {
                                if (item.name === 'Status' && params.type === "offers" || item.name === 'Nr. IFS' && params.type === "offers" || item.name === 'Termin realizacji' && params.type === "offers") {
                                    return null
                                } else return (
                                <label key={item.name}
                                       className='form-check form-check-sm form-check-custom form-check-solid mb-5 mb-5'>
                                    <input onChange={e => onChange(item)} className='form-check-input' type='checkbox'
                                           value={item.name} defaultChecked={item.isChecked}
                                           disabled={item.name === 'Status' && params.type === "offers" || item.name === 'Nr. IFS' && params.type === "offers" || item.name === 'Termin realizacji' && params.type === "offers"}/>
                                    <span className='form-check-label'>{item.name}</span>
                                </label>
                            )})}
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
