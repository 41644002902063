/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import React, {Component, FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {Link, Outlet, Route, Routes, useParams} from "react-router-dom";
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {IAdminDetails} from "../adminList/AdminAddModel";

import Modal from "@mui/material/Modal";
import {ThemeProvider} from "@mui/material";
import {style, theme} from "../adminList/Admin";
import Box from "@mui/material/Box";
// import {ComponentCreateForm} from "./ComponentCreateForm";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllComponents} from "../componentCreate/ComponentsCreateFunctions";
import SubMenu from "../../component/subMenu/SubMenu";
import _ from 'lodash';
import CustomMassiveActions from "../components/CustomMassiveActions";
import {AccessoriesCreateForm} from "./AccessoriesCreateForm";
import {getAllAccessories} from "./AccessoriesFunctions";
import CustomMassiveActionsAccessories from "../components/accessories/CustomMassiveActionsAccessories";
import {Pagination} from "@mantine/core";
import CustomChangePosition, {changePosition} from "../../component/customChangePosition/customChangePosition";

export interface IComponent {
    id: number,
    path: string,
    title: string,
    component_items: {}[]
}

const Accessories: FC = () => {
    const [chosenListItem, setChosenListItem] = useState<IAdminDetails | null>(null)
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })
    const [page, setPage] = useState(1)
    const [chosenComponents, setChosenComponents] = useState([])
    const params = useParams()
    const [component, setComponent] = useState<Partial<IComponent> | null | undefined>(null)
    const componentPath = useParams();
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const {data: accessories} = useQuery([params.type === "gate" ? `gate_accessories` : `accessories`, params.type, page], () => getAllAccessories(accessToken, params.type === "gate" ? `gate_accessories?page=${page}` : `accessories?page=${page}`), {
        keepPreviousData: true
    });

    const {data: allAccessories} = useQuery(["allAccessories", params.type, page, accessories], () => getAllAccessories(accessToken, params.type === "gate" ? "gate_accessories?pagination=false" : "accessories?pagination=false"));

    const grouppedAccesories = _.groupBy(accessories, function (n) {
        if (params.type === "gate") {
            return n.gateAccessoryOrder
        } else return n.accessoryOrder
    });
    const accresoriesPositionDuplicates = _.uniq(_.flatten(_.filter(grouppedAccesories, function (n) {
        return n.length > 1
    })));
    const totalPages = Math.ceil(allAccessories?.length / 30)

    useEffect(() => {
        setIsChecked({
            all: false,
            first: false
        })
    }, [params.type])

    const handleOnChange = (e: any) => {
        if (e.target.checked && e.target.value !== "all") {
            setChosenComponents(prev => [...prev, e.target.value])
        } else if (e.target.checked && e.target.value === "all") {
            setChosenComponents(accessories.map(i => i.id))
        } else if (e.target.checked === false && e.target.value === "all") {
            setChosenComponents([])
        } else {
            setChosenComponents(prev => prev.filter(item => item !== e.target.value))
        }

    }
    const handleChosenListItem = (item: any) => {
        setChosenListItem(item)
    }
    const queryClient = useQueryClient();
    const changePositionMutation = useMutation(changePosition)

    return (
        <>
            <PageTitle>{params.type === "gate" ? "Akcesoria budowy bramy" : "Akcesoria dodatkowe"}</PageTitle>

            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                <CustomMassiveActionsAccessories isChecked={isChecked} component={accessories} open={open}
                                                 setOpen={setOpen}
                                                 chosenComponent={chosenComponents}
                                                 setChosenComponents={setChosenComponents}
                                                 setChosenListItem={setChosenListItem}/>
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            {accessories && accessories?.length > 0 ? <tr className='fw-bolder text-muted'>
                                    <th>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                checked={chosenComponents?.length > 0}
                                                onChange={handleOnChange}
                                                value="all"
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">Pozycja</th>
                                    <th className="text-center">Zdjęcie</th>
                                    <th className="text-center">Nazwa</th>
                                    <th className="text-center">Kategoria</th>
                                    {params.type === "gate" && <th className="text-center">Rodzaje bram</th>}
                                    {params.type === "gate" && <th className="text-center">Napęd</th>}
                                    {params.type === "gate" && <th className="text-center">Kod KTM</th>}
                                    {params.type === "gate" && <th className="text-center">Opis skrócony</th>}
                                    <th className="text-center">Cena</th>
                                    {params.type === "gate" && <th className="text-center">Min. szerokość</th>}
                                    {params.type === "gate" && <th className="text-center">Max. szerokość</th>}
                                    {params.type === "gate" && <th className="text-center">Max. ilość</th>}
                                    <th className="text-center">Akcje</th>
                                </tr> :
                                <p>Nie znaleziono elementów...</p>}
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {accessories?.map(item => {
                                    return (<tr>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    onChange={handleOnChange}
                                                    checked={chosenComponents?.includes(item.id)}
                                                    className='form-check-input widget-9-check'
                                                    type='checkbox'
                                                    value={item.id}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <CustomChangePosition
                                                    orderKey={params.type === "gate" ? "gateAccessoryOrder" : "accessoryOrder"}
                                                    url={params.type === "gate" ? `gate_accessories` : `accessories`}
                                                    allItems={allAccessories} item={item}
                                                    accessToken={accessToken}
                                                    isDuplicated={accresoriesPositionDuplicates.filter(e => e.id === item.id).length}
                                                    queryKey={params.type === "gate" ? `gate_accessories` : `accessories`}/>
                                            </div>

                                        </td>
                                        <td className="text-center">
                                            <div className="symbol symbol-45px">
                                                {item.image?.contentUrl ? <img
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        maxWidth: 100
                                                    }}
                                                    src={`${process.env.REACT_APP_API_URL}` + item.image?.contentUrl}
                                                    alt="item foto"
                                                /> : "-"}
                                            </div>
                                        </td>
                                        <td className="text-center">{item.name ? item.name : "-"}</td>
                                        <td className="text-center">{item.category ? item.category.name : "-"}</td>

                                        {params.type === "gate" && <td>
                                            <div
                                                className="d-flex flex-column gap-5 flex-wrap">{item.gateType ? item.gateType.map(gate => {
                                                return <div key={gate.id}
                                                            className='badge badge-light-primary fs-7 fw-bold'>{gate.name}</div>

                                            }) : "-"} </div>
                                        </td>}
                                        {params.type === "gate" && <td>
                                            <div
                                                className="d-flex flex-column gap-5 flex-wrap">{item.driveGroups ? item.driveGroups.map(gate => {
                                                return <div key={gate.id}
                                                            className='badge badge-light-primary fs-7 fw-bold'>{gate.name}</div>

                                            }) : "-"} </div>
                                        </td>}
                                        {params.type === "gate" &&
                                            <td className="text-center">{item.ktm ? item.ktm : "-"}</td>}
                                        {params.type === "gate" &&
                                            <td className="text-center">{item.ktmDescription ? item.ktmDescription : "-"}</td>}
                                        <td className="text-center">{item.price ? item.price / 100 : "-"}</td>
                                        {params.type === "gate" &&
                                            <td className="text-center">{item.minWidth ? item.minWidth : "-"}</td>}
                                        {params.type === "gate" &&
                                            <td className="text-center">{item.maxWidth ? item.maxWidth : "-"}</td>}
                                        {params.type === "gate" &&
                                            <td className="text-center">{item.maxQuantity ? item.maxQuantity : "-"}</td>}
                                        <td className="text-center">
                                            <SubMenu item={item} setChosenListItem={setChosenListItem}
                                                     chosenListItem={chosenListItem}
                                                     modalOpen={open} setOpen={setOpen}
                                                     url={params.type === "gate" ? "gate_accessories" : "accessories"}
                                            />
                                        </td>
                                    </tr>)
                                }
                            )}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    <Pagination total={totalPages} page={page} onChange={setPage} style={{justifyContent: "flex-end"}}/>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={theme}>
                        <Box sx={style}>
                            <div>
                                <div>
                                    <div className='modal-header pb-0 border-0 justify-content-end'>
                                        <div
                                            onClick={() => {
                                                setChosenListItem(null)
                                                setOpen(!open)
                                            }}
                                            className='btn btn-sm btn-icon btn-active-color-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className='svg-icon-1'/>
                                        </div>
                                    </div>

                                    <div className='modal-body scroll-y'>
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} element w
                                                "{params.type === "gate" ? 'Akcesoria budowy bramy' : 'Akcesoria dodatkowe'}"</h1>
                                        </div>
                                        <AccessoriesCreateForm
                                            accessories={accessories}
                                            allAccessories={allAccessories}
                                            component={component}
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open} setOpen={setOpen}/>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </ThemeProvider>
                </Modal>
            </div>
        </>

    );
};

export {Accessories}
