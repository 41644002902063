import React, {useEffect, useState} from 'react'
import {IStructureKTMDetails, structureKTMInitValues as initialValues} from './StructureKTMAddModel';
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import CustomSelect from "../../component/customSelect/CustomSelect";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {getAllComponents} from "../componentCreate/ComponentsCreateFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {createKTM, editKTM} from "./StructureKtmFunctions";
import {getAllAccessories} from "../accessories/AccessoriesFunctions";

const structureKTMSchema = Yup.object().shape({
    symbol: Yup.string().required('Pole "Symbol" jest wymagane').nullable(),
    description: Yup.string().required('Pole "Opis" jest wymagane').nullable(),
    shortDescription: Yup.string().required('Pole "Opis skórcony" jest wymagane').nullable(),
})
const typesOptions = [
    {value: 1, label: "Rodzaj bram"},
    {value: 2, label: "Szerokość"},
    {value: 3, label: "Wysokość"},
    {value: 4, label: "Nadproże"},
    {value: 5, label: "Rodzaje napędów"},
    {value: 6, label: "Prowadzenie"}
];
const StructureKTMCreateForm: React.FC<{ chosenListItem: IStructureKTMDetails }> = (props) => {
    const {chosenListItem, setOpen, ktmStructureSorted} = props;
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [initValues, setInitValues] = useState<IStructureKTMDetails>(initialValues);
    const [error, setError] = useState(null)

    useEffect(() => {
        if (chosenListItem) {
            setInitValues({
                ...chosenListItem,
                component: chosenListItem?.component?.id
            })
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const createKTMMutation = useMutation(createKTM, {
        onSuccess: () => {
            client.invalidateQueries('ktm')
        },
        onError: error => {
            setTimeout(() => {
                setError(null)
                createKTMMutation.reset()
            }, 2000)
        }
    })

    const editKTMMutation = useMutation(editKTM, {
        onSuccess: () => {
            client.invalidateQueries('ktm')
        }
    })

    const formik = useFormik<IStructureKTMDetails>({
        enableReinitialize: true,
        initialValues: initValues,
        onSubmit: (values) => {
            setError(null)
            const options = {
                onSuccess: () => {
                    setTimeout(() => {
                        setOpen(prev => !prev)
                    }, 2000)
                }
            }

            if (chosenListItem) {
                editKTMMutation.mutate({
                    accessToken,
                    data: {
                        component: values.component,
                        type: values.type,
                        descriptionLength: values.descriptionLength,
                        length: values.length,
                        position: values.position,
                        descriptionPosition: values.descriptionPosition,
                        gateAccessoryCategory: values?.gateAccessoryCategory?.id
                    },
                    ktmId: chosenListItem.id
                }, options)
            } else {
                if (ktmStructureSorted.some(ktm => ktm.position === values.position)) {
                    setError("Dana pozycja KTM już istnieje!")
                    return;
                } else if (ktmStructureSorted.some(ktm => ktm.descriptionPosition === values.descriptionPosition)) {
                    setError("Dana pozycja w opisie już istnieje!")
                    return;
                } else {
                    createKTMMutation.mutate({
                        accessToken,
                        data: {
                            component: values.component,
                            type: values.type,
                            descriptionLength: values.descriptionLength,
                            length: values.length,
                            position: values.position,
                            descriptionPosition: values.descriptionPosition,
                            gateAccessoryCategory: values.gateAccessoryCategory
                        }
                    }, options)
                }
            }
        },
    })

    const {data: components} = useQuery("components", () => getAllComponents(accessToken), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        placeholderData: []
    });

    const {data: accessoriesCategories} = useQuery("accessories-categories", () => getAllAccessories(accessToken, "accessory_categories"), {
        keepPreviousData: true,
        placeholderData: []
    });


    const optionsComponents: [] = components?.map((group: { id: string, title: string }) => {
        return {value: group.id, label: group.name}
    })

    const optionsGateAccessoriesCategories: [] = accessoriesCategories?.map((acc: { id: string, title: string }) => {
        return {value: acc.id, label: acc.name}
    })


    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='card-body border-top p-9'>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                {(chosenListItem === null || chosenListItem?.component) &&
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label
                                            className='col-lg-6 col-form-label required fw-bold fs-6'>Komponent</label>

                                        <div className='col-lg-12 fv-row'>
                                            <Field
                                                className="custom-select"
                                                name="component"
                                                options={optionsComponents}
                                                component={CustomSelect}
                                                placeholder="Wybierz komponent..."
                                                isMulti={false}
                                            />
                                        </div>
                                        {formik.touched.ktmSymbol && formik.errors.ktmSymbol && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ktmSymbol}</div>
                                            </div>
                                        )}
                                    </div>}
                                {(chosenListItem === null || chosenListItem?.type) &&
                                    <div className='d-flex flex-column col-12 col-sm-6'>
                                        <label className='col-lg-6 col-form-label required fw-bold fs-6'>Inne</label>

                                        <div className='col-lg-12 fv-row'>
                                            <Field
                                                className="custom-select"
                                                name="type"
                                                options={typesOptions}
                                                component={CustomSelect}
                                                placeholder="Wybierz komponent..."
                                                isMulti={false}
                                            />
                                        </div>
                                        {formik.touched.ktmSymbol && formik.errors.ktmSymbol && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.ktmSymbol}</div>
                                            </div>
                                        )}
                                    </div>}
                                {<div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Akcesoria budowy
                                        bramy</label>

                                    <div className='col-lg-12 fv-row'>
                                        <Field
                                            className="custom-select"
                                            name="gateAccessoryCategory"
                                            options={optionsGateAccessoriesCategories}
                                            component={CustomSelect}
                                            placeholder="Wybierz kategorię..."
                                            isMulti={false}
                                        />
                                    </div>
                                    {formik.touched.ktmSymbol && formik.errors.ktmSymbol && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.ktmSymbol}</div>
                                        </div>
                                    )}
                                </div>}
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Pozycja w
                                        kodzie</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wpisz pozycję...'
                                            {...formik.getFieldProps('position')}
                                        />
                                    </div>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.description}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Długość w
                                        KTM</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wpisz dlugość...'
                                            {...formik.getFieldProps('length')}
                                        />
                                    </div>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.description}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-6 d-flex flex-sm-row flex-column'>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Pozycja w
                                        opisie</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wpisz pozycję...'
                                            {...formik.getFieldProps('descriptionPosition')}
                                        />
                                    </div>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.description}</div>
                                        </div>
                                    )}
                                </div>
                                <div className='d-flex flex-column col-12 col-sm-6'>
                                    <label className='col-lg-6 col-form-label required fw-bold fs-6'>Długość w
                                        opisie</label>

                                    <div className='col-lg-12 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Wpisz dlugość...'
                                            {...formik.getFieldProps('descriptionLength')}
                                        />
                                    </div>
                                    {formik.touched.description && formik.errors.description && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.description}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            {(createKTMMutation.isIdle && editKTMMutation.isIdle) &&
                                <button type='submit' className='btn btn-primary'>
                                    {chosenListItem ? 'Zapisz' : 'Dodaj'}
                                </button>}
                        </div>
                    </form>
                </FormikProvider>
                {(editKTMMutation.isLoading || createKTMMutation.isLoading) && (
                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </div>

                )}
                {(editKTMMutation.isSuccess || createKTMMutation.isSuccess) &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {(editKTMMutation.isError || createKTMMutation.isError) &&
                    <p className="text-center fw-bold" style={{color: "red", fontSize: 16}}>Istenieje już struktura dla
                        danego komponentu!</p>}
                {error &&
                    <p className="text-center fw-bold" style={{color: "red", fontSize: 16}}>{error}</p>}
            </div>
        </div>
    )
}

export {StructureKTMCreateForm}
