export interface IACategories {
    id: string | null,
    name: string,
}


export const aCategoriesInitValues: IACategories = {
    id: null,
    name: "",
}

