export const getAllComponents = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/components`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const findComponentById = async (accessToken, id) => {
    if (id !== "new") {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/components/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })

        return response.json()
    }
}
export const findComponentItemsById = async (accessToken, id, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/component_items?component=${id}&page=${page}&itemsPerPage=30`, {
        method: "GET",
        headers: {
            'Accept': 'application/ld+json',
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    const data =await  response.json()

    return {
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems']
    }
}

export const createComponent = async ({accessToken, data}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/components`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const updateComponentById = async ({accessToken, data, id}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/components/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const createComponentItem = async ({accessToken, data}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/component_items`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    return response.json()
}
export const updateComponentItemById = async ({accessToken, data, id}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/component_items/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się zapisać danych!")
    }
}

export const createComponentItemImg = async ({accessToken, data, render}) => {
    if (data) {

        const formdata = new FormData()

        formdata.append('file', data)

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/attachments`, {
                method: "POST",
                body: formdata,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            if (!response.ok) {
                return;
            }

            return response.json()

        } catch (e) {
            console.log(e)
        }

    }
    if (render) {
        const formdata = new FormData()
        formdata.append('file', render)

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/attachments`, {
                method: "POST",
                body: formdata,
                headers: {
                    "Authorization": `Bearer ${accessToken}`
                }
            })
            if (!response.ok) {
                return;
            }
            return response.json()

        } catch (e) {
            console.log(e)
        }
    }
}
export const createComponentItemRender = async ({accessToken, renderFiles, colorId}) => {
    for (const file of renderFiles) {
        const formdata = new FormData()
        formdata.append('file', file[1])

        const response = await fetch(`${process.env.REACT_APP_API_URL}api/attachments`, {
            method: "POST",
            body: formdata,
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
        })
        if (!response.ok) {
            return;
        }

        const attachmentId = (await response.json()).id;
        const componentRenderResponse = await fetch(`${process.env.REACT_APP_API_URL}api/component_item_renders`, {
            method: "POST",
            body: JSON.stringify({
                name: file[0],
                render: attachmentId,
                colorComponent: colorId,
                structureComponent: file[0].split("_")[1],
                stampingComponent: file[0].split("_")[0]
            }),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    }
}
export const deleteRenderById = async ({accessToken, id}: { accessToken: string, id: string }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/component_item_renders/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się usunąć komponentu!")
    }
    return response.json()
};
