import React, {useEffect, useState} from 'react'
import {IAccessGroupDetails, accessGroupsInitValues as initialValues,} from './AccessGroupAddModel'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {UserModel} from "../../modules/auth/models/UserModel";
import {fetchAllAdminGroups} from "./AccessGroupFunctions";

const accessGroupSchema = Yup.object().shape({
    title: Yup.string().required('Pole "nazwa" jest wymagane').nullable(),
})

const AccessGroupForm: React.FC<{ chosenListItem: IAccessGroupDetails | null }> = (props) => {
    const {setChosenListItem, chosenListItem, modalOpen, setOpen} = props;
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [data, setData] = useState<IAccessGroupDetails>({});
    const [initValues, setInitValues] = useState<{}>(initialValues);
    const [showSuccess, setShowSuccess] = useState<boolean | string>(false);
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const {data: adminGroups, isLoading, isFetching} = useQuery(["groups"], () => fetchAllAdminGroups(accessToken, ""))
    const userGroup = adminGroups?.find(e => e.id === user.userGroupId)

    useEffect(() => {
        if (chosenListItem) {
            setInitValues(chosenListItem)
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])


    const createNewAdminGroup = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/groups`, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    }

    const updateAdminGroupById = async (adminGroupId: string) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/groups/${adminGroupId}`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    }

    const adminGroupCreateMutation = useMutation(createNewAdminGroup, {
        onSuccess: () => {
            client.invalidateQueries('groups')
            setShowSuccess("Zapisano")
        }
    })

    const adminGroupEditMutation = useMutation(updateAdminGroupById, {
        onSuccess: () => {
            client.invalidateQueries('groups')
            setShowSuccess("Zapisano")
        }
    })

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IAccessGroupDetails>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: accessGroupSchema,
        onSubmit: (values) => {
            setLoading(true)
            setData({
                ...values,
                isGroupsAccess: chosenListItem?.isGroupsAccess,
                isAdminAccess: chosenListItem?.isAdminAccess
            })
            if (chosenListItem && chosenListItem.id) {
                adminGroupEditMutation.mutate(chosenListItem.id)
            } else {
                adminGroupCreateMutation.mutate()
            }
            setTimeout(() => {
                setOpen(!modalOpen)
                setChosenListItem(null)
                setInitValues(initialValues)
            }, 2000)
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div id='access_group_form' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6 d-flex flex-column'>
                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Nazwa grupy</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź nazwę grupy'
                                        {...formik.getFieldProps('title')}
                                    />
                                    {formik.touched.title && formik.errors.title && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.title}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>Dostęp</label>

                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isOrderAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isOrderAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Lista ofert
                                    </label>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isOfferAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isOfferAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Lista zamówień
                                    </label>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isClientAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isClientAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Lista kontrahentów
                                    </label>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isOrderPreviewPDFAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isOrderPreviewPDFAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Podgląd zamówienia PDF
                                    </label>
                                </div>
                                <br/>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isComponentAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isComponentAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Komponenty
                                    </label>
                                </div>
                                <br/>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isCartAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isCartAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Lista porzuconych koszyków
                                    </label>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isContentAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isContentAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Lista treści stałych
                                    </label>
                                </div>
                                <div className='col-lg-12 fv-row mb-5'>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={chosenListItem?.isStatsAccess}
                                        id="flexCheckDefault"
                                        {...formik.getFieldProps('isStatsAccess')}/>
                                    <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                        Statystyki
                                    </label>
                                </div>
                                <br/>
                                {<>
                                    {chosenListItem?.isAdminAccess && <div className='col-lg-12 fv-row mb-5'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultChecked={true}
                                            disabled
                                            id="flexCheckDefault"
                                            {...formik.getFieldProps('isAdminAccess')}/>
                                        <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                            Lista kont adminów
                                        </label>
                                    </div>}
                                    {chosenListItem?.isGroupsAccess && <div className='col-lg-12 fv-row mb-5'>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultChecked={true}
                                            disabled
                                            id="flexCheckDefault"
                                            {...formik.getFieldProps('isGroupsAccess')}/>
                                        <label className="form-check-label mx-5" htmlFor="flexCheckDefault">
                                            Grupy dostępów
                                        </label>
                                    </div>}
                                </>}
                            </div>
                        </div>

                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && !chosenListItem && 'Dodaj'}
                            {!loading && chosenListItem && 'Zapisz'}
                            {loading && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                  Dodawanie...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                            )}
                        </button>
                    </div>
                </form>
                {showSuccess &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>{showSuccess}!</p>}
            </div>
        </div>
    )
}

export {AccessGroupForm}
