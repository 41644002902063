/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {PageTitle} from '../../../../_metronic/layout/core';

import GateCategoryList from "./GateCategoryList";

const GateCategory = () => {

    return (
        <>
            <PageTitle>Rodzaje bram</PageTitle>
            <GateCategoryList/>
        </>
    )
}

export {GateCategory}
