/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {Admin} from "./Admin";


const AdminListWrapper: FC = () => {
    return (
        <>
            <PageTitle>Lista kont adminów</PageTitle>
            <Admin className='card-xxl-stretch mb-5 mb-xl-8'/>
        </>
    )
}

export {AdminListWrapper}
