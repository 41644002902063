import React, {FC} from 'react';
import SubMenu from "../../component/subMenu/SubMenu";


const AdminTable: FC<{adminList: [], }> = (props) => {
    const {adminList,setChosenListItem,chosenListItem,open,setOpen,url} = props;
    return (
        <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                <tr className='fw-bolder text-muted'>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>Email</th>
                    {/*<th>Telefon</th>*/}
                    <th>Grupa</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {adminList && adminList?.length > 0 && adminList?.map(admin => (
                    <tr key={admin.id}>
                        <td>{admin.name}</td>
                        <td>{admin.surname}</td>
                        <td>{admin.email}</td>
                        {/*<td>{admin.phone}</td>*/}
                        <td>{admin.group ? <span className='badge badge-light-primary fs-7 fw-bold'>{admin.group.title}</span> : "-"}</td>
                        <td>
                            <SubMenu item={admin} setChosenListItem={setChosenListItem} chosenListItem={chosenListItem}
                                     modalOpen={open} setOpen={setOpen} url="admins"/>
                        </td>
                    </tr>
                ))}

                </tbody>
                {/* end::Table body */}
            </table>
            {/* end::Table */}
        </div>
    );
};

export default AdminTable;