import React, {useState, FC} from 'react';
import {KTSVG} from "../../../_metronic/helpers";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {useMutation, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {blockItem, changeClientToDistributor} from "../../pages/clientList/ClientsFunctions";
import {Alert} from "@mui/material";
import {Loader} from "@mantine/core";

export const deleteModalStyle = {
    position: "fixed",
    bottom: 200,
    left: "50%",
    top: "50%",
    padding: 20,
    display: "flex",
    flexDirection: "column",
    fontWeight: 500,
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    gap: 40,
    alignItems: "center",
    border: "1px solid #EFF2F5",
    borderRadius: "0.475rem",
    boxShadow: " 8px 8px 24px -12px rgba(187, 187, 187, 1)",
    transform: "translate(-35%,-25%)",
    minWidth: 200
}

const SubMenu: FC<{
    item: {},
    setChosenListItem: () => void,
    chosenListItem: {},
    setOpen: () => void,
    modalOpen: boolean,
    url: string
}> = (props) => {
    const {item, setChosenListItem, chosenListItem, setOpen, modalOpen, url, isClient} = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const queryClient = useQueryClient();
    const [openDelete, setOpenDelete] = useState(false)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setChosenListItem(item)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteAdminById = async (type: string, adminId: string) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/${type}/${adminId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    };

    const deleteAdminMutation = useMutation(() => deleteAdminById(url, item.id), {
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries();
            }, 2000)
        }
    })
    const [isSetDistributorError, setIsSetDistributorError] = useState(false)
    const changeClientToDistributorMutation = useMutation(changeClientToDistributor, {
        onSuccess: () => {
            queryClient.invalidateQueries(["clients"])
        }
    })
    const blockListItem = useMutation(blockItem, {
        onSuccess: () => {
            if (url === 'component_items') {
                queryClient.invalidateQueries(["componentsItems"])
            } else {
                queryClient.invalidateQueries(["gate_types_paginated"])
            }

        }
    })
    return (
        <div>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <KTSVG path='/media/icons/duotune/general/gen024.svg'
                       className='svg-icon-2'/>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    setOpen(!modalOpen)
                    handleClose()
                }}>
                    <button
                        type="button"
                        className='btn menu-link text-center w-100'>
                        Edycja
                    </button>
                </MenuItem>
                {isClient && <MenuItem>
                    <div className={'w-100'}>
                        <button
                            onClick={() => changeClientToDistributorMutation.mutate({
                                data: {
                                    isDistributor: item?.isDistributor ? false : true
                                },
                                accessToken,
                                clientId: item.id
                            }, {
                                onSuccess: (r) => {
                                    if (!r.id) {
                                        setIsSetDistributorError(true)
                                        setTimeout(() => {
                                            setIsSetDistributorError(false)
                                        },4000)
                                    }

                                }
                            })}
                            type="button"
                            className='btn btn-success btn-sm px-3 w-100'>
                            {item?.isDistributor ? "Klient" : "Dystrybutor"}
                        </button>
                        {isSetDistributorError && <p style={{color: '#dc3545', fontWeight: 700, fontSize: 10}} className={'mt-2 mb-0 text-center'}>
                            Istnieje już <br/> dystrybutor <br/>o podanym emailu!
                        </p>}
                    </div>
                </MenuItem>}
                {(url === 'component_items' || url ==='gate_types') && <MenuItem
                    onClick={() => {
                        blockListItem.mutate({
                            data: {
                                isHidden: !chosenListItem.isHidden
                            },
                            accessToken,
                            id: chosenListItem.id,
                            url,
                        }, {
                            onSuccess: () => {
                                handleClose()
                            }
                        })
                    }}>
                    <button
                        type="button"
                        className={`btn ${chosenListItem?.isHidden ? 'btn-success':'btn-danger'} btn-sm px-3 w-100`}>
                        {blockListItem.isLoading ? <Loader size={'xs'} color={'white'}/>:chosenListItem?.isHidden ? 'Odblokuj' : 'Zablokuj'}
                    </button>
                </MenuItem>}
                <MenuItem
                    onClick={() => {
                        handleClose()
                        setOpenDelete(true)
                    }}>
                    <button
                        type="button"
                        className='btn btn-danger btn-sm px-3 w-100'>
                        Usuń
                    </button>
                </MenuItem>
            </Menu>

            {openDelete && <div style={deleteModalStyle}>
                {!deleteAdminMutation.isSuccess && <>
                    <p style={{fontSize: 20}}>Czy na pewno chcesz usunąć wybrany element?</p>
                    <div className="d-flex justify-content-between w-100">
                        <button
                            onClick={() => {
                                deleteAdminMutation.mutate((url, item.id), {
                                    onSuccess: () => {
                                        setChosenListItem(null)
                                        setTimeout(() => {
                                            deleteAdminMutation.reset()
                                            setOpenDelete(false)
                                        }, 2000)
                                        handleClose()
                                    }
                                })
                            }}
                            className='btn btn-danger'>Tak, usuń
                        </button>
                        <button
                            onClick={() => setOpenDelete(false)}
                            className='btn btn-primary'>Nie, zamknij
                        </button>
                    </div>
                </>}
                {deleteAdminMutation.isSuccess &&
                    <p style={{fontSize: 20, color: "green"}}>Element został usunięty!</p>}
            </div>}
        </div>
    );
};

export default SubMenu;
