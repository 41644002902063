export interface IContentDetails {
    clientNumber: number | null,
    internalNumber: string | null,
    name: string | null,
    surname: string | null,
    company: string | null,
    vatNumber: string | null,
    street: string | null,
    zipCode: string | null,
    city: string | null,
    phone: number | null,
    email: string | null,
}


export const contentInitValues: IContentDetails = {
    clientNumber: null,
    internalNumber: null,
    name: null,
    surname: null,
    company: null,
    vatNumber: null,
    street: null,
    zipCode: null,
    city: null,
    phone: null,
    email: null,
}

