/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {BasketTable} from "./BasketTable";


const BasketListPage:  FC<{searchValue: string}> = ({searchValue}) => (
    <>
        <BasketTable className='card-xxl-stretch mb-5 mb-xl-8' searchValue={searchValue}/>
    </>
)

const BasketListWrapper: FC<{searchValue: string}> = ({searchValue}) => {

    return (
        <>
            <PageTitle>Lista porzuconych koszyków</PageTitle>
            <BasketListPage searchValue={searchValue}/>
        </>
    )
}

export {BasketListWrapper}
