import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import AuthInit from './modules/auth/redux/AuthInit'
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../setup";
import {actions} from "./modules/auth";
import {refreshToken} from "./modules/auth/redux/AuthCRUD";


const fetchCopy = window.fetch

const App = () => {
  const dispatch = useDispatch()

  const refresh_token = useSelector<RootState>(store => store.auth.refreshToken)

  useEffect(() => {
    const customFetch = (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
      return new Promise(async resolve => {
        const response =  await fetchCopy(input, init)
        if(response.status === 401) {
          if(refresh_token) {
            const loginResponse: any = await refreshToken(refresh_token as any)

            if(loginResponse && loginResponse.status === 200) {
              const r =  await fetchCopy(input, {
                ...init,
                headers: {
                  ...init.headers,
                  "Authorization": `Bearer ${loginResponse.data.token}`
                }
              })

              if(r.ok) {
                dispatch(actions.login(loginResponse.data.token, loginResponse.data.refresh_token))
                 resolve(r)

              } else {
                dispatch(actions.logout())
              }
            } else {
              dispatch(actions.logout())
            }
          } else {
           dispatch(actions.logout())
          }

        } else {
          return resolve(response)
        }
      })
    }

    ;(window as any).fetch = customFetch


    return () => {
      (window as any).fetch = fetchCopy
    }
  }, [])


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
