import React, {FC, useMemo} from 'react';
import SubMenu from "../../component/subMenu/SubMenu";
import {CircularProgress} from "@mui/material";


const Clients: FC<{ handleChosenListItem: () => void }> = (props) => {
    const {
        item,
        setChosenListItem,
        chosenListItem,
        setOpen,
        modalOpen,
        url,
        isClient,
        clientData,
        isLoading,
        isError,
        filteredClientData,
        noVerifiedClients,
        page,
        searchValue
    } = props;

    const clients = useMemo(() =>{
        const filtered = searchValue ? filteredClientData : filteredClientData.filter(e => !noVerifiedClients.some(c => c.id === e.id));
        const noVeryfied = searchValue || page > 1 ? [] : noVerifiedClients;
        return [...noVeryfied, ...filtered]
    }, [noVerifiedClients, filteredClientData])

    return (
        <>
            {/* begin::Table container */}
            {isError || filteredClientData?.length === 0 && !isLoading &&
                <p className="mt-5">Brak elementów do wyświetlenia</p>}
            {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
            {filteredClientData && !isLoading && filteredClientData?.length > 0 && <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                    <tr className='fw-bolder text-muted'>
                        <th>Numer wewnętrzny IFS</th>
                        <th>Firma</th>
                        <th>NIP</th>
                        <th>Imię</th>
                        <th>Nazwisko</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Nr. tel.</th>
                        <th>Dystrybutor</th>
                        <th>Ostatnia akcja</th>
                        <th>Akcje</th>
                    </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                    {!isLoading && clients?.map(listItem => {
                        return (
                            <tr key={listItem?.id}>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.internalNumber || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.company || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.vatNumber || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.name || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.surname || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.email || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{!listItem?.isActive && !listItem?.isVerified ? "Niezweryfikowany" : listItem.isActive ? "Aktywny" : "Dezaktywowany"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.phone || "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.distributor ?
                                    <a href="#">{listItem?.distributor?.company}</a> : "-"}</td>
                                <td style={!listItem?.isActive && !listItem?.isVerified ? {fontWeight: 'bold'} : null}>{listItem?.lastActiveAt ? new Date(listItem?.lastActiveAt)?.toLocaleDateString() : "-"}</td>
                                <td>
                                    <SubMenu item={listItem} setChosenListItem={setChosenListItem}
                                             chosenListItem={chosenListItem} modalOpen={modalOpen} setOpen={setOpen}
                                             url="clients" isClient={true}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}
            </div>}
            {/* end::Table container */}
        </>
    );
};

export default Clients;
