export const getPrices = async (accessToken, url) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Authorization": `Bearer ${accessToken}`
        },
    })
    if (!response.ok) {
        throw new Error("Wystąpił błąd!")
    }
    const data = await response.json()

    return data;
}
export const getTotalPrices = async (accessToken, gateType) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/pricings?itemsPerPage=0&gateType=${gateType}`, {
        method: "GET",
        headers: {
            "Accept": "application/ld+json",
            "Authorization": `Bearer ${accessToken}`
        },
    })
    if (!response.ok) {
        throw new Error("Wystąpił błąd!")
    }
    const data = await response.json()

    return data['hydra:totalItems'];
}

export const updatePrice = async ({accessToken, data}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/pricings`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const updatePriceById = async ({accessToken, data, id}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/pricings/${id}/price`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}
export const updateRangeById = async ({accessToken, items,newMinWidthValue,newMaxWidthValue,newMinHeightValue,newMaxHeightValue }) => {
    await Promise.all(items.map(item => fetch(`${process.env.REACT_APP_API_URL}api/pricings/${item.id}/dimensions`, {
        method: "PATCH",
        body: JSON.stringify({
            minWidth: newMinWidthValue ? newMinWidthValue : item.minWidth.id,
            maxWidth: newMaxWidthValue ? newMaxWidthValue : item.maxWidth.id,
            minHeight:  newMinHeightValue ? newMinHeightValue : item.minHeight.id,
            maxHeight:  newMaxHeightValue ? newMaxHeightValue : item.minHeight.id,
        }),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })))
}

export const deletePriceById = async ({accessToken, ids}) => {
    await Promise.all(ids.map(id => fetch(`${process.env.REACT_APP_API_URL}api/pricings/${id}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    })))
}
