import React, {FC, useEffect, useState} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {IDriveDetails} from "./DriveModel";
import {useIsMutating, useQuery, useQueryClient} from "react-query";
import {getAllDrivesGroups, getPaginatedDrivesGroups} from "./DriveFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {style, theme} from "../../adminList/Admin";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {DriveCreateForm} from "./DriveCreateForm";
import SubMenu from "../../../component/subMenu/SubMenu";
import CustomMassiveActions from "./CustomMassiveActions";
import CustomChangePosition from "../../../component/customChangePosition/customChangePosition";
import {Pagination} from "@mantine/core";


const Drive: FC = () => {
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = useState(1)
    const handleOpen = () => setOpen(true);
    const isMutating = !!useIsMutating({
        predicate: ({meta}) =>
            !!meta?.isEditDriveMutation || !!meta?.isEditDriveGroupMutation
    })

    const handleClose = () => {
        setTimeout(() => {
            setChosenListItem(null)
            setOpen(false)
        }, 500)
    };
    const queryClient = useQueryClient();
    const [chosenComponents, setChosenComponents] = useState([])
    const [chosenListItem, setChosenListItem] = useState<IDriveDetails | null>(null);
    const {data: allDrivesGroups} = useQuery('drive_groups', () => getAllDrivesGroups(accessToken), {
        refetchOnWindowFocus: false,
    });
    const {
        data: drivesGroups,
        isLoading
    } = useQuery(['drive_groups_paginated', page], () => getPaginatedDrivesGroups(accessToken, page), {
        keepPreviousData: false
    });
    const totalPages = Math.ceil(allDrivesGroups?.length / 30)
    const handleOnChange = (e: any) => {
        if (e.target.checked && e.target.value !== "all") {
            setChosenComponents(prev => [...prev, e.target.value])
        } else if (e.target.checked && e.target.value === "all") {
            setChosenComponents(drivesGroups?.map(i => i.id))
        } else if (e.target.checked === false && e.target.value === "all") {
            setChosenComponents([])
        } else {
            setChosenComponents(prev => prev.filter(item => item !== e.target.value))
        }
    }

    useEffect(() => {
        const nextPage = page + 1;

        queryClient.prefetchQuery(
            ["posts", nextPage],
            () => getPaginatedDrivesGroups(accessToken, page))
    }, [page, queryClient])

    return (
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            <CustomMassiveActions isChecked={isChecked} open={open} setOpen={setOpen} chosenComponent={chosenComponents}
                                  setChosenComponents={setChosenComponents}/>
            {/* begin::Body */}
            <div className='card-body py-3'>
                {drivesGroups?.length === 0 && <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {/* begin::Table container */}
                {!isLoading && drivesGroups && drivesGroups.length > 0 && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={chosenComponents.length > 0}
                                        onChange={handleOnChange}
                                        value="all"
                                    />
                                </div>
                            </th>
                            <th>Pozycja</th>
                            <th>Zdjęcie</th>
                            <th>Nazwa napędu</th>
                            <th>Cena</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {drivesGroups && drivesGroups?.map((drive: IDriveDetails) => {
                                return (<tr key={drive.id}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                onChange={handleOnChange}
                                                checked={chosenComponents.includes(drive.id)}
                                                className='form-check-input widget-9-check'
                                                type='checkbox'
                                                value={drive.id}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <CustomChangePosition orderKey={"driveGroupOrder"} url={"drive_groups"}
                                                              allItems={allDrivesGroups} item={drive}
                                                              accessToken={accessToken}
                                                              queryKey={"drive_groups_paginated"}
                                                              queryKey2={"drive_groups"}/>
                                    </td>
                                    <td>
                                        <div className="symbol symbol-45px me-5">
                                            {drive.image ? <img
                                                style={{
                                                    height: 'auto',
                                                    width: '100%',
                                                    maxWidth: 100
                                                }}
                                                src={`${process.env.REACT_APP_API_URL}` + drive.image.contentUrl}
                                                alt="item foto"
                                            /> : "-"}
                                        </div>
                                    </td>
                                    <td>{drive.name}</td>
                                    <td>{drive?.price / 100 || "-"}</td>
                                    <td>
                                        <SubMenu
                                            item={drive}
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open}
                                            setOpen={setOpen}
                                            url={"drive_groups"}/>
                                    </td>
                                </tr>)
                            }
                        )}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>}
                {/* end::Table container */}
                {totalPages > 1 && <Pagination total={totalPages} page={page} onChange={setPage}
                                               style={{justifyContent: "flex-end"}}/>}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={isMutating ? undefined : handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => {
                                            setChosenListItem(null)
                                            setOpen(!open)
                                        }}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <DriveCreateForm
                                        drivesGroups={drivesGroups}
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    );
};

export {Drive};
