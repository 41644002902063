export const getAllKTM = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/ktm_structures`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Coś poszło nie tak! Spróbuj ponownie!")
    }

    return response.json()
}

export const createKTM = async ({accessToken, data }) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/ktm_structures`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Istenieje już struktura dla danego komponentu!")
    }

    return response.json()
}
export const editKTM = async ({
                                  accessToken,
                                  data,
                                  ktmId
                              }: { accessToken: string, data: any, gateTypeId: string }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/ktm_structures/${ktmId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    return response.json()
}