export interface IAdminDetails {
    id: string | null,
    name: string,
    surname: string,
    email: string,
    roles: string[],
    group: {}
}


export const adminInitValues: IAdminDetails = {
    id: null,
    name: "",
    surname: "",
    email: "",
    roles: []
}

