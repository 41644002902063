/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import React, {Component, FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {Link, Outlet, Route, Routes, useParams, useNavigate} from "react-router-dom";
import {KTSVG, toAbsoluteUrl} from "../../../_metronic/helpers";
import {IAdminDetails} from "../adminList/AdminAddModel";
import CustomMassiveActions from "./CustomMassiveActions";
import Modal from "@mui/material/Modal";
import {ThemeProvider} from "@mui/material";
import {style, theme} from "../adminList/Admin";
import Box from "@mui/material/Box";
import {ComponentCreateForm} from "./ComponentCreateForm";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {findComponentItemsById, getAllComponents} from "../componentCreate/ComponentsCreateFunctions";
import SubMenu, {deleteModalStyle} from "../../component/subMenu/SubMenu";
import _ from 'lodash';
import Button from "@mui/material/Button";
import CustomChangePosition, {changePosition} from "../../component/customChangePosition/customChangePosition";
import {Pagination} from "@mantine/core";

export interface IComponent {
    id: number,
    path: string,
    title: string,
    component_items: {}[]
}

const Components: FC = () => {
    const [chosenListItem, setChosenListItem] = useState<IAdminDetails | null>(null)
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })
    const [deleteComponent, setDeleteComponent] = useState(false)
    const [chosenComponents, setChosenComponents] = useState([])
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [component, setComponent] = useState<Partial<IComponent> | null | undefined>(null)
    const componentPath = useParams();
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };
    const [page, setPage] = useState(1)
    const {data: components, isFetching} = useQuery("components", () => getAllComponents(accessToken), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const {data: componentsItems} = useQuery(["componentsItems", [componentPath.id, page]], () => findComponentItemsById(accessToken, componentPath.id, page), {
        keepPreviousData: true,
        placeholderData: [],
        enabled: !!componentPath.id,
        refetchOnWindowFocus: false,
    });

    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        if (components && components.length > 0) {
            const chosenComponent: IComponent | undefined = components?.find(component => component.id === componentPath.id)
            setComponent(chosenComponent)
            setTotalPages( Math.ceil(component?.componentItems?.length / 30))
        }
    }, [componentPath, component, components])

    useEffect(() => {
        setIsChecked({
            all: false,
            first: false
        })
    }, [component])

    const deleteAdminById = async (type: string, adminId: string) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/${type}/${adminId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
    };

    const deleteAdminMutation = useMutation(() => deleteAdminById("components", component?.id), {
        onSuccess: () => {
            navigate("/component-create/new")
            queryClient.invalidateQueries("components")
        }
    })

    const handleOnChange = (e: any) => {
        if (e.target.checked && e.target.value !== "all") {
            setChosenComponents(prev => [...prev, e.target.value])
        } else if (e.target.checked && e.target.value === "all") {
            setChosenComponents(component?.componentItems.map(i => i.id))
        } else if (e.target.checked === false && e.target.value === "all") {
            setChosenComponents([])
        } else {
            setChosenComponents(prev => prev.filter(item => item !== e.target.value))
        }

    }

    return (
        <>
            <PageTitle>{component?.name}</PageTitle>

            <div className='card card-xxl-stretch mb-5 mb-xl-8'>
                <div className="d-flex justify-content-between p-10">
                    <Link
                        to={`/component-create/${component?.id}`}
                        type="button"
                        className='btn btn-sm btn-primary'
                    >
                        <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                        Edytuj komponent
                    </Link>
                    <Button
                        onClick={() => {
                            // deleteAdminMutation.mutate()
                            setDeleteComponent(true)
                        }}
                        className='btn btn-sm btn-danger'>
                        Usuń komponent
                    </Button>
                </div>
                <CustomMassiveActions isChecked={isChecked} component={component} open={open} setOpen={setOpen}
                                      chosenComponent={chosenComponents} setChosenComponents={setChosenComponents}/>
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    <div className='table-responsive'>
                        {/* begin::Table */}
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            {component && componentsItems?.data?.length > 0 ? <tr className='fw-bolder text-muted'>
                                    <th>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                type='checkbox'
                                                checked={chosenComponents.length > 0}
                                                onChange={handleOnChange}
                                                value="all"
                                            />
                                        </div>
                                    </th>
                                    <th className="text-center">Pozycja</th>
                                    <th className="text-center">Zdjęcie</th>
                                    <th className="text-center">Nazwa</th>
                                    <th className="text-center">Rodzaje bram</th>
                                    <th className="text-center">Kod KTM</th>
                                    <th className="text-center">Opis skrócony</th>
                                    <th className="text-center">Cena</th>
                                    {component.relatedComponents.length > 0 && component.relatedComponents.map(component => {
                                        return <th className="text-center">{component.name}</th>
                                    })}
                                    <th className="text-center">Max. szerokość</th>
                                    <th className="text-center">Max. ilość</th>
                                    <th className="text-center">Data edycji</th>
                                    <th className="text-center">Akcje</th>
                                </tr> :
                                <p>Nie znaleziono elementów...</p>}
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {componentsItems?.data?.map(item => {
                                    const relatedColumnsValues = _.groupBy(item.componentItemsValues, "component.id")
                                    return (<tr style={{background: item?.isHidden ? '#f1c1c1': 'white'}}>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    onChange={handleOnChange}
                                                    checked={chosenComponents.includes(item.id)}
                                                    className='form-check-input widget-9-check'
                                                    type='checkbox'
                                                    value={item.id}/>
                                            </div>
                                        </td>
                                        <td>
                                            <CustomChangePosition item={item} orderKey={"componentOrder"}
                                                                  url={"component_items"} queryKey={"components"} queryKey2={'componentsItems'}
                                                                  accessToken={accessToken}
                                                                  allItems={component?.componentItems}/>
                                        </td>
                                        <td className="text-center">
                                            <div className="symbol symbol-45px">
                                                {item.image?.contentUrl ? <img
                                                    style={{
                                                        height: 'auto',
                                                        width: '100%',
                                                        maxWidth: 100
                                                    }}
                                                    src={`${process.env.REACT_APP_API_URL}` + item.image?.contentUrl}
                                                    alt="item foto"
                                                /> : "-"}
                                            </div>
                                        </td>
                                        <td className="text-center">{item.name ? item.name : "-"}</td>
                                        <td>
                                            <div
                                                className="d-flex flex-column gap-5 flex-wrap">{item.gateTypes ? item.gateTypes.map(gate => {
                                                return <div key={gate.id}
                                                            className='badge badge-light-primary fs-7 fw-bold'>{gate.name}</div>

                                            }) : "-"} </div>
                                        </td>
                                        <td className="text-center">{item.ktm ? item.ktm : "-"}</td>
                                        <td className="text-center">{item.ktmDescription ? item.ktmDescription : "-"}</td>
                                        <td className="text-center">{item.price ? item.price / 100 : "-"}</td>
                                        {component?.relatedComponents?.length > 0 && component?.relatedComponents?.map(comp => {
                                            return (
                                                <td>
                                                    <div className="d-flex flex-column gap-5 flex-wrap">
                                                        {relatedColumnsValues[comp.id]?.map(value => {
                                                            return (<div
                                                                className='badge badge-light-primary fs-7 fw-bold'>{value.name}</div>)
                                                        })}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        <td className="text-center">{item.maxWidth ? item.maxWidth : "-"}</td>
                                        <td className="text-center">{item.maxQuantity ? item.maxQuantity : "-"}</td>
                                        <td className="text-center">{item.updatedAt ? new Date(item.updatedAt)?.toLocaleDateString() : "-"}</td>
                                        <td className="text-center">
                                            {isFetching && (<div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                                            </div>)}
                                            {!isFetching && <SubMenu item={item} setChosenListItem={setChosenListItem}
                                                                     chosenListItem={chosenListItem}
                                                                     modalOpen={open} setOpen={setOpen}
                                                                     url="component_items"
                                            />}
                                        </td>
                                    </tr>)
                                }
                            )}
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        {/* end::Table */}
                    </div>
                    {/* end::Table container */}
                    {totalPages > 1 && <Pagination  total={totalPages} page={page} onChange={setPage} style={{justifyContent: "flex-end"}}/>}
                </div>
                {/* begin::Body */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={theme}>
                        <Box sx={style}>
                            <div>
                                <div>
                                    <div className='modal-header pb-0 border-0 justify-content-end'>
                                        <div
                                            onClick={() => setOpen(!open)}
                                            className='btn btn-sm btn-icon btn-active-color-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className='svg-icon-1'/>
                                        </div>
                                    </div>

                                    <div className='modal-body scroll-y'>
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} element w
                                                komponencie "{component?.name}"</h1>
                                        </div>
                                        <ComponentCreateForm
                                            component={component}
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open} setOpen={setOpen}/>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </ThemeProvider>
                </Modal>

                {deleteComponent && <div style={deleteModalStyle}>
                    {!deleteAdminMutation.isSuccess && <>
                        <p style={{fontSize: 20}}>Czy na pewno chcesz usunąć wybrany komponent?</p>
                        <div className="d-flex justify-content-between w-100">
                            <button
                                onClick={() => {
                                    deleteAdminMutation.mutate(null, {
                                        onSuccess: () => {
                                            setChosenListItem(null)
                                            setTimeout(() => {
                                                deleteAdminMutation.reset()
                                                setDeleteComponent(false)
                                            }, 2000)
                                            handleClose()
                                        }
                                    })
                                }}
                                className='btn btn-danger'>Tak, usuń
                            </button>
                            <button
                                onClick={() => setDeleteComponent(false)}
                                className='btn btn-primary'>Nie, zamknij
                            </button>
                        </div>
                    </>}
                    {deleteAdminMutation.isSuccess &&
                        <p style={{fontSize: 20, color: "green"}}>Element został usunięty!</p>}
                </div>}
            </div>
        </>

    );
};

export {Components}
