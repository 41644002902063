/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {FullScreen, useFullScreenHandle} from "react-full-screen";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {deletePriceById, getPrices, updatePrice, updatePriceById, updateRangeById} from "./PricesFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {
    getAllGateTypes,
    getChosenGateTypesDrives,
    getGateTypeDrives
} from "../components/gateCategory/GateCategoryFunctions";
import {CircularProgress} from "@mui/material";
import {KTSVG, toAbsoluteUrl, useDebounce} from "../../../_metronic/helpers";
import Fade from '@mui/material/Fade';
import {Select} from '@mantine/core';
import Modal from "@mui/material/Modal";
import {deleteModalStyle} from "../../component/subMenu/SubMenu";
import {getPaginatedDrivesGroups} from "../components/drive/DriveFunctions";
import {getAllSizes} from "../components/sizes/SizesFunctions";

const heightCell: {} = {
    transform: "rotate(270deg) translateX(-150%)",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    maxWidth: 50,
    marginBottom: 0
}

const PricesPage: FC = () => {
    const handle = useFullScreenHandle();
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [gateType, setGateType] = useState("nogate");
    const [chosenSize, setChosenSize] = useState(null)
    const [page, setPage] = useState(1);
    const queryClient = useQueryClient();
    const {data: gateTypes, isLoading: gateTypeLoading} = useQuery("gate_types", () => getAllGateTypes(accessToken));
    const [pricingFilteredByGate, setPricingFilteredByGate] = useState([]);
    const [chosenGateType, setChosenGateType] = useState([])
    const [chosenGateTypeWidthOptions, setChosenGateTypeWidthOptions] = useState([])
    const [chosenGateTypeHeightOptions, setChosenGateTypeHeightOptions] = useState([])
    const [price, setPrice] = useState(null)
    const debouncedPrice = useDebounce(price, 1000)
    const [chosenRange, setChosenRange] = useState(undefined)
    const [chosenCol, setChosenCol] = useState(undefined)
    const [chosenRow, setChosenRow] = useState(undefined)
    const [chosenInput, setChosenInput] = useState(undefined)
    const [isEdit, setIsEdit] = useState(undefined)
    const [isHeightEdit, setIsHeightEdit] = useState(undefined)
    const [showTable, setShowTable] = useState(undefined)
    const [newMinWidthValue, setNewMinWidthValue] = useState(undefined)
    const [newMaxWidthValue, setNewMaxWidthValue] = useState(undefined)
    const [newMinHeightValue, setNewMinHeightValue] = useState(undefined)
    const [newMaxHeightValue, setNewMaxHeightValue] = useState(undefined)

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
    };

    const updatePriceMutation = useMutation(updatePriceById, {
        onSuccess: () => {
            queryClient.invalidateQueries("pricing")
            setAddWidthColumn(false)
            setAddHeightColumn(false)
            setPrice(-1)
        }
    })

    const createPriceMutation = useMutation(updatePrice, {
        onSuccess: () => {
            queryClient.invalidateQueries("pricing")
            setAddWidthColumn(false)
            setAddHeightColumn(false)
            setPrice(-1)
        }
    })
    const changeRangeMutation = useMutation(updateRangeById, {
        onSuccess: () => {
            queryClient.invalidateQueries("pricing")
            setIsEdit(false)
            setIsHeightEdit(false)
            setChosenRow(undefined)
            setChosenCol(undefined)
            setNewMinHeightValue(undefined)
            setNewMaxHeightValue(undefined)
            setNewMinWidthValue(undefined)
            setNewMaxWidthValue(undefined)
            changeRangeMutation.reset()
        }
    })

    const deletePriceMutation = useMutation(deletePriceById, {

        onSuccess: () => {
            queryClient.invalidateQueries("pricing")
            setAddWidthColumn(false)
            setAddHeightColumn(false)
            deletePriceMutation.reset()
        }
    })

    useEffect(() => {
        if (+debouncedPrice > 0) {
            if (chosenRange?.id) {
                updatePriceMutation.mutate({
                    accessToken,
                    data: {
                        price: +debouncedPrice * 100
                    },
                    id: chosenRange.id
                })
            } else {
                if (widthColumn[widthColumn.length - 1]?.minWidth &&
                    widthColumn[widthColumn.length - 1]?.maxWidth &&
                    heightColumn[heightColumn.length - 1]?.minHeight &&
                    heightColumn[heightColumn.length - 1]?.maxHeight) {
                    createPriceMutation.mutate({
                        accessToken,
                        data: {
                            price: +debouncedPrice * 100,
                            minWidth: chosenRange.range ?
                                chosenRange.range.widthRange.minWidth.id ? chosenRange.range.widthRange.minWidth.id : chosenRange.range.widthRange.minWidth : widthColumn[widthColumn.length - 1]?.minWidth.id ? widthColumn[widthColumn.length - 1]?.minWidth.id : widthColumn[widthColumn.length - 1]?.minWidth,
                            maxWidth: chosenRange.range ?
                                chosenRange.range.widthRange.maxWidth.id ? chosenRange.range.widthRange.maxWidth.id : chosenRange.range.widthRange.maxWidth :
                                widthColumn[widthColumn.length - 1]?.maxWidth.id ? widthColumn[widthColumn.length - 1]?.maxWidth.id : widthColumn[widthColumn.length - 1]?.maxWidth,
                            minHeight: chosenRange.range ?
                                chosenRange.range.heightRange.minHeight.id ? chosenRange.range.heightRange.minHeight.id : chosenRange.range.heightRange.minHeight :
                                heightColumn[heightColumn.length - 1]?.minHeight.id ? heightColumn[heightColumn.length - 1]?.minHeight.id : heightColumn[heightColumn.length - 1]?.minHeight,
                            maxHeight: chosenRange.range ?
                                chosenRange.range.heightRange.maxHeight.id ? chosenRange.range.heightRange.maxHeight.id : chosenRange.range.heightRange.maxHeight :
                                heightColumn[heightColumn.length - 1]?.maxHeight.id ? heightColumn[heightColumn.length - 1]?.maxHeight.id : heightColumn[heightColumn.length - 1]?.maxHeight,
                            gateType: chosenGateType?.id,
                            driveGroup: chosenRange?.driveGroup
                        }
                    })
                }
            }
        }
    }, [debouncedPrice])

    const {
        data: pricing,
        isLoading
    } = useQuery(["pricing", gateType, chosenGateType], () => getPrices(accessToken, 'pricings' + "?gateType=" + gateType + `&pagination=false`), {
        retry: false,
        enabled: gateType !== "nogate",
        onSuccess: d => {
            if (d.length > 0) {
                // setPage(prevState => prevState + 1)
                setPricingFilteredByGate(prevState => [...d])
                setShowTable(true)
            } else {
                setShowTable(true)
            }
        }
    })

    const {data: gateTypesDrives, isFetching} = useQuery(["gate_type_drive_pricing", chosenGateType], () => getChosenGateTypesDrives(accessToken, chosenGateType?.id), {
        placeholderData: [],
        enabled:!!chosenGateType?.id
    });
    function groupBy(array, f) {
        const groups = {};
        array?.forEach(function (o) {
            const group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const pricingGroupedByWidth = groupBy(pricing, function (item) {
        return [item.minWidth, item.maxWidth];
    });

    const pricingGroupedByHeight = groupBy(pricing, function (item) {
        return [item.minHeight, item.maxHeight];
    });


    useEffect(() => {
        if (gateTypes) {
            setChosenGateType(gateTypes?.find(e => e.id === gateType))
        }
    }, [gateTypes, gateType])
    const {data: widths} = useQuery(["allWidths", chosenGateType?.id], () => getAllSizes(accessToken, "widths"), {
        refetchOnWindowFocus: false,
        enabled: !!chosenGateType,
        placeholderData:[]
    });

    const {data: heights} = useQuery(["allHeights", chosenGateType?.id], () => getAllSizes(accessToken, "heights"), {
        refetchOnWindowFocus: false,
        enabled: !!chosenGateType,
        placeholderData:[]
    });
    useEffect(() => {
        const filteredWidth = widths?.map(w => {
            return {
                value: w.id,
                label: w.value.toString()
            }
        })
        setChosenGateTypeWidthOptions(filteredWidth)

        const filteredHeight = heights?.map(h => {
            return {
                value: h.id,
                label: h.value.toString()
            }
        })
        setChosenGateTypeHeightOptions(filteredHeight)
    }, [chosenGateType,heights,widths])

    useEffect(() => {
            setAddWidthColumn(false)
            setAddHeightColumn(false)
    }, [chosenGateType]);

    const [widthColumn, setWidthColumn] = useState([]);
    const [addWidthColumn, setAddWidthColumn] = useState(false);
    const [heightColumn, setHeightColumn] = useState([]);
    const [addHeightColumn, setAddHeightColumn] = useState(false);

    useEffect(() => {
        const pricingGroupedByWidthFiltered = pricingGroupedByWidth.map(w => {
            return {
                minWidth: w[0].minWidth,
                maxWidth: w[0].maxWidth,
                items: w
            }
        })
        const pricingGroupedByHeightFiltered = pricingGroupedByHeight.map(h => {
            return {
                minHeight: h[0].minHeight,
                maxHeight: h[0].maxHeight,
                items: h,
                gateType: h[0].gateType.id
            }
        })
        if (!!pricingGroupedByWidthFiltered) {
            setWidthColumn(pricingGroupedByWidthFiltered)
        }
        if (!!pricingGroupedByHeightFiltered) {
            setHeightColumn(pricingGroupedByHeightFiltered)
        }
    }, [pricing, gateType, chosenGateType])

    return (
        <FullScreen handle={handle}>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div id="kt_content_container" className="container-xxl p-0 m-0 w-100 mw-unset">
                    <div className="card">
                        <div className="card-header border-0 pt-6 pb-6">
                            <div className="d-flex w-100 justify-content-between">
                                <div className="card-toolbar ">
                                    <div className="me-5 d-flex justify-content-between w-100">
                                        <div className="w-100 d-flex">
                                            <Select id="ktm-select"
                                                    searchable
                                                    disabled={gateTypeLoading}
                                                    styles={(theme) => ({
                                                        input: {
                                                            padding: 20,
                                                            borderRadius: "0,457rem",
                                                            width: "100%",
                                                            minWidth: 220
                                                        }
                                                    })}
                                                    placeholder={"Wybierz rodzaj bramy"}
                                                    name="drive-type"
                                                    onChange={(e) => {
                                                        setGateType(e)
                                                        setPage(1)
                                                        setShowTable(false)
                                                    }}
                                                    data={gateTypes?.length > 0 ? [{
                                                        value: "nogate",
                                                        label: "Wybierz rodzaj bramy..."
                                                    }, ...gateTypes.map(gT => {
                                                        return {value: gT.id, label: gT.name}
                                                    })] : []}
                                                    className=" me-5">
                                            </Select>
                                            {gateTypeLoading && <div
                                                className="d-flex flex-column justify-content-center align-items-center p-5">
                                                <CircularProgress size={12}/>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {gateType !== 'nogate' && (!isLoading && showTable) && <Fade in={!!gateType}>
                            <div className="card-body pt-0 overflow-scroll table-custom-scroll">
                                <table className="table align-middle table-row-dashed fs-6 gy-5 custom-table"
                                       id="kt_table_users">
                                    <thead>
                                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                        <th colSpan={3}
                                            rowSpan={2}
                                            style={{borderBottom: "none!important", verticalAlign: "middle"}}>
                                            <div id="full-screen-btn">
                                                <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo.png')}
                                                     className='h-30px'/>
                                            </div>
                                        </th>
                                        <th className="text-uppercase text-center text"
                                            style={{background: "#f5f5f5", borderBottomColor: "#dee2e6"}}
                                            colSpan={100}>Zakres szerokości w mm
                                        </th>
                                    </tr>
                                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                        {widthColumn.map((w, i) => {
                                            return (
                                                <th className="text-center" style={{
                                                    background: "#f5f5f5",
                                                    borderBottomColor: "#dee2e6"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}>
                                                        {w?.items ? <div style={{marginRight: '1.25rem'}}><p
                                                                className={'mb-0'}>{w?.minWidth?.value}</p></div> :
                                                            <Select id="width-value"
                                                                    searchable
                                                                    styles={(theme) => ({
                                                                        input: {
                                                                            width: 100,
                                                                            borderRadius: "0,457rem",
                                                                        }
                                                                    })}
                                                                    placeholder={"Szerokość od"}
                                                                    name="drive-type"
                                                                    onChange={(e) => {
                                                                        let newWidthValue = [...widthColumn];
                                                                        newWidthValue[i] = {
                                                                            ...newWidthValue[i],
                                                                            minWidth: e
                                                                        }
                                                                        setWidthColumn(newWidthValue)
                                                                    }}
                                                                    data={chosenGateTypeWidthOptions ? chosenGateTypeWidthOptions : []}
                                                                    className=" me-5">
                                                            </Select>}
                                                        <span style={{marginRight: '1.25rem'}}> - </span>
                                                        {w?.items ?
                                                            <div><p className={'mb-0'}>{w?.maxWidth?.value}</p></div> :
                                                            <Select id="width-value"
                                                                    searchable
                                                                    styles={(theme) => ({
                                                                        input: {
                                                                            width: 100,
                                                                            borderRadius: "0,457rem",
                                                                        }
                                                                    })}
                                                                    placeholder={"Szerokość od"}
                                                                    name="drive-type"
                                                                    onChange={(e) => {
                                                                        let newWidthValue = [...widthColumn];
                                                                        newWidthValue[i] = {
                                                                            ...newWidthValue[i],
                                                                            maxWidth: e
                                                                        }
                                                                        setWidthColumn(newWidthValue)
                                                                    }}
                                                                    data={chosenGateTypeWidthOptions ? chosenGateTypeWidthOptions : []}
                                                                    className=" me-5">
                                                            </Select>}
                                                        {w?.items && <div className={'d-flex gap-1 mx-1'}>
                                                            <div className={'cursor-pointer'} onClick={() => {
                                                                setChosenCol(widthColumn[i])
                                                                setChosenRow(undefined)
                                                                setIsEdit(prev => !prev)
                                                            }}>
                                                                <KTSVG path='/media/icons/duotune/art/art005.svg'
                                                                       className='svg-icon-3'/>
                                                            </div>
                                                            <div className={'cursor-pointer'} onClick={() => {
                                                                handleOpen()
                                                                setChosenRow(undefined)
                                                                setChosenCol(widthColumn[i])
                                                            }}>
                                                                <KTSVG path='/media/icons/duotune/general/gen027.svg'
                                                                       className='svg-icon-3 delete'/>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </th>
                                            )
                                        })}
                                        <th
                                            onClick={() => {
                                                if (!addWidthColumn) {
                                                    setWidthColumn(prevState => [...prevState, {}])
                                                    setAddWidthColumn(true)
                                                }
                                            }}
                                            className="text-center" style={{
                                            background: "#f5f5f5",
                                            borderBottomColor: "#dee2e6"
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'pointer'
                                            }}>
                                                + Dodaj zakres
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody id="ktm_gate" className="text-gray-600 fw-bold">
                                    {heightColumn.map((hC, index) => {
                                        return gateTypesDrives.driveGroups.map((dG, i) => {
                                            return (
                                                <tr style={index % 2 === 0 ? {background: "#f5f5f5"} : {background: "white"}}>
                                                    {i === 0 && index === 0 &&
                                                        <td style={{
                                                            background: "#f5f5f5",
                                                            borderRight: "1px dotted #d5d5d5"
                                                        }}
                                                            rowSpan={0}><p style={heightCell}>Zakres wysokość w mm</p>
                                                        </td>}
                                                    {i === 0 && <td
                                                        className={'text-uppercase fs-7'}
                                                        rowSpan={gateTypesDrives.driveGroups.length}
                                                        style={{
                                                            background: index % 2 === 0 ? "#f5f5f5" : '#ffffff',
                                                            borderRight: "1px dotted #d5d5d5",
                                                            borderBottom: "1px dotted #d5d5d5",
                                                        }}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: 'center',
                                                            justifyContent: 'center',

                                                        }}>
                                                            {hC.items ? <div style={{marginRight: '1.25rem'}}><p
                                                                    className={'mb-0'}>{hC?.minHeight?.value}</p></div> :
                                                                <Select id="height-value"
                                                                        searchable
                                                                        styles={(theme) => ({
                                                                            input: {
                                                                                width: 100,
                                                                                borderRadius: "0,457rem",
                                                                            }
                                                                        })}
                                                                        name="minHeight"
                                                                        onChange={(e) => {
                                                                            let newHeightValue = [...heightColumn];
                                                                            newHeightValue[index] = {
                                                                                ...newHeightValue[index],
                                                                                minHeight: e
                                                                            }
                                                                            setHeightColumn(newHeightValue)
                                                                        }}
                                                                        data={chosenGateTypeHeightOptions ? chosenGateTypeHeightOptions : []}
                                                                        className=" me-5">
                                                                </Select>}
                                                            <span style={{marginRight: '1.25rem'}}> - </span>
                                                            {hC.items ? <div><p
                                                                    className={'mb-0'}>{hC?.maxHeight?.value}</p></div> :
                                                                <Select id="heightMax-value"
                                                                        searchable
                                                                        styles={(theme) => ({
                                                                            input: {
                                                                                width: 100,
                                                                                borderRadius: "0,457rem",
                                                                            }
                                                                        })}
                                                                        name="maxHeight"
                                                                        onChange={(e) => {
                                                                            let newHeightValue = [...heightColumn];
                                                                            newHeightValue[index] = {
                                                                                ...newHeightValue[index],
                                                                                maxHeight: e
                                                                            }
                                                                            setHeightColumn(newHeightValue)
                                                                        }}
                                                                        data={chosenGateTypeHeightOptions ? chosenGateTypeHeightOptions : []}
                                                                        className=" me-5">
                                                                </Select>}
                                                            {hC?.items && <div className={'d-flex gap-1 mx-1'}>
                                                                <div className={'cursor-pointer'}  onClick={() => {
                                                                    setIsHeightEdit(true)
                                                                    setChosenCol(undefined)
                                                                    setChosenRow(heightColumn[index])
                                                                }}>
                                                                    <KTSVG path='/media/icons/duotune/art/art005.svg'
                                                                           className='svg-icon-3'/>
                                                                </div>
                                                                <div className={'cursor-pointer'} onClick={() => {
                                                                    handleOpen()
                                                                    setChosenCol(undefined)
                                                                    setChosenRow(heightColumn[index])
                                                                }}>
                                                                    <KTSVG
                                                                        path='/media/icons/duotune/general/gen027.svg'
                                                                        className='svg-icon-3 delete'/>
                                                                </div>
                                                            </div>}
                                                        </div>
                                                    </td>}
                                                    <td style={{
                                                        background: index % 2 === 0 ? "#f5f5f5" : '#ffffff',
                                                        borderRight: "1px dotted #d5d5d5",
                                                        borderBottom: "1px dotted #d5d5d5"
                                                    }}>{dG.name}</td>
                                                    {widthColumn.map(wC => {
                                                        const price = pricingFilteredByGate.find(el => el?.minHeight?.id === hC?.minHeight?.id && el?.maxHeight?.id === hC?.maxHeight?.id && el?.minWidth?.id === wC?.minWidth?.id && el?.maxWidth?.id === wC?.maxWidth?.id && el?.driveGroup?.id === dG?.id && gateType === hC.gateType)
                                                        return (
                                                            <td>
                                                                <div className={'position-relative'}>
                                                                    <input
                                                                        name={(hC?.gateType && wC?.minWidth?.id && wC?.maxWidth?.id && hC?.minHeight?.id && hC?.maxHeight?.id && dG?.id) ?
                                                                            hC?.gateType + wC?.minWidth?.id + wC?.maxWidth?.id + hC?.minHeight?.id + hC?.maxHeight?.id + dG?.id : 'new'}
                                                                        defaultValue={price?.price ? price?.price / 100 : undefined}
                                                                        type="number"
                                                                        className="form-control form-control2"
                                                                        disabled={createPriceMutation.isLoading || updatePriceMutation.isLoading}
                                                                        onFocus={(e) => {
                                                                            setChosenInput(e.target.name)
                                                                            const findChosenRangeItem = pricing.find(el => el?.minHeight?.id === hC?.minHeight?.id && el?.maxHeight?.id === hC?.maxHeight?.id && el?.minWidth?.id === wC?.minWidth?.id && el?.maxWidth?.id === wC?.maxWidth?.id && el?.driveGroup?.id === dG?.id && gateType === hC.gateType)
                                                                            setChosenRange(findChosenRangeItem ? findChosenRangeItem : {
                                                                                driveGroup: dG.id,
                                                                                range: {heightRange: hC, widthRange: wC}
                                                                            })
                                                                        }
                                                                        }
                                                                        onChange={(event) => {
                                                                            setPrice(event.target.value)
                                                                        }}/>
                                                                    {(createPriceMutation.isLoading || updatePriceMutation.isLoading) && (chosenInput === hC.gateType + wC.minWidth.id + wC.maxWidth.id + hC.minHeight.id + hC.maxHeight.id + dG.id || chosenInput === 'new') &&
                                                                        <div className={'position-absolute'}
                                                                             style={{top: '10px', right: '10px'}}>
                                                                            <CircularProgress size={20}/>
                                                                        </div>}
                                                                </div>
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })
                                    })}
                                    <tr>

                                        <td className={'text-center'} style={{
                                            background: "#f5f5f5",
                                            borderRight: "1px dotted #d5d5d5",
                                            borderBottom: "1px dotted #d5d5d5"
                                        }}
                                            colSpan={1}
                                            rowSpan={1}>
                                            <div
                                                className={'text-uppercase fs-7'}
                                                onClick={() => {
                                                    if (!addHeightColumn) {
                                                        setHeightColumn(prevState => [...prevState, {}])
                                                        setAddHeightColumn(true)
                                                    }
                                                }}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                + Dodaj zakres
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Fade>}
                        {(isLoading || showTable === false) &&
                            <div className="d-flex flex-column justify-content-center align-items-center p-5">
                                <CircularProgress/>
                                <strong className="mt-10 bold">Cennik jest generowany!</strong>
                            </div>}
                        {gateType === 'nogate' &&
                            <div id="kt_content_container" className="container-xxl mt-10">
                                <div className="card-header border-0 p-0">
                                    <div className="card text-center">
                                        <p className="m-0" style={{fontSize: 16, fontWeight: 500}}>Aby wyświetlić
                                            cennik, wybierz rodzaj bramy.</p>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={deleteModalStyle}>
                    {deletePriceMutation.isLoading && <><p style={{fontSize: 20}}>Usuwanie...</p><CircularProgress/></>}
                    {!deletePriceMutation.isSuccess && !deletePriceMutation.isLoading && <>
                        <p style={{fontSize: 20}}>Czy na pewno chcesz usunąć wybrany zakres?</p>
                        <div className="d-flex justify-content-between w-100">
                            <button
                                onClick={() => {
                                    deletePriceMutation.mutate({
                                        accessToken,
                                        ids: chosenCol ? chosenCol.items.map(i => i.id) : chosenRow ? chosenRow.items.map(i => i.id) : []
                                    }, {
                                        onSuccess: () => {
                                            handleClose()
                                        }
                                    })
                                }}
                                className='btn btn-danger'>Tak, usuń
                            </button>
                            <button
                                onClick={() => handleClose()}
                                className='btn btn-primary'>Nie, zamknij
                            </button>
                        </div>
                    </>}
                </div>
            </Modal>

            <Modal
                open={isEdit}
                onClose={() => {
                    setNewMinWidthValue(undefined)
                    setNewMaxWidthValue(undefined)
                    setIsEdit(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={deleteModalStyle}>
                    {changeRangeMutation.isLoading && <><p style={{fontSize: 20}}>Zmienianie...</p>
                        <CircularProgress/></>}
                    {!changeRangeMutation.isSuccess && !changeRangeMutation.isLoading && <>
                        <p style={{fontSize: 20}}>Czy na pewno chcesz zmienić wybrany zakres?</p>
                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Select id="width-value"
                                    searchable
                                    value={newMinWidthValue ? newMinWidthValue : chosenCol?.minWidth?.id}
                                    styles={(theme) => ({
                                        input: {
                                            width: 100,
                                            borderRadius: "0,457rem",
                                        }
                                    })}
                                    placeholder={"Szerokość od"}
                                    name="drive-type"
                                    onChange={(e) => {
                                        setNewMinWidthValue(e)
                                    }}
                                    data={chosenGateTypeWidthOptions ? chosenGateTypeWidthOptions : []}
                                    className=" me-5">
                            </Select>
                            <span style={{marginRight: '1.25rem'}}> - </span>
                            <Select id="width-value"
                                    searchable
                                    value={newMaxWidthValue ? newMaxWidthValue : chosenCol?.maxWidth?.id}
                                    styles={(theme) => ({
                                        input: {
                                            width: 100,
                                            borderRadius: "0,457rem",
                                        }
                                    })}
                                    placeholder={"Szerokość od"}
                                    name="drive-type"
                                    onChange={(e) => {
                                        setNewMaxWidthValue(e)
                                    }}
                                    data={chosenGateTypeWidthOptions ? chosenGateTypeWidthOptions : []}
                                    className=" me-5">
                            </Select>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <button
                                onClick={() => {
                                    changeRangeMutation.mutate({
                                        accessToken,
                                        items: chosenCol.items,
                                        newMaxWidthValue: newMaxWidthValue,
                                        newMinWidthValue: newMinWidthValue,
                                        newMaxHeightValue: undefined,
                                        newMinHeightValue: undefined
                                    })
                                }}
                                className='btn btn-danger'>Tak, zmień
                            </button>
                            <button
                                onClick={() => {
                                    setNewMinWidthValue(undefined)
                                    setNewMaxWidthValue(undefined)
                                    setIsEdit(false)
                                }}
                                className='btn btn-primary'>Nie, zamknij
                            </button>
                        </div>
                    </>}
                </div>
            </Modal>
            <Modal
                open={isHeightEdit}
                onClose={() => {
                    setNewMinHeightValue(undefined)
                    setNewMaxHeightValue(undefined)
                    setIsHeightEdit(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={deleteModalStyle}>
                    {changeRangeMutation.isLoading && <><p style={{fontSize: 20}}>Zmienianie...</p>
                        <CircularProgress/></>}
                    {!changeRangeMutation.isSuccess && !changeRangeMutation.isLoading && <>
                        <p style={{fontSize: 20}}>Czy na pewno chcesz zmienić wybrany zakres?</p>
                        <div style={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Select id="width-value"
                                    searchable
                                    value={newMinHeightValue ? newMinHeightValue : chosenRow?.minHeight?.id}
                                    styles={(theme) => ({
                                        input: {
                                            width: 100,
                                            borderRadius: "0,457rem",
                                        }
                                    })}
                                    placeholder={"Szerokość od"}
                                    name="drive-type"
                                    onChange={(e) => {
                                        setNewMinHeightValue(e)
                                    }}
                                    data={chosenGateTypeHeightOptions ? chosenGateTypeHeightOptions : []}
                                    className=" me-5">
                            </Select>
                            <span style={{marginRight: '1.25rem'}}> - </span>
                            <Select id="width-value"
                                    searchable
                                    value={newMaxHeightValue ? newMaxHeightValue : chosenRow?.maxHeight?.id}
                                    styles={(theme) => ({
                                        input: {
                                            width: 100,
                                            borderRadius: "0,457rem",
                                        }
                                    })}
                                    placeholder={"Szerokość od"}
                                    name="drive-type"
                                    onChange={(e) => {
                                        setNewMaxHeightValue(e)
                                    }}
                                    data={chosenGateTypeHeightOptions ? chosenGateTypeHeightOptions : []}
                                    className=" me-5">
                            </Select>
                        </div>
                        <div className="d-flex justify-content-between w-100">
                            <button
                                onClick={() => {
                                    changeRangeMutation.mutate({
                                        accessToken,
                                        items: chosenRow.items,
                                        newMaxHeightValue: newMaxHeightValue,
                                        newMinHeightValue: newMinHeightValue,
                                        newMaxWidthValue: undefined,
                                        newMinWidthValue: undefined,
                                    })
                                }}
                                className='btn btn-danger'>Tak, zmień
                            </button>
                            <button
                                onClick={() => {
                                    setNewMinHeightValue(undefined)
                                    setNewMaxHeightValue(undefined)
                                    setIsHeightEdit(false)
                                }}
                                className='btn btn-primary'>Nie, zamknij
                            </button>
                        </div>
                    </>}
                </div>
            </Modal>
        </FullScreen>
    )
}

const PricesWrapper: FC = () => {

    return (
        <>
            <PageTitle>Cennik</PageTitle>
            <PricesPage/>
        </>
    )
}

export {PricesWrapper}
