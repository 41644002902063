/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {FilterDropdown} from '../../component/dropdown/FilterDropdown'
import {ClientCreate} from "./ClientCreate";
import {IOffer} from "../offerList/OfferTable";
import {IClients} from "./ClientAddModel";
import {useFormik} from "formik";
import {useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import Clients from "./Clients";
import Distributors from "./Distributors";
import Box from "@mui/material/Box";
import {AdminCreateForm} from "../adminList/AdminCreateForm";
import Modal from "@mui/material/Modal";
import {ClientCrateForm} from "./ClietnCreateModalForm";
import SubMenu from "../../component/subMenu/SubMenu";
import {style, theme} from "../adminList/Admin";
import {ThemeProvider} from "@mui/material";
import {fetchAllClients} from "./ClientsFunctions";
import {Pagination, Select} from "@mantine/core";

type Props = {
    className: string
}

const ClientTable: React.FC<Props> = ({className}) => {
    const [activeBtn, setActiveBtn] = useState("Klienci");
    const [isClient, setIsClient] = useState(true)
    const [chosenListItem, setChosenListItem] = useState<IClients | null>(null);
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = useState(1)
    const [clientStatusFilter, setClientStatusFilter] = useState(undefined)
    const [searchValue, setSearchValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            const search = setTimeout(() => {
                setTriggerSearch(prevState => !prevState)
            }, 1200)

            return () => {
                clearTimeout(search)
            }
        } else {
            setTriggerSearch(prevState => !prevState)
        }
    }, [searchValue])

    useEffect(() => {
        const nextPage = page + 1;

        queryClient.prefetchQuery(
            ["posts", nextPage],
            () => fetchAllClients(accessToken, searchValue.trim().length > 0 ? "?simplesearch=" + searchValue : "", nextPage))
    }, [page, queryClient])

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const handleChosenListItem = (item: any) => {
        setChosenListItem(item)
    }

    const {
        data: clientData,
        isLoading,
        isFetching,
        isError,
        isFetched,
        isRefetching
    } = useQuery<IClients[]>(['clients', triggerSearch, page, isClient,clientStatusFilter], () => fetchAllClients(accessToken, searchValue.trim().length > 0 ? "?simplesearch=" + searchValue : clientStatusFilter ? `?${clientStatusFilter}&page=${page}&itemsPerPage=30` :  `?page=${page}&itemsPerPage=30&isDistributor=${!isClient}`), {
        keepPreviousData: true
    });
    const {
        data: allClients,
    } = useQuery<IClients[]>(['allClients', isClient], () => fetchAllClients(accessToken, `?pagination=false&isDistributor=${!isClient}`), {
        keepPreviousData: true
    });

    const {
        data: noVerifiedClients,
    } = useQuery<IClients[]>(['noVerifiedClients', isClient], () => fetchAllClients(accessToken, `?pagination=false&isDistributor=${!isClient}&isVerified=false&isActive=false`), {
        keepPreviousData: true,
        placeholderData: []
    });

    const totalPages = Math.ceil(allClients?.length / 30)

    const filteredClientData = clientData?.length > 0 ? clientData?.filter(client => {
        return client.isDistributor !== isClient
    }) : []

    return (
        <>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className="d-flex justify-content-center d-sm-none mt-5">
                    <button
                        onClick={() => {
                            setActiveBtn("Klienci")
                            setIsClient(true)
                        }}
                        className={`btn btn-sm ${activeBtn === "Klienci" ? "btn-primary" : "btn-secondary"} mx-5`}>Klienci
                    </button>
                    <button
                        onClick={() => {
                            setActiveBtn("Dystrybutorzy")
                            setIsClient(false)
                        }}
                        className={`btn btn-sm ${activeBtn === "Dystrybutorzy" ? "btn-primary" : "btn-secondary"} mx-5`}>Dystrybutorzy
                    </button>
                </div>
                <div className='card-header border-0 pt-5 d-flex flex-column flex-nowrap gap-5 justify-content-between align-items-center'>
                    <div className="d-none d-sm-flex align-items-center">
                        <a href="#"
                           onClick={() => {
                               setActiveBtn("Klienci")
                               setIsClient(true)
                               setPage(1)
                           }}
                           className={`btn btn-sm ${activeBtn === "Klienci" ? "btn-primary" : "btn-secondary"} mx-5`}>Klienci</a>
                        <a
                            href="#"
                            onClick={() => {
                                setActiveBtn("Dystrybutorzy")
                                setIsClient(false)
                                setPage(1)
                            }}
                            className={`btn btn-sm ${activeBtn === "Dystrybutorzy" ? "btn-primary" : "btn-secondary"} mx-5`}>Dystrybutorzy</a>
                    </div>
                    <div className={'d-flex justify-content-between w-100'}>
                        <div className={'d-flex align-items-center gap-5'}>
                            <div className="d-flex align-items-center position-relative my-1">
                        <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                  height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                  fill="black"/>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"/>
                        </svg>
                         </span>
                                <input type="text"
                                       value={searchValue}
                                       onChange={(e) => setSearchValue(e.target.value)}
                                       className="form-control form-control-solid ps-14"
                                       placeholder="Szukaj..."/>
                            </div>
                            <div className="d-flex">
                                <Select id="ktm-select"
                                        searchable
                                        styles={(theme) => ({
                                            input: {
                                                padding: 20,
                                                borderRadius: "0,457rem",
                                                width: "100%",
                                                minWidth: 220
                                            }
                                        })}
                                        placeholder={"Filtruj po statusie"}
                                        name="drive-type"
                                        onChange={(e) => {
                                            setClientStatusFilter(e)
                                        }}
                                        data={[
                                            {value: 'isActive=true&isVerified=true', label: "Wszyscy"},
                                            {value: 'isActive=true', label: "Aktywny"},
                                            {value: 'isActive=false&isVerified=true', label: "Dezaktywowany"},
                                            {value: 'isVerified=false&isActive=false', label: "Niezweryfikowany"},
                                        ]}
                                        className=" me-5">
                                </Select>
                            </div>
                        </div>
                        <div
                            className='card-toolbar'
                            data-bs-toggle='tooltip'
                            data-bs-placement='top'
                            data-bs-trigger='hover'
                            title='Kliknij, aby dodać klienta'
                        >
                            <button
                                className='btn btn-sm btn-light-primary d-flex'
                                onClick={() => {
                                    setChosenListItem(null)
                                    handleOpen()
                                }
                                }
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                                <span className="d-none d-sm-block">Dodaj</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    <Clients handleChosenListItem={handleChosenListItem}
                             setChosenListItem={setChosenListItem}
                             isClient={isClient}
                             modalOpen={open}
                             setOpen={setOpen}
                             clientData={clientData}
                             noVerifiedClients={noVerifiedClients}
                             isLoading={isLoading}
                             isError={isError}
                             page={page}
                             searchValue={searchValue}
                             filteredClientData={filteredClientData}/>
                    {totalPages > 1 && <Pagination total={totalPages} page={page} onChange={setPage}
                                                   style={{justifyContent: "flex-end"}}/>}
                </div>
                {/* begin::Body */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={theme}>
                        <Box sx={style}>
                            <div>
                                <div>
                                    <div className='modal-header pb-0 border-0 justify-content-end'>
                                        <div
                                            onClick={() => setOpen(!open)}
                                            className='btn btn-sm btn-icon btn-active-color-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className='svg-icon-1'/>
                                        </div>
                                    </div>

                                    <div className='modal-body scroll-y'>
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} {isClient ? "klienta" : "dystrybutora"}</h1>
                                        </div>
                                        <ClientCrateForm isClient={isClient} setChosenListItem={setChosenListItem}
                                                         chosenListItem={chosenListItem} modalOpen={open}
                                                         setOpen={setOpen}/>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </ThemeProvider>
                </Modal>
            </div>
        </>
    )
}

export {ClientTable}
