export const fetchAllAdmins = async (accessToken,url) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/admins${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data = await response.json();
    return data;
}