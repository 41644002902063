/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {OfferTable} from "./OfferTable";
import {useParams} from "react-router-dom";

const OfferListPage: FC = () => (
    <>
       <OfferTable className='card-xxl-stretch mb-5 mb-xl-8'/>
    </>
)

const OfferListWrapper: FC = () => {
    const params = useParams();

    return (
        <>
            <PageTitle>{"Lista " + (params.type !== "offers" ? "zamówień": "ofert")}</PageTitle>
            <OfferListPage/>
        </>
    )
}

export {OfferListWrapper}
