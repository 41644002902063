import axios from 'axios'
import {UserModel} from '../models/UserModel';
import jwt from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}api/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}api/reset_password/create_request`
export const RESET_PASSWORD_URL = `${API_URL}api/reset_password/process`
export const REFRESH_TOKEN_URL = `${API_URL}api/auth/refresh_token`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
    redirectUrl: 'http://futryna-admin.dev-effectivity.pl/auth/reset-password'
  })
}

export function resetPassword(token: string,password: string) {
  return axios.post<{result: boolean}>(RESET_PASSWORD_URL, {
    password,
    token
  })
}

export function refreshToken(refreshToken: string) {
  const client =  axios.create({baseURL: process.env.PUBLIC_URL})

  const response = client.post<{result: boolean}>(REFRESH_TOKEN_URL, {
    refresh_token: refreshToken,
  })

  if (response) {
    return response
  }
}

// export function getUserByToken(token:string) {
//   console.log(token)
//
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token:token
//   })
// }
