import React, {FC, useEffect, useState} from 'react';
import {KTSVG} from "../../../_metronic/helpers";
import {IComponent} from "./Components";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {updateComponentItemById} from "../componentCreate/ComponentsCreateFunctions";
import {deleteComponentById} from "./ComponentsFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {getAllGateTypes} from "./gateCategory/GateCategoryFunctions";
import Select from "react-select";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import {deleteModalStyle} from "../../component/subMenu/SubMenu";

const customStyles: StylesConfig = {
    control: () => ({
        // none of react-select's styles are passed to <Control />
        backgroundColor: "transparent",
        display: "flex",
        borderColor: "#F5F8FA",
        fontWeight: 500,
        transition: "color 0.2s ease, background-color 0.2s ease",
        border: "1px solid #E4E6EF",
        fontSize: "1.1rem",
        borderRadius: "0.475rem",
        fontFamily: `Poppins, Helvetica, "sans-serif"`,
        color: "#5E6278",
        padding: "0.225rem 1rem"
    }),
    indicatorSeparator: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: "none"
        }
    },
    indicatorsContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#9c9c9c"
        }
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#5E6278",
        }
    },
    singleValue: (s) => ({
        ...s,
        color: "#5E6278",
        borderRight: "none"
    })
}

const CustomMassiveActions: FC<{ isChecked: { first: boolean, all: boolean }, component: IComponent }> = (props) => {
        const {isChecked, component, open, setOpen, chosenComponent, setChosenComponents} = props;
        const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
        const [globalActionType, setGlobalActionType] = useState(undefined);
        const [finalStep, setFinalStep] = useState(false)
        const [priceChangeType, setPriceChangeType] = useState(undefined);
        const [gateType, setGateType] = useState([]);
        const [gateCategory, setGateCategory] = useState(undefined);
        const [chosenRelatedComponentItems, setChosenRelatedComponentItem] = useState([]);
        const [value, setValue] = useState(null)
        const client = useQueryClient();
        const chosenComponents = component?.componentItems?.filter(e => chosenComponent.includes(e.id));
        const chosenRelatedItem = component?.relatedComponents?.find(e => e.id === globalActionType);

        const gateOptions: [] = component?.gateTypes?.map((gate: { id: string, name: string }) => {
            return {value: gate.id, label: gate.name}
        })

        const relatedComponentsOptions: [] = component?.relatedComponents?.map((comp: { id: string, name: string }) => {
            return {value: comp.id, name: comp.name}
        })

        const relatedChosenItemOptions: [] = chosenRelatedItem?.componentItems?.map((comp: { id: string, name: string }) => {
            return {value: {element: comp.id, component: comp.component.id}, label: comp.name}
        })

        useEffect(() => {
            setPriceChangeType(undefined)
            setGlobalActionType(undefined)
            setGateType([])
            setGateCategory(undefined)
            setFinalStep(false)
            setChosenComponents([])

        }, [component])

        const handleInputChange = (e: any, toChange: any) => {
            toChange(e)
        }

        const deleteComponentMutation = useMutation(deleteComponentById, {
            onSuccess: () => {
                setChosenComponents([])
                setGlobalActionType(undefined)
                setGateType([])
                setPriceChangeType(undefined)
                setFinalStep(false)
                setValue(null)
                client.invalidateQueries()
            }
        })


        const componentItemUpdateMutation = useMutation(updateComponentItemById, {
            onSuccess: () => {
                setChosenComponents([])
                setGlobalActionType(undefined)
                setGateType([])
                setPriceChangeType(undefined)
                setFinalStep(false)
                setValue(null)
                client.invalidateQueries('components')
            },
            onError: () => {
                setTimeout(() => {
                    setChosenComponents([])
                    setGlobalActionType(undefined)
                    setGateType([])
                    setPriceChangeType(undefined)
                    setFinalStep(false)
                    setValue(null)
                }, 2000)
            }
        })

        const handleMassiveActions = () => {
            if (globalActionType === "price-change") {
                chosenComponents.forEach(element => {
                    let data;
                    switch (priceChangeType) {
                        case "increase-procentage" :
                            data = {
                                price: Math.round(element.price + (element.price * value / 100))
                            }
                            break;
                        case "decrease-procentage" :
                            data = {
                                price: Math.round(element.price - (element.price * value / 100))
                            }
                            break;
                        case "increase-value" :
                            data = {
                                price: +value + element.price
                            }
                            break;
                        case "decrease-value" :
                            data = {
                                price: element.price - +value
                            }
                            break;
                        case "change-value" :
                            data = {
                                price: +value
                            }
                            break;
                    }
                    componentItemUpdateMutation.mutate({
                        accessToken,
                        data,
                        id: element.id
                    })
                })

            }
            if (globalActionType === "gate-change") {
                chosenComponents.forEach(element => {
                    componentItemUpdateMutation.mutate({
                        accessToken,
                        data: {
                            gateTypes: gateType
                        },
                        id: element.id
                    })
                })
            } else {
                chosenComponents.forEach(element => {
                    const elementComponentItemsValues = element.componentItemsValues.filter(e => {
                         return e.component.id !== chosenRelatedComponentItems[0].component
                    })

                    componentItemUpdateMutation.mutate({
                        accessToken,
                        data: {
                            componentItemsValues: chosenRelatedComponentItems.map(e => e.element).concat(elementComponentItemsValues.map(c=> c.id))
                        },
                        id: element.id
                    })
                })
            }

        }

        return (
            <div className='card-header border-0 pt-5'>
                <div className="d-flex align-items-center">
                    <div className="w-200px me-5">
                        {chosenComponent?.length > 0 && <select id="global-action"
                                                                name="global-action"
                                                                className="form-select w-200px me-5"
                                                                value={globalActionType}
                                                                onChange={e => handleInputChange(e.target.value, setGlobalActionType)}
                        >
                            <option>Działania masowe...</option>
                            <option value="price-change">Zmień cenę</option>
                            <option value="gate-change">Zmień rodzaj bram</option>
                            {relatedComponentsOptions && relatedComponentsOptions?.map(comp => {
                                return (
                                    <option value={comp?.value}>{comp?.name}</option>
                                )
                            })}
                        </select>}
                    </div>
                    {chosenComponent?.length > 0 && globalActionType === "price-change" && <div id="price-select">
                        <select id="price-change"
                                name="price-change"
                                className="form-select w-200px me-5"
                                value={priceChangeType}
                                onChange={e => handleInputChange(e.target.value, setPriceChangeType)}>
                            <option>Typ zmiany...</option>
                            <option value="increase-procentage">zwiększ o %</option>
                            <option value="decrease-procentage">zmniejsz o %</option>
                            <option value="increase-value">zwiększ o kwotę</option>
                            <option value="decrease-value">zmiejsz o kwotę</option>
                            <option value="change-value">zmień wartość</option>
                        </select>
                    </div>}
                    {chosenComponent?.length > 0 && globalActionType === "gate-change" && <div>
                        <Select
                            styles={customStyles}
                            className="custom-select"
                            name={"gate-change"}
                            onChange={(e) => {
                                setFinalStep(true)
                                setGateType(e.map(it => it.value))
                            }}
                            placeholder={"Wybierz rodzaj bram..."}
                            options={gateOptions}
                            isMulti
                        />

                    </div>
                    }

                    {(globalActionType !== "price-change" && globalActionType !== "category-change" && chosenRelatedItem && chosenComponent?.length > 0) &&
                        <div id="leading-select" className="w-300px">
                            <Select
                                styles={customStyles}
                                className="custom-select"
                                name={chosenRelatedItem?.name}
                                onChange={(e) => {
                                    setFinalStep(true)
                                    setChosenRelatedComponentItem(e.map(it => it.value))
                                }}
                                placeholder={`Wybierz ${chosenRelatedItem?.name?.toLowerCase()}...`}
                                options={relatedChosenItemOptions}
                                isMulti
                            />
                        </div>}
                    {priceChangeType && globalActionType === "price-change" && chosenComponent?.length > 0 &&
                        <div id="price-change-input" className="w-200px">
                            <input type="number"
                                   onChange={(e) => {
                                       setValue(e.target.value)
                                       setFinalStep(true)
                                   }
                                   }
                                   className="form-control" placeholder="Kwota"/>
                        </div>}

                    {finalStep && chosenComponent.length > 0 && <button
                        className='btn  btn-light-primary mx-5'
                        onClick={() => {
                            handleMassiveActions()
                            // if (chosenComponent?.length > 0) {
                            //     chosenComponent.forEach(el => {
                            //         deleteComponentMutation.mutate({
                            //             accessToken,
                            //             id: el
                            //         })
                            //     })
                            // }
                        }
                        }
                    >
                        Zapisz
                    </button>}
                    {/*{componentItemUpdateMutation.isError && chosenComponents.length > 0 &&*/}
                    {/*<p style={{marginBottom: 0, color: "red", fontWeight: 500, fontSize: 14}}>Coś poszło nie tak! Spróbuj*/}
                    {/*    ponownie!</p>}*/}
                </div>
                <div
                    className='card-toolbar ms-auto'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Kliknij, aby dodać element'
                >
                    {chosenComponent?.length === 0 ? <button
                            type="button"
                            className='btn btn-sm btn-light-primary'
                            onClick={() => setOpen(prev => !prev)}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                            Dodaj
                        </button> :
                        <>
                            <div className="fw-bolder me-5">Wybrano:
                                <span className="me-2"> {chosenComponent?.length}</span>
                            </div>
                            <button
                                className='btn btn-sm btn-light-danger'
                                onClick={() => {
                                    if (chosenComponent?.length > 0) {
                                        chosenComponent?.forEach(el => {
                                            deleteComponentMutation.mutate({
                                                accessToken,
                                                id: el
                                            }, {
                                                onSuccess: () => {
                                                    setTimeout(() => {
                                                        deleteComponentMutation.reset()
                                                    }, 3000)
                                                }
                                            })
                                        })
                                    }
                                }
                                }
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                                Usuń zaznaczone
                            </button>
                        </>}
                </div>
                {deleteComponentMutation.isSuccess && <div style={deleteModalStyle}>
                    Element został usunięty!
                </div>}
            </div>
        );
    }
;

export default CustomMassiveActions;