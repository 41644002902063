export const getAllGateTypes = async (accessToken: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types?pagination=false`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    return response.json()
}
export const getAllGateTypesPaginated = async (accessToken: string, page =1) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types?page=${page}&itemsPerPage=30`, {
        method: "GET",
        headers: {
            'Accept': 'application/ld+json',
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    const data =await  response.json()

    return {
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems']
    }
}
export const getChosenGateTypesWidth = async (accessToken: string, id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types/${id}/widths`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    const data = response.json()
    return data
}
export const getChosenGateTypesHeight = async (accessToken: string, id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types/${id}/heights`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    const data = response.json()
    return data
}
export const getChosenGateTypesDrives = async (accessToken: string, id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types/${id}/drive_groups`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    const data = response.json()
    return data
}
export const getGateTypeDrives =  async (accessToken: string, ids: string[]=[]) => {
    let drives = []
    const promises = ids.map(id =>
        fetch(`${process.env.REACT_APP_API_URL}api/gate_types/${id}/drive_groups`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        }).then(r => r.json()))

    const response = await Promise.all(promises);
    if (response) {
        drives = response.map(r => r.driveGroups)
    }

    return drives
}


export const createNewGateType = async (accessToken: string, data: { name: string, ktm: string, ktmDescription: string, drives: string[], heights: string[], widths: string[] }) => {

    const changedData = {
        name: data.name,
        ktm: data.ktm,
        ktmDescription: data.ktmDescription,
        image: data.image,
        driveGroups: data?.driveGroups,
        heights: data?.heights,
        widths: data?.widths
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types`, {
        method: "POST",
        body: JSON.stringify(changedData),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak! Spróbuj ponownie!");
    }
    return response.json()
}

export const editGaeTypeById = async ({
                                          accessToken,
                                          data,
                                          gateTypeId
                                      }: { accessToken: string, data: any, gateTypeId: string }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gate_types/${gateTypeId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Coś poszło nie tak! Spróbuj ponownie!");
    }
    return response.json()
}
