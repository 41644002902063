/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG} from './../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'

const Step1: FC<{name: string}> = ({name}) => {

  return (
      <div className="w-100">
        <div className="pb-10 pb-lg-15">
          <h2 className="fw-bolder d-flex align-items-center text-dark">Wprowadź nazwę komponentu</h2>
        </div>

        <div className="fv-row">
            <div className="row">
            <div className="me-12 min-w-400px">
              <label className="fw-bold fs-6 mb-2">Nazwa komponentu</label>
                <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name='name'
                    id='kt_create_component_form_component_name'
                />
            </div>
          </div>
        </div>
      </div>
  )
}

export {Step1}
