export const getOrdersAndOffers = async (accessToken, url) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
            method: "GET",
            headers: {
                'Accept': 'application/ld+json',
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error("Coś poszło nie tak!")
        }

        const data = await response.json();

        return {
            data: data['hydra:member'],
            totalItems: data['hydra:totalItems']
        }
    } catch (e) {
        console.log(e)
    }
}

export const getOffersById = async (accessToken, id, params) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/${params}/${id}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        });
        if (!response.ok) {
            throw new Error("Coś poszło nie tak!")
        }

        const data = await response.json();

        return data
    } catch (e) {
        console.log(e)
    }
}

export const getDetailGate = async (id, accessToken) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/gates/${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data = await response.json()
    return data;
}

export const getPdf = async (id, accessToken, orderNumber, params) => {

    try {
        let httpClient = new XMLHttpRequest();
        let pdfLink = `${process.env.REACT_APP_API_URL}api/${params}/${id}/get_pdf`;
        httpClient.open('get', pdfLink, true);
        httpClient.setRequestHeader( "Authorization", `Bearer ${accessToken}`)
        httpClient.responseType = "blob";
        httpClient.onload = function() {
            const file = new Blob([httpClient.response], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `${orderNumber}.pdf`;
            link.click();
            // document.body.removeChild(link);
            URL.revokeObjectURL(fileURL);
        };
        httpClient.send();
    } catch (e) {
        console.log(e);
    }
}

export const printPdf = async (id, accessToken, orderNumber, params) => {
    try {
        let httpClient = new XMLHttpRequest();
        let pdfLink = `${process.env.REACT_APP_API_URL}api/${params}/${id}/get_pdf`;
        httpClient.open('get', pdfLink, true);
        httpClient.setRequestHeader( "Authorization", `Bearer ${accessToken}`)
        httpClient.responseType = "blob";
        httpClient.onload = function() {
            const file = new Blob([httpClient.response], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            const iframe =  document.createElement('iframe'); //load content in an iframe to print later
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = fileURL;
            iframe.onload = function() {
                setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        };
        httpClient.send();
    } catch (e) {
        console.log(e);
    }
}

export const changeStatus = async ({data, accessToken, statusId, url}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${statusId}`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Zmiana statusu się nie powiodła!")
    }
}

export const changeIFS = async ({data, accessToken, orderId}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders/${orderId}/change_internal`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Zmiana numeru IFS się nie powiodła!")
    }
}

export const changeValidUntil = async ({data, accessToken, offerId}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/offers/${offerId}/change_valid_until`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Zmiana numeru IFS się nie powiodła!")
    }
}

export const changeRealizationDate = async ({data, accessToken, offerId}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/orders/${offerId}/change_realization`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Zmiana numeru IFS się nie powiodła!")
    }
}

export const updateOffer = async ({data, accessToken, statusId, url}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${statusId}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    if (!response.ok) {
        throw new Error("Zmiana statusu się nie powiodła!")
    }
}


export const deleteOffer = async ({accessToken, orderId, url}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${orderId}`, {
        method: "DELETE",
        headers: {
            "Authorization": `Bearer ${accessToken}`
        }
    })
}
export const deleteNewOffer = async ({accessToken, cartId}) => {

    const response = await fetch(`${process.env.REACT_APP_API_URL}api/carts/${cartId}/empty_cart`, {
        method: "PATCH",
        body: JSON.stringify({}),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}
