/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {FilterDropdown} from '../../component/dropdown/FilterDropdown'
import {AdminCreate} from "./AdminCreate";
import {IAdminDetails} from "./AdminAddModel";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import AdminSubMenu from "./AdminSubMenu";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {AdminCreateForm} from "./AdminCreateForm";
import SubMenu from "../../component/subMenu/SubMenu";
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import {fetchAllAdmins} from "./AdminFunctions";
import AdminTable from "./AdminTable";
import {auto} from "@popperjs/core";

type Props = {
    className: string
}
export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 300, // phone
            sm: 600, // tablets
            md: 900, // small laptop
            lg: 1200, // desktop
            xl: 1536 // large screens
        }
    }
});

export const style = {
    position: 'relative',
    margin: "1.75rem auto",
    maxWidth: {
        xs: "80%",
        md:"50%"
    },
    maxHeight: "100%",
    overflow: "auto",
    boxShadow: 24,
    backgroundColor: "#ffffff",
    border: "0 solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.475rem",
};

const Admin: React.FC<Props> = ({className}) => {
    const [chosenListItem, setChosenListItem] = useState<IAdminDetails | null>(null)
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [open, setOpen] = React.useState<boolean>(false);
    const [searchValue, setSearchValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            const search = setTimeout(() => {
                setTriggerSearch(prevState => !prevState)
            }, 1200)

            return () => {
                clearTimeout(search)
            }
        } else {
            setTriggerSearch(prevState => !prevState)
        }
    }, [searchValue])


    const {data: adminList, isFetching, isLoading} = useQuery(["admins", triggerSearch], () => fetchAllAdmins(accessToken, searchValue.trim().length > 0 ? "?simplesearch=" + searchValue : ""))

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5 d-flex flex-nowrap gap-5'>
                <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                  height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                  fill="black"/>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"/>
                        </svg>
                    </span>
                    <input type="text"
                           value={searchValue}
                           onChange={(e) => setSearchValue(e.target.value)}
                           className="form-control form-control-solid ps-14"
                           placeholder="Szukaj..."/>
                </div>
                <div
                    className='card-toolbar'
                    title='Kliknij, aby dodać admina'
                >
                    <button
                        className='btn btn-sm btn-light-primary'
                        onClick={() => setOpen(!open)}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        <span className="d-none d-sm-inline">Dodaj</span>
                    </button>
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {/* begin::Table container */}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {adminList && adminList?.length > 0 &&
                <AdminTable
                    adminList={adminList}
                    setChosenListItem={setChosenListItem}
                    chosenListItem={chosenListItem}
                    open={open}
                    setOpen={setOpen}
                    url={"admins"}
                />}
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpen(!open)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} admina</h1>
                                    </div>
                                    <AdminCreateForm
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    )
}

export {Admin}
