export interface IDriveDetails {
    name: string | null,
    image: string,
    ktm: string,
    ktmDescription: string,
    details: {
        minHeight: number,
        maxHeight: number,
        minTotalHeight: number,
        maxTotalHeight: number,
        maximalSurface: number,
        leftArea: number,
        rightArea: number,
        depth: number,
        ktm: string,
        ktmDescription: string,
        leading: number[]
    }[]
}


export const driveInitValues: IDriveDetails = {
    name: "",
    image: "",
    ktm: "",
    ktmDescription: "",
    details: [
        {
            minHeight: 0,
            maxHeight: 0,
            minTotalHeight: 0,
            maxTotalHeight: 0,
            maximalSurface: 0,
            leftArea: 0,
            rightArea: 0,
            depth: 0,
            ktm: "",
            ktmDescription: "",
            leading: []
        }
    ],
    drives: [{
        ktm: "",
        ktmDescription: ""
    }]
}

