import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {OfferListWrapper} from "../pages/offerList/OfferListWrapper";
import {ClientListWrapper} from "../pages/clientList/ClientListWrapper";
import {AccessGroupsWrapper} from '../pages/accessGroups/AccessGroupsWrapper'
import {AdminListWrapper} from "../pages/adminList/AdminListWrapper";
import {BasketListWrapper} from '../pages/basketList/BasketListWrapper'
import {ContentListWrapper} from "../pages/contentList/ContentListWrapper";
import {Components} from "../pages/components/Components";
import {StatisticsWrapper} from '../pages/statistics/Statistics'

import StructureKTM from "../pages/structureKTM/StructureKTM";
import ComponentCreate from "../pages/componentCreate/ComponentCreate";
import {GateCategory} from "../pages/components/gateCategory/GateCategoryWrapper";
import Sizes from "../pages/components/sizes/Sizes";
import {Drive} from "../pages/components/drive/Drive";
import {UserModel} from "../modules/auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../setup";
import {Accessories} from "../pages/accessories/Accessories";
import {AccessoriesCategories} from "../pages/accessoriesCategories/AccessoriesCategories";
import {PricesWrapper} from "../pages/newPrices/Prices";


const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);

    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/offers'/>}/>
                <Route path='/*' element={<Navigate to='/offers'/>}/>
                {/* Pages */}
                {/*<Route path='dashboard' element={<DashboardWrapper/>}/>*/}

                <Route
                    path='profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='/:type'
                    element={
                        <OfferListWrapper/>
                    }
                />
                <Route
                    path='client-list'
                    element={
                        <ClientListWrapper/>
                    }
                />
                <Route
                    path='access-group'
                    element={
                        <AccessGroupsWrapper/>
                    }
                />
                <Route
                    path='admin-list'
                    element={
                        <AdminListWrapper/>
                    }
                />
                <Route
                    path='size/:size'
                    element={
                        <Sizes/>
                    }
                />
                <Route
                    path='prices'
                    element={
                        <PricesWrapper/>
                    }
                />
                <Route
                    path='carts/abandoned'
                    element={
                        <BasketListWrapper/>
                    }
                />
                <Route
                    path='structure-ktm'
                    element={
                        <StructureKTM/>
                    }
                />
                <Route
                    path='content-list'
                    element={
                        <ContentListWrapper/>
                    }
                />
                <Route
                    path='component-create/:id'
                    element={
                        <ComponentCreate/>
                    }
                />
                <Route
                    path='gate-category/*'
                    element={
                        <SuspensedView>
                            <GateCategory/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='drive'
                    element={
                        <Drive/>
                    }
                />
                <Route
                    path='components/:id'
                    element={
                        <SuspensedView>
                            <Components/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='accessories/:type'
                    element={
                        <SuspensedView>
                            <Accessories/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='accessories-categories'
                    element={
                        <SuspensedView>
                            <AccessoriesCategories/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='statistics'
                    element={
                        <StatisticsWrapper/>
                    }
                />
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
