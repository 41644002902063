import {retry} from "@reduxjs/toolkit/query";

const _ = require('lodash');

export const getLeadingForDrive = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/components?isRelatedToDrive=true`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const getAllDrives = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drives`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    const data = await response.json();
    const groupedData = _.groupBy(data, "name")
    const allDrives = Object.entries(groupedData).map((item,i) => {
        const details = item[1]?.map(el => el)
        return {
            id: item[1][0].id,
            name: item[0],
            details
        }
    })
    return allDrives
}

export const getPaginatedDrivesGroups = async (accessToken, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drive_groups?page=${page}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    const data = await response.json();

    return data
}
export const getAllDrivesGroups = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drive_groups?pagination=false`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    const data = await response.json();

    return data
}

export const createNewDrive = async ({accessToken, data}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drives`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Minimalne wartości napędu muszą być większe od 0!")
    }
    return response.json()
}

export const createNewDriveGroup = async ({accessToken, data}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drive_groups`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    if (!response.ok) {
        throw new Error("Minimalne wartości napędu muszą być większe od 0!")
    }
    return response.json()
}
export const editDriveGroupById = async ({accessToken, data, driveId}: {accessToken: string, data: any, driveId : string}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drive_groups/${driveId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    return response.json()
}

export const editDriveById = async ({accessToken, data, driveId}: {accessToken: string, data: any, driveId : string}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drives/${driveId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    return response.json()
}
export const deleteDriveById = async ({accessToken, driveId}: {accessToken: string, data: any, driveId : string}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/drives/${driveId}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });
    return response.json()
}
