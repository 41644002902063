export const getAllAccessories = async (accessToken,url) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Coś poszło nie tak! Spróbuj ponownie!")
    }

    return response.json()
}

export const createAccessory = async ({accessToken, data, url}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const updateAccessoryById = async ({accessToken, data, url,id}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    return response.json()
}
