/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {FilterDropdown} from '../../component/dropdown/FilterDropdown'

import {IAdminDetails} from "./AdminAddModel";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {CircularProgress, createTheme, ThemeProvider} from "@mui/material";
import AccessoriesCategoriesTable from "./AccessoriesCategoriesTable";
import {fetchAllCategories, fetchAllCategoriesPaginated} from "./AccessoriesCategoriesFunctions";
import {AccessoriesCategoriesCreateForm} from "./AccessoriesCategoriesCreateForm";
import {PageTitle} from "../../../_metronic/layout/core";
import {Pagination} from "@mantine/core";


type Props = {
    className: string
}
export const theme = createTheme({
    breakpoints: {
        values: {
            xs: 300, // phone
            sm: 600, // tablets
            md: 900, // small laptop
            lg: 1200, // desktop
            xl: 1536 // large screens
        }
    }
});

export const style = {
    position: 'relative',
    margin: "1.75rem auto",
    maxWidth: {
        xs: "80%",
        md: "50%"
    },
    maxHeight: "100%",
    overflow: "auto",
    boxShadow: 24,
    backgroundColor: "#ffffff",
    border: "0 solid rgba(0, 0, 0, 0.2)",
    borderRadius: "0.475rem",
};

const AccessoriesCategories: React.FC<Props> = ({className}) => {
    const [chosenListItem, setChosenListItem] = useState<IAdminDetails | null>(null)
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [open, setOpen] = React.useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            const search = setTimeout(() => {
                setTriggerSearch(prevState => !prevState)
            }, 1200)

            return () => {
                clearTimeout(search)
            }
        } else {
            setTriggerSearch(prevState => !prevState)
        }
    }, [searchValue])


    const {
        data: accessoriesCategories,
        isLoading
    } = useQuery(["accessoriesCategories", triggerSearch, page], () => fetchAllCategoriesPaginated(accessToken, page))

    const totalPages = Math.ceil(accessoriesCategories?.totalItems / 30);
    return (
        <>
            <PageTitle>Akcesoria - kategorie</PageTitle>
            <div className={`card ${className}`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5 d-flex flex-nowrap gap-5'>
                    <div className="d-flex align-items-center position-relative my-1">
                    </div>
                    <div
                        className='card-toolbar'
                        title='Kliknij, aby dodać admina'
                    >
                        <button
                            className='btn btn-sm btn-light-primary'
                            onClick={() => setOpen(!open)}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                            <span className="d-none d-sm-inline">Dodaj</span>
                        </button>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                    {/* begin::Table container */}
                    {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                    {accessoriesCategories && accessoriesCategories?.data?.length > 0 &&
                        <AccessoriesCategoriesTable
                            accessoriesCategories={accessoriesCategories}
                            setChosenListItem={setChosenListItem}
                            chosenListItem={chosenListItem}
                            open={open}
                            setOpen={setOpen}
                            url={"admins"}
                        />}
                    {/* end::Table container */}
                    {totalPages > 1 && <Pagination total={totalPages} page={page} onChange={setPage}
                                                   style={{justifyContent: "flex-end"}}/>}
                </div>
                {/* begin::Body */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <ThemeProvider theme={theme}>
                        <Box sx={style}>
                            <div>
                                <div>
                                    <div className='modal-header pb-0 border-0 justify-content-end'>
                                        <div
                                            onClick={() => setOpen(!open)}
                                            className='btn btn-sm btn-icon btn-active-color-primary'>
                                            <KTSVG path='/media/icons/duotune/arrows/arr061.svg'
                                                   className='svg-icon-1'/>
                                        </div>
                                    </div>

                                    <div className='modal-body scroll-y'>
                                        <div className='text-center mb-13'>
                                            <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} kategorię</h1>
                                        </div>
                                        <AccessoriesCategoriesCreateForm
                                            accessoriesCategories={accessoriesCategories}
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open}
                                            setOpen={setOpen}/>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </ThemeProvider>
                </Modal>
            </div>
        </>
    )
}

export {AccessoriesCategories}
