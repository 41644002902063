import {FieldProps} from "formik";
import React, {useEffect, useMemo, useState} from "react";
import Select from "react-select";
import {OptionsType, ValueType} from "react-select/lib/types";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

interface Option {
    label: string;
    value: string;
}

interface CustomSelectProps extends FieldProps {
    options: OptionsType<Option>;
    isMulti?: boolean;
    className?: string;
    placeholder?: string;
}

const customStyles: StylesConfig = {
    control: () => ({
        // none of react-select's styles are passed to <Control />
        backgroundColor: "#F5F8FA",
        display: "flex",
        borderColor: "#F5F8FA",
        fontWeight: 500,
        transition: "color 0.2s ease, background-color 0.2s ease",

        fontSize: 12,
        borderRadius: "0.475rem",
        fontFamily: `Poppins, Helvetica, "sans-serif"`,
        color: "#A1A5B7",
        padding: "0.425rem 1rem"
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#A1A5B7",
        }
    },
    singleValue: (s) => ({
        ...s,
        color: "#A1A5B7",
    })
}

export const CustomSelect = ({
                                 className,
                                 placeholder,
                                 field,
                                 form,
                                 options,
                                 isMulti = false
                             }: CustomSelectProps) => {
    const onChange = (option: ValueType<Option | Option[]>) => {
        form.setFieldValue(
            field.name,
            isMulti
                ? (option as Option[]).map((item: Option) => {
                    return{id: item?.value, name: item.label}})
                : (option as Option).value
        );
    };

    const getValue = () => {
        if (options) {
            return isMulti
                // ? options.filter(option => field?.value?.indexOf(option.value) >= 0)
                ? options.filter((opt: { value: string }) => field?.value?.some((f: { id: string }) => f.id === opt.value))
                : options.find((option: { value: string }) =>{
                    return  option.value === field.value || option.value === field?.value?.id});
        } else {
            return isMulti ? [] : ("" as any);
        }
    };

    return (
        <Select
            styles={customStyles}
            className={className}
            name={field.name}
            value={getValue()}
            onChange={onChange}
            placeholder={placeholder}
            options={options}
            isMulti={isMulti}
        />
    );
};

export default CustomSelect;
