export interface ISizesDetails {
    value: number,
    ktm: string,
    ktmDescription: string,
    gateCategory: string[]
}


export const sizesInitValues: ISizesDetails = {
    value: null,
    gateCategory: null,
    ktm: "",
    ktmDescription : ""
}

