import React, {FC} from 'react';
import SubMenu from "../../component/subMenu/SubMenu";
import CustomChangePosition from "../../component/customChangePosition/customChangePosition";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useQuery} from "react-query";
import {fetchAllCategories, fetchAllCategoriesPaginated} from "./AccessoriesCategoriesFunctions";

const AccessoriesCategoriesTable: FC<{adminList: [], }> = (props) => {
    const {accessoriesCategories,setChosenListItem,chosenListItem,open,setOpen,url} = props;

    const {
        data: allAccessoriesCategories,
        isLoading
    } = useQuery(["allAccessoriesCategories"], () => fetchAllCategories(accessToken))

    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    return (
        <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                <tr className='fw-bolder text-muted'>
                    <th>Pozycja</th>
                    <th>Nazwa</th>
                    <th>Akcje</th>
                </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {accessoriesCategories && accessoriesCategories?.data?.length > 0 && accessoriesCategories?.data?.map(cat => (
                    <tr key={cat.id}>
                        <td>
                            <CustomChangePosition item={cat} orderKey={"categoryOrder"} url={"accessory_categories"} accessToken={accessToken} allItems={allAccessoriesCategories} queryKey={"accessoriesCategories"} queryKey2={"allAccessoriesCategories"}/>
                        </td>
                        <td>{cat.name}</td>
                        <td>
                            <SubMenu item={cat} setChosenListItem={setChosenListItem} chosenListItem={chosenListItem}
                                     modalOpen={open} setOpen={setOpen} url="accessory_categories"/>
                        </td>
                    </tr>
                ))}

                </tbody>
                {/* end::Table body */}
            </table>
            {/* end::Table */}
        </div>
    );
};

export default AccessoriesCategoriesTable;
