import React, {FC, useEffect, useRef, useState} from 'react'
import {KTSVG} from './../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Formik, Form, FormikValues} from 'formik'
import {ICreateComponent, createComponentSchemas, inits} from './CreateComponentWizardHelper'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createComponent, findComponentById, getAllComponents, updateComponentById} from "./ComponentsCreateFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import * as Yup from 'yup'
import {useParams} from "react-router-dom";
import {componentItemsInitValues as initialValues, IComponentItems} from "../components/ComponentAddModel";

const ComponentCreateSchema = Yup.object().shape({
    name: Yup.string().required('Pole "Nazwa" jest wymagane'),
})

const VerticalCreate: FC = () => {
    const [currentStep, setCurrentStep] = useState(1)
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [formValues, setFromValues] = useState(null)
    const client = useQueryClient();
    const params = useParams();
    const [initValues, setInitValues] = useState<ICreateComponent>(inits);
    const {data: chosenComponent} = useQuery(["chosenComponent", params.id], () => findComponentById(accessToken, params.id));
    const createComponentMutation = useMutation(createComponent, {
        onSuccess: () => {
            client.invalidateQueries("components")
        }
    })

    const updateComponentMutation = useMutation(updateComponentById, {
        onSuccess: () => {
            client.invalidateQueries("components")
        }
    })

    const chosenComponentInitialValues = chosenComponent?.relatedComponents.map(comp => comp.id + "_" + comp.name)

    useEffect(() => {
        if (chosenComponent) {
            setInitValues({
                ...chosenComponent,
                relatedComponents: chosenComponentInitialValues
            })
        } else {
            setInitValues(inits)
        }
    }, [chosenComponent, params])

    const prevStep = () => {
        if (currentStep !== 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const submitStep = (values: ICreateComponent, actions: FormikValues) => {
        if (currentStep !== 3) {
            setFromValues(values)
            setCurrentStep(currentStep + 1)
        } else if (currentStep === 3) {
            try {
                if (chosenComponent) {
                    updateComponentMutation.mutate({
                        accessToken,
                        data: {
                            name: values.name,
                            gateTypes: values?.gateTypes?.map(gate => gate.id),
                            relatedComponents: values?.relatedComponents.map(comp => comp.split("_")[0])
                        },
                        id: chosenComponent.id
                    })
                } else {
                    createComponentMutation.mutate({
                        accessToken,
                        data: {
                            name: values.name,
                            gateTypes: values?.gateTypes?.map(gate => gate.id),
                            relatedComponents: values?.relatedComponents.map(comp => comp.split("_")[0])
                        }
                    })
                }
                actions.resetForm()
                setInitValues(inits)
                setCurrentStep(1)
            } catch (e) {
                console.error(e)
            }
        }
    }

    return (
        <div className='stepper stepper-pills stepper-column   d-flex flex-column flex-xl-row flex-row-fluid'>
            <div
                className='d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
                <div className='px-6 px-lg-10 px-xxl-15 py-20'>
                    <div className='stepper-nav'>
                        <div className={`stepper-item ${currentStep === 1 ? "current" : ""}`}>
                            <div className='stepper-line w-40px'/>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>1</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Nazwa komponentu</h3>

                                <div className='stepper-desc fw-bold'>Wybierz nazwę dla kompnentu</div>
                            </div>
                        </div>

                        <div className={`stepper-item ${currentStep === 2 ? "current" : ""}`}>
                            <div className='stepper-line w-40px'/>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>2</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Tworzenie kolumn</h3>
                                <div className='stepper-desc fw-bold'>Wybierz jakie kolumny ma zawierać komponent</div>
                            </div>
                        </div>

                        <div className={`stepper-item ${currentStep === 3 ? "current" : ""}`}>
                            <div className='stepper-line w-40px'/>

                            <div className='stepper-icon w-40px h-40px'>
                                <i className='stepper-check fas fa-check'></i>
                                <span className='stepper-number'>3</span>
                            </div>

                            <div className='stepper-label'>
                                <h3 className='stepper-title'>Podsumowanie</h3>
                                <div className='stepper-desc fw-bold'>Składowe komponentu</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                <Formik initialValues={initValues} onSubmit={submitStep} validationSchema={ComponentCreateSchema}
                        enableReinitialize={true}>
                    {({errors, values}) => {
                        return (
                            <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_component_form'>
                                <div className='current'>
                                    {currentStep === 1 && <Step1 name={values.name}/>}
                                </div>

                                <div>
                                    {currentStep === 2 && <Step2/>}
                                </div>

                                <div>
                                    {currentStep === 3 && <Step3 formValues={formValues}/>}
                                </div>

                                <div className='d-flex flex-stack pt-10'>
                                    {currentStep > 1 && <div className='mr-2'>
                                        <button
                                            onClick={prevStep}
                                            type='button'
                                            className='btn btn-lg btn-light-primary me-3'
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr063.svg'
                                                className='svg-icon-4 me-1'
                                            />
                                            Wstecz
                                        </button>
                                    </div>}

                                    <div>
                                        <button
                                            type='submit'
                                            className='btn btn-lg btn-primary me-3'
                                            disabled={(currentStep === 1 && values?.name.trim().length === 0) || (currentStep === 2 && values?.gateTypes?.length === 0)}
                                        >
                                        <span className='indicator-label'>
                                            {currentStep !== 3 && 'Następny'}
                                            {currentStep === 3 && 'Zapisz'}
                                            <KTSVG
                                                path='/media/icons/duotune/arrows/arr064.svg'
                                                className='svg-icon-3 ms-2 me-0'
                                            />
                                        </span>
                                        </button>
                                    </div>
                                </div>
                            </Form>)
                    }}
                </Formik>
            </div>
        </div>
    )
}

export {VerticalCreate}
