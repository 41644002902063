import React, {FC, useState} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {getAllSizes, theadTitleChanger} from "./SizesFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {style, theme} from "../../adminList/Admin";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {SizesCreateForm} from "./SizesCreateForm";
import {IDriveDetails} from "../drive/DriveModel";
import SubMenu from "../../../component/subMenu/SubMenu";
import CustomMassiveActions from "./CustomMassiveActions";

const Sizes: FC = () => {
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })

    const params = useParams();
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const [chosenListItem, setChosenListItem] = useState<IDriveDetails | null>(null);
    const {
        data: sizes,
        isError,
        isLoading
    } = useQuery(["sizes", params.size], () => getAllSizes(accessToken, params.size));
    const [chosenComponents, setChosenComponents] = useState([])


    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const handleOnChange = (e: any) => {
        if (e.target.checked && e.target.value !== "all") {
            setChosenComponents(prev => [...prev, e.target.value])
        } else if (e.target.checked && e.target.value === "all") {
            setChosenComponents(sizes?.map(i => i.id))
        } else if (e.target.checked === false && e.target.value === "all") {
            setChosenComponents([])
        } else {
            setChosenComponents(prev => prev.filter(item => item !== e.target.value))
        }
    }

    const tHeadTitle = theadTitleChanger(params)

    return (
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            {/* begin::Body */}
            <CustomMassiveActions isChecked={isChecked} open={open} setOpen={setOpen} chosenComponent={chosenComponents}
                                  setChosenComponents={setChosenComponents}/>
            <div className='card-body py-3'>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                    {isError && !isLoading && <p>Coś poszło nie tak...</p>}
                    {sizes?.length === 0 && !isLoading && <p>Brak elementów do wyświetlenia...</p>}
                    {sizes?.length > 0 &&
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            {/* begin::Table head */}
                            <thead>
                            <tr className='fw-bolder text-muted'>
                                <th>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                        <input
                                            className='form-check-input'
                                            type='checkbox'
                                            checked={chosenComponents.length > 0}
                                            onChange={handleOnChange}
                                            value="all"
                                        />
                                    </div>
                                </th>
                                <th>{tHeadTitle} w mm</th>
                                <th>Kod KTM</th>
                                <th>Opis skrócony</th>
                                {params.size === "heights" && <th>Ilość paneli</th>}
                                {params.size !== "lintels" && <th>Rodzje bram</th>}
                                <th>Akcje</th>
                            </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                            {sizes?.length > 0 && sizes.sort((a, b) => a.value - b.value).map(size => {
                                return <tr>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                onChange={handleOnChange}
                                                checked={chosenComponents.includes(size.id)}
                                                className='form-check-input widget-9-check'
                                                type='checkbox'
                                                value={size.id}
                                            />
                                        </div>
                                    </td>
                                    <td>{size.value}</td>
                                    <td>{size.ktm}</td>
                                    <td>{size.ktmDescription || "-"}</td>
                                    {params.size === "heights" && <td>{size.panelsAmount || "-"}</td>}
                                    {params.size !== "lintels" && <td>
                                        <div className="d-flex flex-column gap-5 flex-wrap">
                                            {size?.gateTypes.map(value => {
                                                return (<div
                                                    className='badge badge-light-primary fs-7 fw-bold'>{value.name}</div>)
                                            })}
                                        </div>
                                    </td>}
                                    <td>
                                        <SubMenu
                                            item={size}
                                            setChosenListItem={setChosenListItem}
                                            chosenListItem={chosenListItem}
                                            modalOpen={open}
                                            setOpen={setOpen}
                                            url={params.size}/>
                                    </td>
                                </tr>
                            })}
                            </tbody>
                            {/* end::Table body */}
                        </table>}
                    {/* end::Table */}
                </div>
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpen(!open)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} {tHeadTitle}</h1>
                                    </div>
                                    <SizesCreateForm
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        params={params}
                                        setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>

        </div>
    );
};

export default Sizes;
