export interface IComponentItems {
    name: string,
    image: string,
    gateTypes: string[],
    price: number,
    isCustomPosition: boolean,
    priceType: string,
    perMeter?: string,

}


export const componentItemsInitValues: IComponentItems = {
    name: "",
    image: "",
    gateTypes: [],
    price: null,
    priceType: "",
    perMeter: undefined,
    isCustomPosition: false
}

