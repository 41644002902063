import React, {FC} from 'react'
import {KTSVG} from './../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import CustomSelect from "../../../component/customSelect/CustomSelect";
import {useQuery} from "react-query";
import {getAllGateTypes} from "../../components/gateCategory/GateCategoryFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {getAllComponents} from "../ComponentsCreateFunctions";
import {useParams} from "react-router-dom";

const Step2: FC = () => {
	const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
	const {data: gateTypes} = useQuery("gate_types", () => getAllGateTypes(accessToken));
	const {data: components} = useQuery("components", () => getAllComponents(accessToken), {
		refetchOnWindowFocus: false,
	});
	const params = useParams();

	const options = gateTypes?.map((gate: { id: string, name: string }) => {
		return {value: gate.id, label: gate.name}
	})

    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-15">
                <h2 className="fw-bolder text-dark">Tworzenie kolumn</h2>
                <div className="text-muted fw-bold fs-6">Wybierz jakie kolumny ma zawierać
                    komponent
                </div>
            </div>

            <div className="mb-0 fv-row">

                <label className="d-flex align-items-center form-label mb-5">Wybierz jakie
                    kolumny ma zawierać komponent
                    <i className="fas fa-exclamation-circle ms-2 fs-7"
                       data-bs-toggle="tooltip"
                       title="Monthly billing will be based on your account plan"></i></label>


                <div className="mb-0">
					{components && components.length > 0 && components?.filter(comp => comp.id !== params.id).map((component, index) => {
						return (
							<label className="d-flex flex-stack mb-5 cursor-pointer">
								<span className="d-flex align-items-center me-2">
									<span className="d-flex flex-column">
										<span className="fw-bolder text-gray-800 text-hover-primary fs-5">{component.name}</span>
						  			</span>
								</span>
								<span className="form-check form-check-custom form-check-solid">
							 		<Field
										className='form-check-input'
										type='checkbox'
								 		name={`relatedComponents`}
										value={component.id+"_"+component.name}
								 		id={`kt_create_component_form_${component.id}`}
							 		/>
								</span>
							</label>
						)
					})}

                    <label className="d-flex flex-stack mb-5 cursor-pointer">
						<span className="d-flex align-items-center me-2">
							<span className="d-flex flex-column">
								<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Rodzaj bram</span>
								<Field
									className="custom-select"
									name="gateTypes"
									options={options}
									component={CustomSelect}
									placeholder="Wybierz rodzaje bramy..."
									isMulti={true}
								/>
							</span>
						</span>
                    </label>

                </div>
            </div>
        </div>
    )
}

export {Step2}
