export interface IComponentItems {
    name: string,
    image: string,
    gateTypes: string[],
    price: number,
    ktm: string,
    ktmDescription: string,
    priceType: string,
    perMeter?: string,
}


export const componentItemsInitValues: IComponentItems = {
    name: "",
    image: "",
    gateTypes: [],
    price: null,
    ktm: "",
    ktmDescription: "",
    priceType: "",
    perMeter: undefined,
}

