/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {FilterDropdown} from '../../component/dropdown/FilterDropdown'
import {AdminCreate} from "../adminList/AdminCreate";
import {ContentEdit} from "./ContentEdit";
import {IContentDetails} from "./ConentEditModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useQuery} from "react-query";
import SubMenu from "../../component/subMenu/SubMenu";
import Box from "@mui/material/Box";
import {AccessGroupForm} from "../accessGroups/AccessGroupCreateModalForm";
import Modal from "@mui/material/Modal";
import {ContentEditForm} from "./ContentEditForm";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {style, theme} from "../adminList/Admin";

type Props = {
    className: string
}

const ContentTable: React.FC<Props> = ({className}) => {
    const [chosenListItem, setChosenListItem] = useState<IContentDetails | null>(null);
    const [open, setOpen] = useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);

    const [searchValue, setSearchValue] = useState("");
    const [triggerSearch, setTriggerSearch] = useState(false);

    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    useEffect(() => {
        if (searchValue.trim().length > 0) {
            const search = setTimeout(() => {
                setTriggerSearch(prevState => !prevState)
            }, 1200)

            return () => {
                clearTimeout(search)
            }
        } else {
            setTriggerSearch(prevState => !prevState)
        }
    }, [searchValue])

    const fetchContent = async (url) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}api/contents${url}?itemsPerPage=50`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${accessToken}`
            }
        })
        return response.json();
    };

    const {data: contentList, isLoading, isError} = useQuery(["contents",triggerSearch], () =>fetchContent(searchValue.trim().length > 0 ? "?simplesearch=" + searchValue : ""))

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 pt-5'>
                <div className="d-flex align-items-center position-relative my-1">
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546"
                                  height="2" rx="1" transform="rotate(45 17.0365 15.1223)"
                                  fill="black"/>
                            <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"/>
                        </svg>
                    </span>
                    <input type="text"
                           value={searchValue}
                           onChange={(e) => setSearchValue(e.target.value)}
                           className="form-control form-control-solid ps-14"
                           placeholder="Szukaj..."/>
                </div>
                <div
                    className='card-toolbar'
                    title='Kliknij, aby dodać admina'
                >
                    {/*<button*/}
                    {/*    className='btn btn-sm btn-light-primary'*/}
                    {/*    onClick={() => setOpen(!open)}*/}
                    {/*>*/}
                    {/*    <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>*/}
                    {/*    <span className="d-none d-sm-inline">Dodaj</span>*/}
                    {/*</button>*/}
                </div>
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {isError && contentList?.length === 0 && <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {/* begin::Table container */}
                {!isError && !isLoading && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>Nazwa pola</th>
                            <th>Treść</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {contentList?.length > 0 && contentList.map(content => (
                            <tr>
                                <td>{content.slug}</td>
                                <td style={{maxWidth: 400}}>{content.content}</td>
                                <td>
                                    <SubMenu
                                        item={content}
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open}
                                        setOpen={setOpen}
                                        url={"contents"}/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>}
                {/* end::Table container */}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                <Box sx={style}>
                    <div>
                        <div>
                            <div className='modal-header pb-0 border-0 justify-content-end'>
                                <div
                                    onClick={() => setOpen(!open)}
                                    className='btn btn-sm btn-icon btn-active-color-primary'>
                                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                </div>
                            </div>

                            <div className='modal-body scroll-y'>
                                <div className='text-center mb-13'>
                                    <h1 className='mb-3'>Edytuj treści stałe</h1>
                                </div>
                                <ContentEditForm
                                    setChosenListItem={setChosenListItem}
                                    chosenListItem={chosenListItem}
                                    modalOpen={open} setOpen={setOpen}/>
                            </div>
                        </div>
                    </div>
                </Box>
                </ThemeProvider>
            </Modal>
        </div>
    )
}

export {ContentTable}
