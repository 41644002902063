export const fetchAllClients = async (accessToken, url) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data = await response.json()

    return data;
}

export const createNewClient = async ({data, accessToken}: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const updateClientById = async ({data, accessToken, clientId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients/${clientId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const verifyClient = async ({data, accessToken, clientId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients/${clientId}/verify`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się zweryfikować klienta")
    }
}

export const changeStatusClient = async ({data, accessToken, clientId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients/${clientId}/change_active_status`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    if (!response.ok) {
        throw new Error("Nie udało się dezaktywować klienta")
    }
}

export const changeClientToDistributor = async ({data, accessToken, clientId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients/${clientId}/set_distributor`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const blockItem = async ({data, accessToken, id, url}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/${url}/${id}/change_visibility`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })

    return response.json()
}

export const deactivateClient = async ({data, accessToken, clientId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/clients/${clientId}/verify`, {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/merge-patch+json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}
