export interface IGateCategory {
    name: string,
    image: string,
    ktm: string,
    ktmDescription: string,
    drives: { name: string, id: string }[],
}


export const leadingInitValues: IGateCategory = {
    name: "",
    image: "",
    ktm: "",
    ktmDescription: "",
    drives: [],
}

