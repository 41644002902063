export const fetchAllCategoriesPaginated = async (accessToken, page) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/accessory_categories?page=${page}&itemsPerPage=30`, {
        method: "GET",
        headers: {
            'Accept': 'application/ld+json',
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data =await  response.json()

    return {
        data: data['hydra:member'],
        totalItems: data['hydra:totalItems']
    }
}
export const fetchAllCategories = async (accessToken) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/accessory_categories?pagination=false`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data =await  response.json()

    return data
}
