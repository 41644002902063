import React, {FC, useEffect, useState} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import Select from "react-select";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import {updateComponentItemById} from "../../componentCreate/ComponentsCreateFunctions";
import {deleteComponentById} from "../ComponentsFunctions";
import {deleteModalStyle} from "../../../component/subMenu/SubMenu";

import {getAllDrives, getAllDrivesGroups} from "../drive/DriveFunctions";
import {getAllSizes, updateSizeById} from "../sizes/SizesFunctions";
import {getAllGateTypes} from "../gateCategory/GateCategoryFunctions";
import {useParams} from "react-router-dom";

const customStyles: StylesConfig = {
    control: () => ({
        // none of react-select's styles are passed to <Control />
        backgroundColor: "transparent",
        display: "flex",
        borderColor: "#F5F8FA",
        fontWeight: 500,
        transition: "color 0.2s ease, background-color 0.2s ease",
        border: "1px solid #E4E6EF",
        fontSize: "1.1rem",
        borderRadius: "0.475rem",
        fontFamily: `Poppins, Helvetica, "sans-serif"`,
        color: "#5E6278",
        padding: "0.225rem 1rem"
    }),
    indicatorSeparator: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: "none"
        }
    },
    indicatorsContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#9c9c9c"
        }
    },
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: "#5E6278",
        }
    },
    singleValue: (s) => ({
        ...s,
        color: "#5E6278",
        borderRight: "none"
    })
}

const CustomMassiveActions: FC<{ isChecked: { first: boolean, all: boolean }, }> = (props) => {
        const {isChecked, open, setOpen, chosenComponent, setChosenComponents} = props;
        const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
        const [globalActionType, setGlobalActionType] = useState(undefined);
        const [finalStep, setFinalStep] = useState(false)
        const [priceChangeType, setPriceChangeType] = useState(undefined);
        const [gateType, setGateType] = useState([]);
        const [gateCategory, setGateCategory] = useState(undefined);
        const [chosenRelatedComponentItems, setChosenRelatedComponentItem] = useState([]);
        const [value, setValue] = useState(null)
        const client = useQueryClient();
        const {data: gateTypes, isLoading} = useQuery("gate_types", () => getAllGateTypes(accessToken));
        const editGaeTypeById = []
        const params = useParams();

        const driveOptions = gateTypes?.map((group: { id: string, name: string }) => {
            return {value: group?.id, label: group?.name}
        })

        const deleteGateById = async ({accessToken, id}: { accessToken: string, id: string }) => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}api/${params.size}/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`
                }
            })

            if (!response.ok) {
                throw new Error("Nie udało się usunąć komponentu!")
            }
        };

        const deleteSizeMutation = useMutation(deleteGateById, {
            onSuccess: () => {
                setChosenComponents([])
                setGlobalActionType(undefined)
                setGateType([])
                setPriceChangeType(undefined)
                setFinalStep(false)
                setValue(null)
                client.invalidateQueries("sizes")
            }
        })

        const editGateTypeMutation = useMutation(updateSizeById, {
            onSuccess: () => {
                setChosenComponents([])
                setGlobalActionType(undefined)
                setGateType([])
                setPriceChangeType(undefined)
                setFinalStep(false)
                setValue(null)
                client.invalidateQueries("sizes")
            },
            onError: () => {
                setTimeout(() => {
                    setChosenComponents([])
                    setGlobalActionType(undefined)
                    setGateType([])
                    setPriceChangeType(undefined)
                    setFinalStep(false)
                    setValue(null)
                }, 2000)
            }
        })


        const handleInputChange = (e: any, toChange: any) => {
            toChange(e)
        }

        const handleMassiveActions = () => {
            if (globalActionType === "drive-change") {
                chosenComponent.forEach(element => {
                    editGateTypeMutation.mutate({
                        accessToken,
                        data: {
                            gateTypes: gateType
                        },
                        path: params.size,
                        id: element
                    })
                })
            }
        }

        return (
            <div className='card-header border-0 pt-5'>
                <div className="d-flex align-items-center">
                    <div className="w-200px me-5">
                        {chosenComponent?.length > 0 && <select id="global-action"
                                                                name="global-action"
                                                                className="form-select w-200px me-5"
                                                                value={globalActionType}
                                                                onChange={e => handleInputChange(e.target.value, setGlobalActionType)}
                        >
                            <option>Działania masowe...</option>
                            <option value="drive-change">Zmień rodzaj bramy...</option>
                        </select>}
                    </div>

                    {chosenComponent?.length > 0 && globalActionType === "drive-change" && <div>
                        <Select
                            styles={customStyles}
                            className="custom-select"
                            name={"gate-change"}
                            onChange={(e) => {
                                setFinalStep(true)
                                setGateType(e.map(it => it.value))
                            }}
                            placeholder={"Wybierz napęd..."}
                            options={driveOptions}
                            isMulti
                        />

                    </div>
                    }

                    {finalStep && chosenComponent.length > 0 && <button
                        className='btn  btn-light-primary mx-5'
                        onClick={() => {
                            handleMassiveActions()
                            // if (chosenComponent?.length > 0) {
                            //     chosenComponent.forEach(el => {
                            //         deleteComponentMutation.mutate({
                            //             accessToken,
                            //             id: el
                            //         })
                            //     })
                            // }
                        }
                        }
                    >
                        Zapisz
                    </button>}
                    {/*{componentItemUpdateMutation.isError && chosenComponents.length > 0 &&*/}
                    {/*<p style={{marginBottom: 0, color: "red", fontWeight: 500, fontSize: 14}}>Coś poszło nie tak! Spróbuj*/}
                    {/*    ponownie!</p>}*/}
                </div>
                <div
                    className='card-toolbar ms-auto'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Kliknij, aby dodać element'
                >
                    {chosenComponent?.length === 0 ? <button
                            type="button"
                            className='btn btn-sm btn-light-primary'
                            onClick={() => setOpen(prev => !prev)}
                        >
                            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                            Dodaj
                        </button> :
                        <>
                            <div className="fw-bolder me-5">Wybrano:
                                <span className="me-2"> {chosenComponent?.length}</span>
                            </div>
                            <button
                                className='btn btn-sm btn-light-danger'
                                onClick={() => {
                                    if (chosenComponent?.length > 0) {
                                        chosenComponent?.forEach(el => {
                                            deleteSizeMutation.mutate({
                                                accessToken,
                                                id: el
                                            }, {
                                                onSuccess: () => {
                                                    setTimeout(() => {
                                                        deleteSizeMutation.reset()
                                                    }, 3000)
                                                }
                                            })
                                        })
                                    }
                                }
                                }
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                                Usuń zaznaczone
                            </button>
                        </>}
                </div>
                {deleteSizeMutation.isSuccess && <div style={deleteModalStyle}>
                    Element został usunięty!
                </div>}
            </div>
        );
    }
;

export default CustomMassiveActions;