export const fetchAllAdminGroups = async (accessToken, url) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/groups${url}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
    const data = await response.json();
    return data;
}

export const createNewAdminGroup = async (accessToken:string, data: {}) => {
    const response =  await fetch(`${process.env.REACT_APP_API_URL}api/groups`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const updateAdminGroupById = async (adminGroupId: string , data: {}, accessToken: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/groups/${adminGroupId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}