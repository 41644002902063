import React from 'react';
import Button from "@mui/material/Button";
import {KTSVG} from "../../../_metronic/helpers";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {getPdf, printPdf} from "./OfferListFunctions";
import {UserModel} from "../../modules/auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useQuery} from "react-query";
import {fetchAllAdminGroups} from "../accessGroups/AccessGroupFunctions";
import {useParams} from "react-router-dom";

const SubMenu = ({order, setChosenListItem, chosenListItem, handleOpenPreview, setOpenStatusChange, setOpenCancel,setOpenDelete}) => {
    const params = useParams();
    const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;
    const {data: adminGroups} = useQuery(["groups"], () => fetchAllAdminGroups(accessToken, ""));
    const userGroup = adminGroups?.find(e => e.id === user.userGroupId);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            {/* begin::Menu */}
            <Button
                id={`basic-button${order.id}`}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={(e) => {
                    setChosenListItem(order)
                    handleClick(e)
                }}
            >
                <KTSVG path='/media/icons/duotune/general/gen024.svg'
                       className='svg-icon-2'/>
            </Button>
            <Menu
                id={`basic-menu${order.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    boxShadow: 0
                }}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    handleOpenPreview()
                }} className="w-100">
                    <span className="w-100 text-center">Podgląd</span>
                </MenuItem>
                {params.type === 'orders' && <MenuItem onClick={() => {
                    handleClose()
                    setOpenStatusChange(true)
                }}><span className="w-100 text-center">Zmień status</span>
                </MenuItem>}
                <MenuItem onClick={() => {
                    handleClose()
                    printPdf(chosenListItem.id, accessToken, order.internalNumber, params.type)
                }}><span
                    className="w-100 text-center">Drukuj</span></MenuItem>
                {userGroup?.isOrderPreviewPDFAccess &&
                    <MenuItem onClick={() => {
                        handleClose()
                        getPdf(chosenListItem?.id, accessToken, chosenListItem.internalNumber, params.type)
                    }}><span
                        className="w-100 text-center">Pobierz PDF</span></MenuItem>}
                <MenuItem onClick={() => {
                    setOpenCancel(true)
                }
                }>
                    <button className='btn btn-danger btn-sm px-4 w-100'>
                        Anuluj {params.type === 'offers' ? 'ofertę' : 'zamówienie'}
                    </button>
                </MenuItem>
                <MenuItem onClick={() => {
                    setOpenDelete(true)
                }
                }>
                    <button className='btn btn-danger btn-sm px-4 w-100'>
                        Usuń {params.type === 'offers' ? 'ofertę' : 'zamówienie'}
                    </button>
                </MenuItem>
            </Menu>
            {/* end::Menu */}
        </div>
    );
};

export default SubMenu;
