import React from 'react';
import {KTSVG} from "../../../_metronic/helpers";
import {OfferPreview} from "./OfferPreview";
import {useQuery} from "react-query";
import {getOffersById} from "./OfferListFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useParams} from "react-router-dom";

const OpenOfferPreview = ({offerId, closePreview, chosenListItem}) => {
    const params = useParams();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const {data: offer, isLoading} = useQuery(['offerById', offerId], () => getOffersById(accessToken, offerId, params.type === 'offers' ? 'orders' : 'offers'))
    return (
        <div>
            <div>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div
                        onClick={() => closePreview()}
                        className='btn btn-sm btn-icon btn-active-color-primary'>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                    </div>
                </div>

                <div className='modal-body scroll-y'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{params.type === 'offers' ? 'Podgląd zamówienia' : 'Podgląd oferty'}</h1>
                    </div>
                    <OfferPreview chosenOffer={offer} isLoading={isLoading} chosenListItem={chosenListItem}/>
                </div>
            </div>
        </div>
    );
};

export default OpenOfferPreview;
