import * as Yup from 'yup'

export interface ICreateComponent {
    name: string,
    gateType: [],
    relatedComponents: []
}

const createComponentSchemas = [
    Yup.object({
        name: Yup.string().required().label('Name'),
    }),
]

const inits: ICreateComponent = {
    name: '',
    gateType: [],
    relatedComponents: []
}

export {createComponentSchemas, inits}
