export const createNewContent = async ({accessToken, data}) => {
    const response =  await fetch(`${process.env.REACT_APP_API_URL}api/contents`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}

export const updateContentById = async ({accessToken, data, contentId}) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/contents/${contentId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    })
}