import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Vertical} from './components/Vertical'
import {Horizontal} from './components/Horizontal'
import { VerticalCreate } from './VerticalCreate'

const ComponentCreate = () => (
    <VerticalCreate/>
)

export default ComponentCreate
