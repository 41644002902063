import React, {useEffect, useState} from 'react'
import {ISizesDetails, sizesInitValues as initialValues} from './SizesAddModel';
import * as Yup from 'yup'
import {Field, FormikProvider, useFormik} from 'formik'
import {useMutation, useQuery, useQueryClient} from "react-query";
import {createSize, getAllSizes, theadTitleChanger, updateSizeById} from "./SizesFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {useParams} from "react-router-dom";
import CustomSelect from "../../../component/customSelect/CustomSelect";
import {getAllGateTypes} from "../gateCategory/GateCategoryFunctions";

const sizesSchema = Yup.object().shape({
    gateTypes: Yup.array().required('To pole jest wymagane'),
    value: Yup.string().required('To pole jest wymagane').nullable(),
    ktm: Yup.string().required('To pole jest wymagane').nullable(),
})

const lintelSchema = Yup.object().shape({
    value: Yup.number().required('To pole jest wymagane').nullable(),
    ktm: Yup.string().required('To pole jest wymagane').nullable(),
})

const SizesCreateForm: React.FC = (props) => {
    const {params, chosenListItem, setOpen, setChosenListItem} = props;
    const [initValues, setInitValues] = useState<ISizesDetails>(initialValues);
    const client = useQueryClient();
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const chosenSizeTitle = theadTitleChanger(params)
    const createSizeMutation = useMutation(createSize, {
        onSuccess: () => {
            client.invalidateQueries('sizes')
        }
    })
    const updateSizeMutation = useMutation(updateSizeById, {
        onSuccess: () => {
            client.invalidateQueries('sizes')
        }
    })
    const {data: gateTypes, isLoading} = useQuery("gate_types", () => getAllGateTypes(accessToken));
    const [error, setError] = useState(null)

    const optionsGates = gateTypes?.map((group: { id: string, name: string }) => {
        return {value: group?.id, label: group?.name}
    })


    useEffect(() => {
        if (chosenListItem) {
            setInitValues(chosenListItem)
        } else {
            setInitValues(initialValues)
        }
    }, [chosenListItem])

    const formik = useFormik<ISizesDetails>({
        enableReinitialize: true,
        initialValues: initValues,
        validationSchema: params.size !== "lintels" ? sizesSchema : lintelSchema,
        onSubmit: (values) => {
            const options = {
                onSuccess: () => {
                    setTimeout(() => {
                        formik.resetForm()
                        setChosenListItem(initialValues)
                        setOpen(prev => !prev)
                    }, 2000)
                },
                onError: e => {
                    if (e.message === "value: There is already this value") {
                        setError("Podana wartość już istanieje!")
                    }
                    setTimeout(() => {
                        createSizeMutation.reset()
                    }, 2000)
                }
            }
            if (chosenListItem && chosenListItem.id) {
                updateSizeMutation.mutate({
                    accessToken,
                    data: {
                        value: values.value,
                        ktm: values.ktm,
                        panelsAmount: values?.panelsAmount,
                        ktmDescription: values.ktmDescription.toString(),
                        gateTypes: values?.gateTypes?.map(g => g.id)
                    },
                    path: params.size,
                    id: chosenListItem.id
                }, options)
            } else {
                createSizeMutation.mutate({
                    accessToken,
                    data: {
                        value: values.value,
                        ktm: values.ktm,
                        panelsAmount: values?.panelsAmount,
                        ktmDescription: values.ktmDescription.toString(),
                        gateTypes: values?.gateTypes?.map(g => g.id)
                    },
                    path: params.size
                }, options)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='collapse show'>
                <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className='row mb-6 d-flex flex-sm-row flex-column'>
                            {params.size !== "lintels" && <div className=' d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label fw-bold fs-6 required'
                                       title='Change avatar'>Rodzaj bramy</label>
                                <Field
                                    className="custom-select"
                                    name="gateTypes"
                                    options={optionsGates}
                                    component={CustomSelect}
                                    placeholder="Wybierz powiązane rodzaje bram..."
                                    isMulti={true}
                                />
                                {formik.errors.gateTypes && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.gateTypes}</div>
                                    </div>
                                )}
                            </div>}
                            <div className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label required fw-bold fs-6'>{chosenSizeTitle} w
                                    mm</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Rozmiar w mm'
                                        {...formik.getFieldProps('value')}
                                    />
                                </div>
                                {formik.touched.value && formik.errors.value && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.value}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6 d-flex'>
                            <div className='d-flex flex-column col-12 col-sm-6 '>
                                <label className='col-lg-6 col-form-label fw-bold fs-6 required'>KTM</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź KTM...'
                                        {...formik.getFieldProps('ktm')}
                                    />
                                </div>
                                {formik.touched.ktm && formik.errors.ktm && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.value}</div>
                                    </div>
                                )}
                            </div>
                            <div
                                style={{
                                    transition: "all .3s ease-in-out",
                                    opacity: formik.values.ktm.trim().length > 0 ? 1 : 0
                                }}
                                className='d-flex flex-column col-12 col-sm-6'>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'>Opis skrócony</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź opis skrócony...'
                                        {...formik.getFieldProps('ktmDescription')}
                                    />
                                </div>
                            </div>
                        </div>
                        {params.size === "heights" && <div className='row mb-6 d-flex'>
                            <div className='d-flex flex-column col-12 col-sm-6 '>
                                <label className='col-lg-6 col-form-label fw-bold fs-6'>Ilość paneli</label>

                                <div className='col-lg-12 fv-row'>
                                    <input
                                        type='number'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Wprowadź ilość paneli...'
                                        {...formik.getFieldProps('panelsAmount')}
                                    />
                                </div>
                            </div>
                        </div>}

                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            {(createSizeMutation.isIdle && updateSizeMutation.isIdle) &&
                                <button type='submit' className='btn btn-primary'>
                                    {chosenListItem ? 'Zapisz' : 'Dodaj'}
                                </button>}
                        </div>
                    </form>
                </FormikProvider>
                {(createSizeMutation.isLoading || updateSizeMutation.isLoading) && (
                    <div className="w-100 text-center">
                        <span className='indicator-progress' style={{display: 'block'}}>
                            <span className='spinner-border text-center spinner-border-sm align-middle ms-2'></span>
                        </span>
                    </div>

                )}
                {(updateSizeMutation.isSuccess || createSizeMutation.isSuccess) &&
                    <p className="text-center fw-bold" style={{color: "green", fontSize: 16}}>Zapisano!</p>}
                {(updateSizeMutation.isError || createSizeMutation.isError) &&
                    <p className="text-center fw-bold" style={{color: "red", fontSize: 16}}>{error}</p>}
            </div>
        </div>
    )
}

export {SizesCreateForm}
