import React, {FC, useState} from 'react'
import {Field, ErrorMessage} from 'formik'
import {createComponentSchemas} from "../CreateComponentWizardHelper";

const Step3: FC<{ formValues: { name: string, relatedComponents: {}[], gateType: {name: string}[] } }> = ({formValues}) => {

    return (
        <div className="w-100">
            <div className="pb-10 pb-lg-12">
                <h2 className="fw-bolder text-dark">Podsumowanie</h2>
            </div>
            <div className="fv-row mb-10">
                <label className="form-label">Nazwa komponentu</label>
                <input name="business_name"
                       className="form-control form-control-lg form-control-solid"
                       value={formValues?.name}
                       disabled/>
            </div>
            <p className="form-label">Powiązane komponenty</p>
            {formValues?.relatedComponents && formValues.relatedComponents.length > 0 && formValues?.relatedComponents.map(item => {
               if (item !== undefined) {
                   return (
                       <div className="mb-0">
                           <label className="d-flex flex-stack mb-5 cursor-pointer">
							<span className="d-flex align-items-center me-2">
						<span className="symbol symbol-50px me-6">
							<span className="symbol-label">
								<span
                                    className="svg-icon svg-icon-1 svg-icon-gray-600">
									<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24" height="24"
                                         viewBox="0 0 24 24" fill="none">
										<path
                                            d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                                            fill="black"/>
										<path opacity="0.3"
                                              d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                                              fill="black"/>
									</svg>
								</span>
							</span>
						</span>
						<span className="d-flex flex-column">
							<span className="fw-bolder text-gray-800 text-hover-primary fs-5">{item?.split("_")[1]}</span>
						</span>
					</span>
                           </label>
                       </div>
                   )
               }
            })}
            <p className="form-label">Rodzaje bram</p>
            {formValues?.gateTypes && formValues.gateTypes.length > 0 && formValues?.gateTypes.map(item => {
                return (
                    <div className="mb-0">
                        <label className="d-flex flex-stack mb-5 cursor-pointer">
							<span className="d-flex align-items-center me-2">
						<span className="symbol symbol-50px me-6">
							<span className="symbol-label">
								<span
                                    className="svg-icon svg-icon-1 svg-icon-gray-600">
									<svg xmlns="http://www.w3.org/2000/svg"
                                         width="24" height="24"
                                         viewBox="0 0 24 24" fill="none">
										<path
                                            d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                                            fill="black"/>
										<path opacity="0.3"
                                              d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                                              fill="black"/>
									</svg>
								</span>
							</span>
						</span>
						<span className="d-flex flex-column">
							<span
                                className="fw-bolder text-gray-800 text-hover-primary fs-5">{item?.name}</span>
						</span>
					</span>
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export {Step3}
