import React, {FC, useMemo, useState} from 'react';
import {KTSVG} from "../../../../_metronic/helpers";
import {useQuery} from "react-query";
import {getAllGateTypesPaginated, getGateTypeDrives} from "./GateCategoryFunctions";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";
import {IAccessGroupDetails} from "../../accessGroups/AccessGroupAddModel";
import {CircularProgress, ThemeProvider} from "@mui/material";
import {style, theme} from "../../adminList/Admin";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {GateCategoryCreateForm} from "./GateCategoryCreateForm";
import SubMenu from "../../../component/subMenu/SubMenu";
import CustomMassiveActions from "./CustomMassiveActions";
import CustomChangePosition from "../../../component/customChangePosition/customChangePosition";
import {Loader, Pagination} from "@mantine/core";

const GateCategoryList: FC = () => {
    const [chosenListItem, setChosenListItem] = useState<IAccessGroupDetails | null>(null)
    const [open, setOpen] = React.useState(false);
    const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual);
    const handleOpen = () => setOpen(true);
    const [page, setPage] = useState(1)
    const [chosenComponents, setChosenComponents] = useState([])
    const [isChecked, setIsChecked] = useState({
        all: false,
        first: false
    })
    const {
        data: gateTypes,
        isLoading
    } = useQuery(["gate_types_paginated", page], () => getAllGateTypesPaginated(accessToken, page), {
        refetchOnWindowFocus: false,
        placeholderData: [],
    });
    const ids = useMemo(() => gateTypes.data?.map(e => e.id), [gateTypes])
    const {
        data: gateTypesDrives,
        isFetching
    } = useQuery(["gate_type_drive", gateTypes], () => getGateTypeDrives(accessToken, ids), {
        placeholderData: [],
        refetchOnWindowFocus: false,
    });
    const totalPages = Math.ceil(gateTypes?.totalItems / 30)
    const handleClose = () => {
        setChosenListItem(null)
        setOpen(false)
    };

    const handleOnChange = (e: any) => {
        if (e.target.checked && e.target.value !== "all") {
            setChosenComponents(prev => [...prev, e.target.value])
        } else if (e.target.checked && e.target.value === "all") {
            setChosenComponents(gateTypes?.map(i => i.id))
        } else if (e.target.checked === false && e.target.value === "all") {
            setChosenComponents([])
        } else {
            setChosenComponents(prev => prev.filter(item => item !== e.target.value))
        }
    }

    return (
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            {/* begin::Body */}
            <CustomMassiveActions isChecked={isChecked} open={open} setOpen={setOpen} chosenComponent={chosenComponents}
                                  setChosenComponents={setChosenComponents}/>
            <div className='card-body py-3'>
                {/* begin::Table container */}
                {gateTypes?.data?.length === 0 && <p className="mt-5">Brak elementów do wyświetlenia</p>}
                {isLoading && <div className="d-flex justify-content-center p-10"><CircularProgress/></div>}
                {!isLoading && gateTypes && gateTypes?.data?.length > 0 && <div className='table-responsive'>
                    {/* begin::Table */}
                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                        {/* begin::Table head */}
                        <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>
                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                        className='form-check-input'
                                        type='checkbox'
                                        checked={chosenComponents.length > 0}
                                        onChange={handleOnChange}
                                        value="all"
                                    />
                                </div>
                            </th>
                            <th>Pozycja</th>
                            <th>Rodzaj bram</th>
                            <th>Napęd</th>
                            <th>Akcje</th>
                        </tr>
                        </thead>
                        {/* end::Table head */}
                        {/* begin::Table body */}
                        <tbody>
                        {gateTypes?.data?.map((gate: {
                                id: string,
                                name: string,
                                drives: { id: string, name: string }[]
                            }, index) => {
                                return (
                                    <tr key={gate.id} style={{background: gate?.isHidden ? '#f1c1c1': 'white'}}>
                                        <td>
                                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                <input
                                                    onChange={handleOnChange}
                                                    checked={chosenComponents.includes(gate.id)}
                                                    className='form-check-input widget-9-check'
                                                    type='checkbox'
                                                    value={gate.id}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <CustomChangePosition item={gate} orderKey={"gateTypeOrder"} url={"gate_types"}
                                                                  accessToken={accessToken} allItems={gateTypes?.data}
                                                                  queryKey={"gate_types"}
                                                                  queryKey2={"gate_types_paginated"}/>
                                        </td>
                                        <td>
                                            {gate?.image?.contentUrl && <div className="symbol symbol-45px me-5">
                                                <img src={`${process.env.REACT_APP_API_URL}` + gate?.image?.contentUrl}
                                                     alt=""/>
                                            </div>}
                                            {gate.name}
                                        </td>
                                        <td style={{maxWidth: 1000, display: "flex", flexWrap: "wrap", gap: 7}}>
                                            {isFetching ?
                                                <Loader/> : gateTypesDrives[index]?.length > 0 ? gateTypesDrives[index]?.map((drive) => (
                                                    <span key={drive.id}
                                                          className='badge badge-light-primary fs-7 fw-bold'>{drive.name}</span>
                                                )) : "-"}
                                        </td>
                                        <td>
                                            <SubMenu
                                                item={gate}
                                                setChosenListItem={setChosenListItem}
                                                chosenListItem={chosenListItem}
                                                modalOpen={open}
                                                setOpen={setOpen}
                                                url={"gate_types"}/>
                                        </td>
                                    </tr>
                                )
                            }
                        )}
                        </tbody>
                        {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                </div>}
                {/* end::Table container */}
                {totalPages > 1 && <Pagination total={totalPages} page={page} onChange={setPage}
                                               style={{justifyContent: "flex-end"}}/>}
            </div>
            {/* begin::Body */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={theme}>
                    <Box sx={style}>
                        <div>
                            <div>
                                <div className='modal-header pb-0 border-0 justify-content-end'>
                                    <div
                                        onClick={() => setOpen(!open)}
                                        className='btn btn-sm btn-icon btn-active-color-primary'>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1'/>
                                    </div>
                                </div>

                                <div className='modal-body scroll-y'>
                                    <div className='text-center mb-13'>
                                        <h1 className='mb-3'>{chosenListItem ? "Edytuj" : "Dodaj"} bramę</h1>
                                    </div>
                                    <GateCategoryCreateForm
                                        gateTypes={gateTypes}
                                        setChosenListItem={setChosenListItem}
                                        chosenListItem={chosenListItem}
                                        modalOpen={open} setOpen={setOpen}/>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </div>
    );
};

export default GateCategoryList;
