import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, resetPassword} from '../redux/AuthCRUD'

const initialValues = {
  password: '',
  repeatedPassword: ''
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()

    .min(8, 'Min. 8 znaków, w tym duża litera, numer i symbol')
    .required('Pole "Hasło" jest wymagane'),
})

export function ResetPassword() {
  const params = useParams();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(params.token, values.password)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('Coś poszło nie tak, spróbuj ponownie')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Resetowanie hasła</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Wpisz poniżej nowe hasło.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Wystapił błąd, spróbuj ponownie później.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Link do zmiany hasła został wysłany. Sprawdź skrzynke pocztową.</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Nowe hasło</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Powtórz nowe hasło</label>
          <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('repeatedPassword')}
              className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {'is-invalid': formik.touched.repeatedPassword && formik.errors.repeatedPassword},
                  {
                    'is-valid': formik.touched.repeatedPassword && !formik.errors.repeatedPassword,
                  }
              )}
          />
          {formik.touched.repeatedPassword && formik.errors.repeatedPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.repeatedPassword}</span>
                </div>
              </div>
          )}
          {(formik.values.password !== formik.values.repeatedPassword) && formik.touched.repeatedPassword &&  <div className='fv-help-block'>
            <span role='alert'>Hasła muszą być takie same</span>
          </div>}
        </div>

        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            disabled={formik.values.password !== formik.values.repeatedPassword}
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Zapisz</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Wróć do logowania
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
