/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core';
import {ClientTable} from "./ClientTable";


const ClientListPage: FC = () => (
    <>
        <ClientTable className='card-xxl-stretch mb-5 mb-xl-8'/>
    </>
)

const ClientListWrapper: FC = () => {

    return (
        <>
            <PageTitle>Lista kontrahentów</PageTitle>
            <ClientListPage/>
        </>
    )
}

export {ClientListWrapper}
